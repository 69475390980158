import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import SwitchControl from "../SwitchControl";
import "./style.scss";
import { DATECONTROL_TYPE, WAITER_TYPES } from '../../../utils/enums';
import OrderDatetimeControl from "../OrderDatetimeControl/OrderDatetimeControl";
import moment from "moment";
import {DATE_TIME_FORMAT_DAYTIME, isNotToday, TIME_FORMAT_USER} from "../../../utils/convertTime";
import IconButton from "../IconButton";
import Picker from 'react-mobile-picker';
import {useOnClickOutside} from "../../../services/hooks";
import {useTranslation} from "react-i18next";
import Waiter from '../Waiter/Waiter';
import {isRTL} from "../../../services/userModel.js";

const ShiftCourierControlTimeSelect = ({ onChange, endTime, startTime}) => {
    const {t} = useTranslation();
    const durationRef = useRef();
    useOnClickOutside(durationRef, handleOnCloseDuration);
    const [showDurationModal, setShowDurationModal] = useState(false);

    const [isStartEnable, setIsStartEnable] = useState(false);
    const [isEndEnable, setIsEndEnable] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(moment(new Date()).add(8, "hour"));
    const [duration, setDuration] = useState({
        hour: 8,
        minutes: "00",
    });

    useEffect(() => {
        setStartDate(() => startTime || new Date());
        setIsStartEnable(() => !!startTime)

        if(endTime) {
            setIsEndEnable(() => true);
            setEndDate(() => endTime);
            const diff = moment.duration(moment(endTime).diff(startTime || new Date()));
            const minutes = diff.get("minutes");
            setDuration({
                hour: diff.get("hour"),
                minutes: minutes > 9 ? `${minutes}` : `0${minutes}`,
            })
        } else {
            setIsEndEnable(() => false);
            setEndDate(() => moment(startTime || new Date()).add(8, "hour"))
        }

        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        onChange({
            startTime : isStartEnable ? new Date(startDate) : null,
            endTime : isEndEnable ? new Date(endDate) : null,
        })
    }, [isStartEnable, isEndEnable, startDate, endDate, onChange]);

    const handleOnChangeStartDate = (date) => {
        setStartDate(date);
        setEndDate(moment(date).add(duration.hour, "hour").add(duration.minutes, "minutes"));
    }

    const handleOnChangeEndDate = (date) => {
        let newEndTime = date;
        if(moment(date).isBefore(isStartEnable ? startDate : new Date())) {
            newEndTime = isStartEnable ? startDate : new Date();
        }

        setEndDate(newEndTime);

        const diff = moment.duration(moment(newEndTime).diff(startDate));
        const minutes = diff.get("minutes");
        setDuration({
            hour: diff.get("hour") + diff.get("days") * 24,
            minutes: minutes > 9 ? `${minutes}` : `0${minutes}`,
        })
    }

    const getValuesGroup = () => {
        return {
            hour: Array(200).fill(0).map((e, i) => i),
            minutes: Array(60).fill(0).map((e, i) => i > 9 ? `${i}` : `0${i}`)
        }
    }

    const handleOnChangeDuration = (key, value) => {
        setDuration({...duration, [key]: value});
    }

    function handleOnCloseDuration() {
        setShowDurationModal(false);
        setEndDate(moment(startDate).add(duration.hour, "hour").add(duration.minutes, "minutes"));
    }

    return (
        <div className={classNames("shiftCourierControlTimeSelect", {rtl: isRTL()})}>
            <div className={classNames("startDateControl")}>
                <div className={classNames("startDate", {disable: !isStartEnable})}>
                    <span className={classNames("labelControl")}>{t("shifts.START_TIME_LABEL")}</span>
                    <OrderDatetimeControl
                        hideLabel
                        tab={1}
                        time={new Date(startDate)}
                        format={isNotToday(startDate) ? DATE_TIME_FORMAT_DAYTIME : TIME_FORMAT_USER}
                        type={DATECONTROL_TYPE.datetime}
                        isEditable={true}
                        hideIcon={true}
                        onSelect={handleOnChangeStartDate}
                    />
                </div>
                <SwitchControl
                  value={isStartEnable}
                  onClick={setIsStartEnable}
                  title={{ on: t("dashboard.orders.ON_LABEL"), off: t("dashboard.orders.OFF_LABEL") }}
                />
            </div>
            <div className={classNames("endDateControl")}>
                <div className={classNames("endDate", {disable: !isEndEnable})}>
                    <span className={classNames("labelControl")}>{t("shifts.END_TIME_LABEL")}</span>
                    <OrderDatetimeControl
                        hideLabel
                        tab={1}
                        time={new Date(endDate)}
                        format={isNotToday(endDate) ? DATE_TIME_FORMAT_DAYTIME : TIME_FORMAT_USER}
                        type={DATECONTROL_TYPE.datetime}
                        isEditable={true}
                        hideIcon={true}
                        onSelect={handleOnChangeEndDate}
                    />
                </div>
                <div className={classNames("durationControl", {disable: !isEndEnable})}>
                    <span className={classNames("labelControl")}>{t("shifts.DURATION")}</span>
                    <div className={classNames("duration")}>
                        <span>{duration.hour}:{duration.minutes}</span>
                        <div className={classNames("iconDurationWrap")}>
                            <IconButton iconName={"pencil"} isIcon onClick={() => setShowDurationModal(true)} />
                        </div>

                        {showDurationModal && (
                            <div ref={durationRef}>
                                <Picker
                                    wheel={"normal"}
                                    onChange={handleOnChangeDuration}
                                    optionGroups={getValuesGroup()}
                                    valueGroups={{hour: duration.hour, minutes: duration.minutes}}
                                />
                                <div className={'approveButtonWrapper'}>
                                    <div className={'approveButton'}>
                                        <Waiter
                                          type={WAITER_TYPES.button}
                                          useClass={classNames("btn", "confirm")}
                                          spanText={t("new-order.EDIT_ORDER_SAVE_BUTTON")}
                                          stopPropagation={true}
                                          handleOnClick={handleOnCloseDuration}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <SwitchControl
                  value={isEndEnable}
                  onClick={setIsEndEnable}
                  title={{ on: t("dashboard.orders.ON_LABEL"), off: t("dashboard.orders.OFF_LABEL") }}
                />
            </div>
        </div>
    )
}

export default ShiftCourierControlTimeSelect;