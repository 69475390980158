import React from "react";
import { connect } from "react-redux";
import "./index.scss";

import { ACCOUNT_ORDER_STATUS } from "../../../utils/enums";

const MenuItemIconOrdersCount = ({ activeOrders }) => {
    const allCount = activeOrders
      .filter(order=> order.status === ACCOUNT_ORDER_STATUS.pending).length;

    return allCount ? <span>{allCount}</span> : null;
}

const mapStateToProps = state => ({
    activeOrders: state.order.data.activeOrders || []
});

export default connect(mapStateToProps)(MenuItemIconOrdersCount);