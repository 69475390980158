
import SectionModel from "../../models/sectionModel";
import { PAYMENTS_CONFIGURATION_SETTING, RESTAURANT_PAYME_WALLET_SETTING } from "../../store/reducers/restaurant/restaurant-actions";
import {
  CONTROL_TYPE,
  MARKETPLACE_SETTINGS_MODEL, MODEL_CONTROL_TYPE,
  sellerBankCodes,
  sellerIncList,
  sellerPersonBusinessTypes,
  TYPE_SECTION_MODEL
} from "../../utils/enums";
import {createControlModel} from "../controlModelFactory";
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import {isMobileWidth} from "../restaurant";

export function createPaymentsConfigurationSettingsSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "settings.paymentsConfigurationSettings.OVERRIDE_DEPOT_SETTINGS_LABEL",
        accessor: "overrideDepotSettings",
        isEditable: true,
        type: CONTROL_TYPE.switch,
        isInline: true,
      },
      {
        title: "settings.paymentsConfigurationSettings.ACCEPT_CASH",
        accessor: "acceptCash",
        isShow: item=> item.overrideDepotSettings,
        isEditable: true,
        type: CONTROL_TYPE.checkbox
      },
      {
        title: "settings.paymentsConfigurationSettings.ACCEPT_CREDIT_CARD",
        accessor: "paymeWalletExist",
        isShow: item=> item.overrideDepotSettings && item.acceptCreditCard,
        isEditable: true,
        type: CONTROL_TYPE.paymeConfiguration
      },
      {
        title: "settings.paymentsConfigurationSettings.ACCEPT_CREDIT_CARD",
        accessor: "acceptCreditCard",
        isShow: item=> item.overrideDepotSettings,
        isEditable: true,
        type: CONTROL_TYPE.checkbox
      }
    ],
    actionName: PAYMENTS_CONFIGURATION_SETTING,
    initNewItemModel: item => item,
    typeAction: args.typeAction,
    stayAfterSave: true,
    typeModel: MARKETPLACE_SETTINGS_MODEL.paymentsConfiguration,
    saveControlsConfig: {
      cancel: { title: "basic.RESET_BUTTON", action: ()=> {}, isInit: true}
    },
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth
      })
    ],
    breadcrumbs: [
      createBreadcrumbModel({
        label: "header.menu.MARKETPLACE_SETTINGS",
      }),
    ],
    label: "settings.paymentsConfigurationSettings.LABEL",
    mappingLambda: state => ({ id: "paymentsConfiguration", ...state.restaurant.data.paymentsConfiguration })
  });
}

export function createRestaurantPaymeWalletNewSectionModel({ ...args }) {
    return new SectionModel({
      columns: [
        {
          title: "settings.paymeWallet.SELLER_FIRST_NAME",
          accessor: "sellerFirstName",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_LAST_NAME",
          accessor: "sellerLastName",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_SOCIAL_ID",
          accessor: "sellerSocialId",
          minLength: 9,
          maxLength: 9,
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_BANK_CODE",
          accessor: "sellerBankCode",
          connect: () => ({
            values: sellerBankCodes()
          }),
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.select
        },
        {
          title: "settings.paymeWallet.SELLER_BANK_BRANCH",
          accessor: "sellerBankBranch",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.number
        },
        {
          title: "settings.paymeWallet.SELLER_BANK_ACCOUNT_NUMBER",
          accessor: "sellerBankAccountNumber",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_EMAIL",
          accessor: "sellerEmail",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_PHONE",
          accessor: "sellerPhone",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_BIRTHDAY",
          accessor: "sellerBirthdate",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.date
        },
        {
          title: "settings.paymeWallet.SELLER_SOCIAL_ID_ISSUED",
          accessor: "sellerSocialIdIssued",        
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.date
        },
        {
          title: "settings.paymeWallet.SELLER_GENDER",
          label: {
            onLabel: "Female",
            offLabel: "Male",
          },
          accessor: "sellerGender",
          isEditable: true,
          type: CONTROL_TYPE.switch
        },
        {
          title: "settings.paymeWallet.SELLER_INC",
          accessor: "sellerInc",
          connect: () => ({
            values: sellerIncList()
          }),
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.select
        },
        {
          title: "settings.paymeWallet.SELLER_MERCHANT_NAME",
          accessor: "sellerMerchantName",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_INC_CODE",
          accessor: "sellerIncCode",
          isEditable: true,
          isFuncRequired: function(item) {
            return !!item.sellerInc
          },
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_PERSON_BUSINESS_TYPE",
          accessor: "sellerPersonBusinessType",
          connect: () => ({
            values: sellerPersonBusinessTypes()
          }),
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.select
        },
        {
          title: "settings.paymeWallet.SELLER_SITE_URL",
          accessor: "sellerSiteUrl",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_ADDRESS_CITY",
          accessor: "sellerAddressCity",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_ADDRESS_STREET",
          accessor: "sellerAddressStreet",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        },
        {
          title: "settings.paymeWallet.SELLER_ADDRESS_STREET_NUMBER",
          accessor: "sellerAddressStreetNumber",
          isEditable: true,
          isRequired: true,
          type: CONTROL_TYPE.text
        }
      ],
      actionName: RESTAURANT_PAYME_WALLET_SETTING,
      initNewItemModel: () => ({
        sellerFirstName: "",
        sellerLastName: "",
        sellerSocialId: "",
        sellerBankCode: 0,
        sellerBankBranch: 0,
        sellerBankAccountNumber: "",
        sellerEmail: "",
        sellerPhone: "",
        sellerBirthdate: "",
        sellerSocialIdIssued: "",
        sellerGender: false,
        sellerInc: 0,
        sellerMerchantName: "",
        sellerIncCode: "",
        sellerPersonBusinessType: 0,
        sellerSiteUrl: "",
        sellerAddressCity: "",
        sellerAddressStreet: "",
        sellerAddressStreetNumber: ""
      }),
      typeAction: args.typeAction,
      typeModel: TYPE_SECTION_MODEL.restaurantPaymeWalletNew,
      parentUrl: MARKETPLACE_SETTINGS_MODEL.paymentsConfiguration,
      stayAfterSave: true,
      saveControlsConfig: {
        cancel: {
          action: (item, history)=> {
            history.push(MARKETPLACE_SETTINGS_MODEL.paymentsConfiguration);
          }      
        }
      },
      mappingLambda: () => []
    });
}
