import i18n from "../i18n.js";

export default () => {
    return {
        cutlery: {
            addCutlery: i18n.t('uilib.cutlery.ADD_CUTLERY'),
            cutlery: i18n.t('uilib.cutlery.ADD_CUTLERY'),
        },
        checkoutFormItem: {
            change: i18n.t('uilib.checkoutFormItem.CHANGE'),
        },
        gift: {
            itsGift: i18n.t('uilib.gift.ITS_GIFT'),
            name: i18n.t('uilib.gift.NAME'),
            namePlaceholder: i18n.t('uilib.gift.NAME_PLACEHOLDER'),
            greeting: i18n.t('uilib.gift.GREETING'),
            greetingPlaceholder: i18n.t('uilib.gift.GREETING_PLACEHOLDER'),
            phone: i18n.t('uilib.gift.PHONE'),
            phonePlaceholder: i18n.t('uilib.gift.PHONE_PLACEHOLDER'),
        },
        bagesPaymentsOrderSummary: {
            discount: i18n.t('uilib.bagesPaymentsOrderSummary.DISCOUNT'),
            freeDelivery: i18n.t('uilib.bagesPaymentsOrderSummary.FREE_DELIVERY'),
            bonuses: i18n.t('uilib.bagesPaymentsOrderSummary.BONUSES'),
        },
        tag: {
            vegan: i18n.t('uilib.tag.VEGAN'),
            vegetarian: i18n.t('uilib.tag.VEGETARIAN'),
            spicy: i18n.t('uilib.tag.SPICY'),
            freeGluten: i18n.t('uilib.tag.FREE_GLUTEN'),
        },
        errorConstructor: {
            selectOneOf: i18n.t('uilib.errorConstructor.SELECT_ONE_OF'),
            selectAtLeast: i18n.t('uilib.errorConstructor.SELECT_AT_LEAST'),
            for: i18n.t('uilib.errorConstructor.FOR'),
            maximumAvailable: i18n.t('uilib.errorConstructor.MAXIMUM_AVAILABLE'),
        },
        productConstructor: {
            ingredients: i18n.t('uilib.productConstructor.INGREDIENTS'),
            comments: i18n.t('uilib.productConstructor.COMMENTS'),
            addName: i18n.t('uilib.productConstructor.ADD_NAME'),
            addComment: i18n.t('uilib.productConstructor.ADD_COMMENTS'),
            addCommentPlaceholder: i18n.t('uilib.productConstructor.ADD_COMMENT_PLACEHOLDER'),
            addNamePlaceholder: i18n.t('uilib.productConstructor.ADD_NAME_PLACEHOLDER'),
            summary: i18n.t('uilib.productConstructor.SUMMARY'),
            toppings: i18n.t('uilib.productConstructor.TOPPINGS'),
            half: i18n.t('uilib.productConstructor.HALF'),
            full: i18n.t('uilib.productConstructor.FULL'),
            addToCart: i18n.t('uilib.productConstructor.ADD_TO_CART'),
            back: i18n.t('uilib.productConstructor.BACK'),
            next: i18n.t('uilib.productConstructor.NEXT'),
            left: i18n.t('uilib.productConstructor.LEFT'),
            right: i18n.t('uilib.productConstructor.RIGHT'),
            change: i18n.t('uilib.productConstructor.CHANGE'),
            free: i18n.t('uilib.productConstructor.FREE'),
        },
        summary: {
            recalculateDiscounts: i18n.t('uilib.summary.RECALCULATE_DISCOUNTS'),
            addRemovedOffer: i18n.t('uilib.summary.ADD_REMOVED_OFFER'),
            removedOffer: i18n.t('uilib.summary.REMOVE_OFFER'),
            bonus: i18n.t('uilib.summary.BONUS'),
            discount: i18n.t('uilib.summary.DISCOUNT'),
            coupon: i18n.t('uilib.summary.COUPON'),
            summary: i18n.t('uilib.summary.SUMMARY'),
            subtotal: i18n.t('uilib.summary.SUBTOTAL'),
            deliveryCost: i18n.t('uilib.summary.DELIVERY_COST'),
            deliveryCostBase: i18n.t('uilib.summary.DELIVERY_COST_BASE'),
            deliveryCostFinal: i18n.t('uilib.summary.DELIVERY_COST_FINAL'),
            serviceFee: i18n.t('uilib.summary.SERVICE_FEE'),
            minimumOrderCharge: i18n.t('uilib.summary.MINIMUM_ORDER_CHANGE'),
            driverTip: i18n.t('uilib.summary.DRIVER_TIP'),
            invalidPromocode: i18n.t('uilib.summary.INVALID_PROMOCODE'),
            placeholderPromocode: i18n.t('uilib.summary.PLACEHOLDER_PROMOCODE'),
            paymentBalance: i18n.t('uilib.summary.PAYMENT_BALANCE'),
            total: i18n.t('uilib.summary.TOTAL'),
            confirm: i18n.t('uilib.summary.CONFIRM'),
            driverTooltip: i18n.t('uilib.summary.DRIVER_TOOLTIP'),
            minimumOrderChargeTooltip: i18n.t('uilib.summary.MINIMUM_ORDER_CHARGE_TOOLTIP'),
            serviceFeeTooltip: i18n.t('uilib.summary.SERVICE_FEE_TOOLTIP')
        },
        deliveryControl: {
            pickUp: i18n.t('uilib.deliveryControl.PICKUP'),
            delivery: i18n.t('uilib.deliveryControl.DELIVERY'),
        }
    }
}