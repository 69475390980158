import React from "react";
import './style.scss';
import {useSelector} from "react-redux";

const CountPendingOrder = () => {
    const count = useSelector(state => state.order?.data?.adminOrders?.pendingCount);
    return !!count && <div className={'countPendingOrder'}>
        {count}
    </div>
}

export default CountPendingOrder;