import * as Sentry from '@sentry/react';
import Store from '../store/store';
import moment from 'moment';

import i18n from '../i18n';
import User from './user';
import {getStoreData, saveStoreData} from './dataStorage';
import {getInitModel} from './initModels';
import {getDistance, getLatLngFromLocation, locationIsEmpty} from './order';
import {
    approvedFilter,
    awayFilter,
    backUpCouriersFilter,
    comingBackFilter,
    courierQueueSort,
    couriersBarFilter,
    externalCycleCouriersBarFilter,
    getStartedControlCenter,
    onRouteCourierGroupSort,
    onRouteFilter,
    pendingHistoryOrdersFilter,
    pendingOrdersCookingSort,
    pendingOrdersCouriersOlderSort,
    pendingOrdersFilter,
    pendingOrdersSort,
    sortItemsByProp,
    waitingFilter,
} from './filter';

import ColorService from './color';

import {fetchMarkerAvatarBase64, getConvertedCloudUrl} from '../utils/image-util';
import {
    defaultIcons,
    getPendingOrdersIsDesktop,
    getPendingOrdersIsIpad,
    MAP_POLYGON_DIFF,
    SEARCH_RADIUS,
} from '../utils/constants';
import {getStringValue} from '../utils/objects-util';
import {
    CAMPAIGN_EXECUTION_STATE_TYPES,
    CAMPAIGN_TYPES,
    COUNTRY_CURRENCY_CODES,
    COUNTRY_CURRENCY_CODES_VALUE,
    COURIER_ASSOCIATION_MODE,
    COURIER_GROUP_MODEL_TYPE,
    COURIER_LOCATION_STATE,
    CYCLE_STATE,
    DELIVERY_ZONE_TYPE,
    DISCOUNT_ORDER_TYPE,
    DISCOUNT_TYPE,
    EXTERNAL_COURIER_PROVIDER,
    getCurrencyIcons,
    MODEL_TYPE,
    OFFER_DISCOUNT_TARGET,
    ORDER_PROCESS_STEP,
    ORDER_STATE,
    PENDING_ORDERS_FILTER_TYPE,
    RESTAURANT_STATES,
    TYPE_STORAGE,
} from '../utils/enums';
import {getUserConnectionData} from '../utils/auth-util';
import {getHistoryLocationParams} from '../utils/routesHelper';
import {getFullName} from './userModel.js';
import {GET_ORDER_SETUP_INTENT, STRIPE_VERIFY_CARD_ORDER} from "../store/reducers/order/order-actions.js";

export function getAvatar(id, keys, url) {
    return new Promise(resolve => {
        if (keys.length > 0 && keys.includes(id)) {
            getStoreData(TYPE_STORAGE.db, id).then(response => resolve(response));
        } else {
            if (url) {
                fetchMarkerAvatarBase64(url).then(
                    image => {
                        if (image) {
                            saveStoreData(TYPE_STORAGE.db, id, false, image).then(
                                () => resolve({ data: image }),
                                () => Sentry.captureException('Error saveStoreData. Depot id: ' + id)
                            );
                        } else {
                            Sentry.captureException('Error fetchMarkerAvatarBase64. Depot id: ' + id);
                        }
                    },
                    () => {
                        saveStoreData(TYPE_STORAGE.db, id, false, '').then(
                            () => resolve({ data: '' }),
                            () => Sentry.captureException('Error saveStoreData. Depot id: ' + id)
                        );
                    }
                );
            } else {
                saveStoreData(TYPE_STORAGE.db, id, false, '').then(
                    () => resolve({ data: '' }),
                    () => Sentry.captureException('Error saveStoreData. Depot id: ' + id)
                );
            }
        }
    });
}

export function getRestaurantName() {
    if (!Store) return null;
    const state = Store.store.getState();
    return state.restaurant && state.restaurant.data && state.restaurant.data.name;
}

export function getConsumerRestaurantId() {
    if (!Store) return null;
    const state = Store.store.getState();
    return (
        state.restaurant &&
        state.restaurant.data &&
        state.restaurant.data.consumerOrderMenu &&
        state.restaurant.data.consumerOrderMenu.id
    );
}

export function getRestaurantPaymentEnabled() {
    if (!Store) return false;
    const state = Store.store.getState();
    return (
        state.restaurant &&
        state.restaurant.data &&
        state.restaurant.data.paymentsConfiguration &&
        state.restaurant.data.paymentsConfiguration.enabled
    );
}


export function getRestaurantLoyaltyEnabled() {
    if (!Store) return false;
    const state = Store.store.getState();
    return (
        state.restaurant &&
        state.restaurant.data &&
        state.restaurant.data.loyaltyEnabled
    );
}

export function hasDepotBusinessMenu() {
    if (!Store) return false;
    const { id, parentId } = getHistoryLocationParams(window.location.search);
    const state = Store.store.getState();
    const depot = state.restaurant.data.depots && state.restaurant.data.depots.find(d => d.id === (id || parentId));
    const businessMenu = state.restaurant.data && state.restaurant.data.businessMenu;

    return !!(depot && depot.businessId) || !!(businessMenu && businessMenu.id);
}

export function getBusinessIdByCurrentDepot(store) {
    const { id } = getHistoryLocationParams(window.location.search);
    const state = store || Store.store.getState();
    const depot = state.restaurant.data.depots && state.restaurant.data.depots.find(d => d.id === id);
    const businessMenu = state.restaurant.data.businessMenu;
    return depot ? depot.businessId : businessMenu ? businessMenu.id : null;
}

export const getCurrentDepotByBusiness = store => {
    const { parentId, id, depotId } = getHistoryLocationParams(window.location.search);
    return depotId || parentId || id;
};

export function getOverrideDepotSettings() {
    if (!Store) return false;
    const state = Store.store.getState();
    return (
        state.restaurant &&
        state.restaurant.data &&
        state.restaurant.data.paymentsConfiguration &&
        state.restaurant.data.paymentsConfiguration.overrideDepotSettings
    );
}

export function getDepots(withGiftCard) {
    if (!Store) return [];
    const state = Store.store.getState();
    const restaurant = (state && state.restaurant && state.restaurant.data) || {};
    const depots = restaurant.depots || [];
    const giftCard = restaurant.giftCard &&
        restaurant.giftCard.businessMenu && {
            businessId: restaurant.giftCard.businessMenu.id,
            name: restaurant.giftCard.businessMenu.name,
        };

    return sortItemsByProp(withGiftCard && giftCard ? depots.concat([giftCard]) : depots);
}

export function getDepotsKeyValue() {
    if (!Store) return [];
    const state = Store.store.getState();
    const restaurant = (state && state.restaurant && state.restaurant.data) || {};
    const depots = restaurant.depots || [];

    return depots.map(depot => ({key: depot.id, value: depot.name}));
}

export function getReconciliationsDepots() {
    if (!Store) return [];
    const state = Store.store.getState();
    const depots = [...(state?.reconciliations?.data?.depots || [])];

    return depots;
}

export function getMessages() {
    if (!Store) return [];
    const state = Store.store.getState();
    const messages = (state && state.marketplace && state.marketplace.data && state.marketplace.data.message) || [];
    return sortItemsByProp(messages);
}


export function getNameForCategory(category) {
    if(!category) {
        return "";
    }
    return (category.nameTranslations && category.nameTranslations[i18n.language]) || "";
}

export function getCategories() {
    if (!Store) return [];
    const state = Store.store.getState();
    const restaurant = (state && state.restaurant && state.restaurant.data) || {};
    return sortItemsByProp(restaurant.businessCategory || []);
}

export function getTerritoriesEntity() {
    if (!Store) return [];
    const state = Store.store.getState();
    const restaurant = (state && state.restaurant && state.restaurant.data) || {};
    return restaurant.territories || [];
}

export function getTerritoryNameByEntityId(id) {
    const territories = getTerritoriesEntity();
    const territory = territories.find(t => t.entityId === id);
    return territory ? territory.name : "";
}

export function isShowParentBusinessMenu(businessMenu) {
    if (!businessMenu) {
        if (!Store) return false;
        const state = Store.store.getState();
        if (!state) return false;
        return !!(state.restaurant.data.businessMenu || {}).template_business_id;
    }

    return !!businessMenu.template_business_id;
}

export const getCampaignNameById = campaignId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const campaign = state?.restaurant?.data?.campaigns?.find(c => c.id === campaignId);
    return campaignId ? campaign?.name : '';
};

export const getDepotNameById = depotId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const depot =
        state.restaurant.data &&
        state.restaurant.data.depots &&
        state.restaurant.data.depots.find(d => d.id === depotId);
    return depot ? depot.name : '';
};

export const getListPromotionNameById = promoId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const listPromotion =
        state.restaurant.data &&
        state.restaurant.data.listPromotion &&
        state.restaurant.data.listPromotion.find(promo => promo.id === promoId);

    const languageCode = i18n.language;
    return listPromotion?.name_translations ? listPromotion.name_translations[languageCode] ?? '' : '';
};

export const getDeliveryZonesNameById = deliveryZonesId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const deliveryZones =
        state.restaurant.data &&
        state.restaurant.data.deliveryZones &&
        state.restaurant.data.deliveryZones.find(zone => zone.id === deliveryZonesId);

    return deliveryZones?.name ? deliveryZones.name : ''
};

export const getBusinessMenuGalleryNameById = galleryId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const gallery =
        state.restaurant.data &&
        state.restaurant.data.businessMenu &&
        state.restaurant.data.businessMenu.gallery.find(gallery => gallery.id === galleryId);

    return gallery?.title ? gallery.title : ''
};

export const getBusinessMenuOfferNameById = galleryId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const gallery =
        state.restaurant.data &&
        state.restaurant.data.businessMenu &&
        state.restaurant.data.businessMenu.offers &&
        state.restaurant.data.businessMenu.offers.find(gallery => gallery.id === galleryId);

    return gallery?.name ? gallery.name : ''
};

export const getOrderingMenuDeliveryZonesNameById = deliveryZonesId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const deliveryZones =
        state.restaurant.data &&
        state.restaurant.data.businessMenu &&
        state.restaurant.data.businessMenu.delivery_zones.find(deliveryZones => deliveryZones.id === deliveryZonesId);

    return deliveryZones?.name ? deliveryZones.name : ''
};

export const getPageNameById = pageId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const page =
        state.marketplace.data &&
        state.marketplace.data.pages &&
        state.marketplace.data.pages.find(page => page.id === pageId);

    return page?.title ? page.title : ''
};

export const getLoyaltyGiftCardProductNameById = productId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const product =
        state.restaurant.data &&
        state.restaurant.data.giftCard &&
        state.restaurant.data.giftCard.businessMenu &&
        state.restaurant.data.giftCard.businessMenu.categories &&
        state.restaurant.data.giftCard.businessMenu.categories[0]?.products?.find(product => product.id === productId);

    return product?.name ? product.name : ''
};

export const getCloudPrinterById = id => {
    if (!Store) return '';
    const state = Store.store.getState();

    const product =
        state.restaurant.data &&
        state.restaurant.data?.cloudPrinters?.find(e => e.id === id);

    return product ? product : null
};

export const getDepotUserNameById = (userId, depotId ) => {
    if (!Store) return '';
    const state = Store.store.getState();
    const depots = state?.restaurant?.data?.depots
    const depot = depots?.find(depot => depot?.id === depotId)
    const depotUser = depot?.depotUsers?.find(user => user?.userId === userId)
    return depotUser ? getFullName(depotUser) : '';
};

export const getUserNameById = (userId) => {
    if (!Store) return '';
    const state = Store.store.getState();
    const users = state?.userModel?.data || []
    const user = users?.find(user => user?.userId === userId)
    return user ? getFullName(user) : '';
};

export const getReconciliationsConfigurationsNameById = (configurationId) => {
    if (!Store) return '';
    const state = Store.store.getState();
    const configurations = state?.reconciliations?.data.configurations|| []
    const configuration = configurations?.find(configuration => configuration?.id === configurationId)
    return configuration ? configuration?.templateName : '';
};

export const getMessageNameById = messageId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const message =
        state.marketplace.data &&
        state.marketplace.data.message &&
        state.marketplace.data.message.find(m => m.id === messageId);

    return message ? message.label || message.title : '';
}

export const getMessageGalleryNameById = (messageId, galleryId) => {
    if (!Store) return '';
    const state = Store.store.getState();
    const message =
        state.marketplace.data &&
        state.marketplace.data.message &&
        state.marketplace.data.message.find(m => m.id === messageId);

    const gallery =
        message &&
        message.gallery &&
        message.gallery.find(g => g.id === galleryId);

    return gallery ? gallery.title : '';
}

export const isDepotByPathname = () => {
    const pathname = window.location.pathname
    return pathname.includes('/settings')
}

export const getOperationsModeForDepot = (depotId, store) => {
    const state = store || Store.store.getState();
    const restaurant = state.restaurant.data || {};
    const depot = (restaurant.depots || []).find(d => d.id === depotId);
    const operations = ((restaurant.operations || {}).profiles || []);
    const depotOperations = ((depot.operationConfiguration || {}).profiles || []);
    return depotOperations.concat(operations).map(p => ({
        key: p.name,
        value: p.name,
    }))
}

export const getCourierNameByShiftId = shiftId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const users = state?.userModel?.data?.filter(user => user.cycle)
    return users?.find(user => user?.cycle?.shiftId === shiftId)?.firstName || ''
}

export const getProviderNameById = id => {
    if (!Store) return '';
    const state = Store.store.getState();
    const ordersProvider = state?.restaurant?.data?.ordersProvider;
    return ordersProvider?.find(provider => provider.id === id)?.name || '';
}

export const getHistoryCourierNameByShiftHistory = () => {
    if (!Store) return '';
    const state = Store.store.getState();
    const shift = state.restaurant.data.shiftsHistory
    return state?.userModel?.data?.find(user => user?.userId === shift?.userId)?.firstName || ''
}

export const getMenuTemplateNameById = menuTemplateId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const menuTemplate = state.restaurant.data.businessMenu;
    return menuTemplate?.id === menuTemplateId ? menuTemplate?.name : '';
};
export const getMenuTemplateBusinessCategoryNameById = menuTemplateBusinessCategoryId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const businessCategories = state.restaurant.data.businessCategory;
    const businessCategoriesNames = businessCategories?.find(category => category.id === menuTemplateBusinessCategoryId).nameTranslations
    return businessCategoriesNames ? businessCategoriesNames[i18n.language] : ''
};

export const getControlCenterNameById = controlCenterId => {
    if(!Store) return '';
    const state = Store.store.getState();
    return ((state.restaurant.data.controlCenters || []).find(c => c.controlCenterId === controlCenterId) || {}).name;
}

export const getBusinessName = depotId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const depots = state.restaurant.data.depots || [];
    return (depots.find(d => d.id === depotId) || {}).name || '';
}

export const getExtraNameFromBusinessCategory = (categoryId, extraId) => {
    if (!Store) return '';
    const state = Store.store.getState();
    const businessMenu = state.restaurant.data.businessMenu || {};
    const categories = (businessMenu.categories || []).find(c => c.id === categoryId) || {};
    const extra = (categories.extras || []).find(e => e.id === extraId) || {};
    return extra.name || '';
}

export const getExtraOptionNameFromBusinessCategory = (categoryId, extraId, optionId) => {
    if (!Store) return '';
    const state = Store.store.getState();
    const businessMenu = state.restaurant.data.businessMenu || {};
    const categories = (businessMenu.categories || []).find(c => c.id === categoryId) || {};
    const extra = (categories.extras || []).find(e => e.id === extraId) || {};
    const option = (extra.options || []).find(o => o.id === optionId) || {};
    return option.name || '';
}

export const getNameByIdsBusinessMenu = businessId => {
    if (!Store) return '';
    const state = Store.store.getState();
    const { depots, businessMenu } = state.restaurant.data;
    let businessMenuFinded;

    if (businessMenu && businessMenu.id === businessId) {
        businessMenuFinded = businessMenu;
    } else {
        businessMenuFinded = depots?.find(d => d.businessId === businessId);
    }

    if (!businessMenuFinded) {
        return '';
    }

    return businessMenuFinded.name;
};

export const getNameForProductsByIds = (businessId, categoryId, productId) => {
    if (!Store) return '';
    const state = Store.store.getState();
    const { businessMenu } = state.restaurant.data;
    let category = null;

    if (!businessMenu) {
        return '';
    }

    if (!categoryId) {
        return businessMenu.name;
    }

    category = businessMenu.categories.find(c => c.id === categoryId);
    if (!productId) {
        return (category || {}).name || '';
    }

    const product = (category.products || []).find(p => p.id === productId);
    return (product || {}).name || '';
};

export function getDepotNameByBusinessId(businessId) {
    if (!businessId || !Store) return '';
    const state = Store.store.getState();
    const depot = ((state && state.restaurant && state.restaurant.data && state.restaurant.data.depots) || []).find(
        f => f.businessId === businessId
    );
    return (depot && `${depot.name}`) || '';
}

export function getDepotByBusinessId(businessId) {
    if (!businessId || !Store) return '';
    const state = Store.store.getState();
    return ((state && state.restaurant && state.restaurant.data && state.restaurant.data.depots) || []).find(
        f => f.businessId === businessId
    );
}

export function getDeliveryZones() {
    const state = (Store && Store.store.getState()) || {};
    return (state.restaurant && state.restaurant.data && state.restaurant.data.deliveryZones) || [];
}

export function getBusinessCategories() {
    const state = (Store && Store.store.getState()) || {};
    return (state.restaurant && state.restaurant.data && state.restaurant.data.businessCategory) || [];
}

export function getAvailableControlCenters(isActive) {
    if (!Store) return [];
    const state = Store.store.getState();
    if (!state.restaurant || !state.restaurant.data || !state.restaurant.data.availableControlCenters) return [];
    return isActive
      ? state.restaurant.data.availableControlCenters.filter(controlCenter => getStartedControlCenter(controlCenter))
      : state.restaurant.data.availableControlCenters;
}

export function getControlCenters(isActive) {
    if (!Store) return [];
    const state = Store.store.getState();
    if (!state.restaurant || !state.restaurant.data || !state.restaurant.data.controlCenters) return [];
    return isActive
        ? state.restaurant.data.controlCenters.filter(controlCenter => getStartedControlCenter(controlCenter))
        : state.restaurant.data.controlCenters;
}

export function getInitialControlCenters() {
    if (!Store) return [];
    const state = Store.store.getState();
    if (!state.restaurant || !state.restaurant.data || !state.restaurant.data.initialControlCenters) return [];
    return state.restaurant.data.initialControlCenters;
}

export function getControlCenterById(id) {
    if (!Store) return null;
    const state = Store.store.getState();
    if (!state.restaurant || !state.restaurant.data || !state.restaurant.data.controlCenters) return null;
    return state.restaurant.data.controlCenters.find(f => f.controlCenterId === id);
}

export function getRegionById(regionId) {
    if (!Store) return null;
    const state = Store.store.getState();
    if (
        !state.restaurant ||
        !state.restaurant.data ||
        !state.restaurant.data.configuration ||
        !state.restaurant.data.configuration.regions
    )
        return null;
    const region = state.restaurant.data.configuration.regions.find(f => f.id === regionId);
    return region ? { id: region.id, locations: region.region } : null;
}

export function getRegions() {
    if (!Store) return [];
    const state = Store.store.getState();
    return ((state.restaurant || {}).data.configuration || {}).regions || [];
}

export function getDepotRegions(price, store) {
    const state = store || Store.store.getState();

    if (
        !state.restaurant ||
        !state.restaurant.data ||
        !state.restaurant.data.configuration ||
        !state.restaurant.data.configuration.regions
    )
        return [];

    let depotRegionIds = price && price.regionConfigurations ? price.regionConfigurations.map(m => m.id) : [];

    return state.restaurant.data.configuration.regions.filter(f => !depotRegionIds.includes(f.id));
}

export function getAllDepotRegions(price, store) {
    const state = store || Store.store.getState();

    if (
        !state.restaurant ||
        !state.restaurant.data ||
        !state.restaurant.data.configuration ||
        !state.restaurant.data.configuration.regions
    )
        return [];

    return state.restaurant.data.configuration.regions;
}

export function isDepotMode() {
    return User.instance.getBaseConstant('isDepotMode');
}

export function isSingleDepotMode() {
    return User.instance.getBaseConstant('isSingleDepotMode');
}

export function getDepotById(id, depots) {
    if (!id) return null;

    if (Array.isArray(depots)) {
        return depots.find(depot => depot.id === id);
    } else {
        const state = Store.store.getState();
        return ((state && state.restaurant && state.restaurant.data.depots) || []).find(depot => depot.id === id);
    }
}

export function getFirstDepotId() {
    const state = Store.store.getState();
    const depots = (state && state.restaurant && state.restaurant.data.depots) || [];
    return depots.length ? depots[0].id : '';
}

export function getTemplateMenuById(id, menu) {
    if (!id) return null;

    if (Array.isArray(menu)) {
        return menu.find(m => m.id === id);
    } else {
        const state = Store.store.getState();
        return (state?.menuTemplate?.data?.templates || []).find(menu => menu.id === id);
    }
}

export function getDepotName(order, depot) {
    if (!order) return '';

    const depotId = order.depotId || order.depot_id;

    if (!depotId) return '';

    if (depot) {
        return depot.name || '';
    } else {
        const state = Store.store.getState() || {};
        const depot = (((state.restaurant || {}).data || {}).depots || []).find(depot => depot.id === depotId) || {};
        return depot.name || '';
    }
}


export function getDepotPhone(order, depot) {
    if (!order || !order.depotId) return '';

    if (depot) {
        return (depot && depot.phone) || '';
    } else {
        const state = Store.store.getState();
        const depot = ((state && state.restaurant && state.restaurant.data.depots) || []).find(
            depot => depot.id === order.depotId
        );
        return (depot && depot.phone) || '';
    }
}


export function getDepotAvatar(order) {
    if (!order.depotId) return '';

    const state = Store.store.getState();
    const depot = (state.restaurant.data.depots || []).find(depot => depot.id === order.depotId);
    return depot && getConvertedCloudUrl(depot.logoUrl);
}

export function isAutoMode(restaurant) {
    return restaurant && restaurant.state === RESTAURANT_STATES.autoMode;
}

export function isPullMode(restaurant) {
    return restaurant && restaurant.configuration.courierAssociationMode !== COURIER_ASSOCIATION_MODE.push;
}

export function getRestaurantBounds(isWithRegion = true) {
    if (isGoogleMapEmpty()) return;
    const bounds = new window.google.maps.LatLngBounds();
    const state = Store.store.getState();
    const depotBounds = getDepotsBounds(state.restaurant.data);
    const regionBounds = getRegionBounds(state.restaurant.data);

    if (!depotBounds && !regionBounds) return;

    if (depotBounds) {
        bounds.union(regionBounds);
    }

    if (isWithRegion && regionBounds) {
        bounds.union(regionBounds);
    }

    return depotBounds;
}

export function getDefaultGoogleLatLngBounds() {
    return !isGoogleMapEmpty() && new window.google.maps.LatLngBounds();
}

export function getDepotsBounds(restaurant) {
    if (isGoogleMapEmpty() || !restaurant || !restaurant.depots) return null;
    let bounds = new window.google.maps.LatLngBounds();
    const isSetMapZoomBasedOnControlCenter =
        restaurant.selectedControlCenter &&
        restaurant.configuration &&
        (restaurant.configuration || {}).setMapZoomBasedOnControlCenter;

    restaurant.depots
        .filter(
            depot =>
                !locationIsEmpty((depot.address || {}).location, true) &&
                (!isSetMapZoomBasedOnControlCenter ||
                    !restaurant.selectedControlCenter ||
                    depot.defaultControlCenterId === restaurant.selectedControlCenter)
        )
        .forEach(depot => {
            const depotLatLng = getGoogleLatLngRegion(depot.address.location);
            if (depotLatLng) {
                bounds.extend(depotLatLng);
            }
        });
    return bounds;
}

export function getRegionBounds(restaurant) {
    if (isGoogleMapEmpty()) return null;
    let bounds = new window.google.maps.LatLngBounds();
    if (!restaurant || !restaurant.configuration || !restaurant.configuration.regions) return bounds;

    const isSetMapZoomBasedOnControlCenter =
        restaurant.selectedControlCenter && restaurant.configuration.setMapZoomBasedOnControlCenter;

    restaurant.configuration.regions
        .filter(
            region =>
                !isSetMapZoomBasedOnControlCenter ||
                (restaurant.selectedControlCenter &&
                    region.defaultControlCenterId === restaurant.selectedControlCenter) ||
                !region.defaultControlCenterId ||
                !restaurant.selectedControlCenter
        )
        .forEach(region => {
            (region.region || []).forEach(location => {
                if (!locationIsEmpty(location, true)) {
                    bounds.extend(getGoogleLatLngRegion(location));
                }
            });
        });
    return bounds;
}

export function getOrdersBounds(orderLocations) {
    if (isGoogleMapEmpty() || !Array.isArray(orderLocations) || !orderLocations.length) return null;
    let bounds = new window.google.maps.LatLngBounds();

    orderLocations.forEach(orderLocation => {
        if (!locationIsEmpty(orderLocation)) {
            const latLngRegion = getGoogleLatLngRegion(orderLocation);

            if (latLngRegion) {
                bounds.extend(latLngRegion);
            }
        }
    });
    return bounds;
}

export function getGoogleLatLngRegion(location) {
    return !locationIsEmpty(location) && !isGoogleMapEmpty()
        ? new window.google.maps.LatLng(
              parseFloat(location.latitude || location.lat || 0),
              parseFloat(location.longitude || location.lng || 0)
          )
        : null;
}

export function getRegionCenter() {
    const state = Store.store.getState();
    const restaurant = state.restaurant.data;
    const bounds = getRegionBounds(restaurant);
    if (!bounds) return null;
    const center = bounds.getCenter();
    return {
        latitude: center.lat() || 0,
        longitude: center.lng() || 0,
    };
}

export function isAllowOrderDeleteWithoutPassword() {
    const state = Store.store.getState();
    return state.restaurant.data.configuration && state.restaurant.data.configuration.allowOrderDeleteWithoutPassword;
}

export function isLowBattery(travelData) {
    if (!travelData) return false;
    return travelData.batteryPercentage > 0 && travelData.batteryPercentage <= 0.2;
}

export function isUniqueRegionNames(regions) {
    let result = false;
    regions.some(function(region, index) {
        for (let i = index + 1; i < regions.length; i++) {
            if (region.name === regions[i].name) {
                result = true;
                break;
            }
        }
        return result;
    });
    return !result;
}

export function isDisableCitySave(city) {
    const state = Store.store.getState();
    const restaurant = state.restaurant.data;
    return !restaurant || !city || !!restaurant.serviceCities.find(c => c.name === city);
}

export function getMapCenter() {
    const state = Store.store.getState();
    const restaurant = state.restaurant.data;
    let bounds = getDepotsBounds(restaurant);
    if (!bounds) return { lat: 0, lng: 0 };

    const newCenter = bounds.getCenter();
    return {
        lat: newCenter.lat() || 0,
        lng: newCenter.lng() || 0,
    };
}

export function getCurrencyValue() {
    const state = Store.store.getState();
    const restaurant = state.restaurant.data;
    return restaurant ? COUNTRY_CURRENCY_CODES_VALUE[restaurant.countryCode] || '$' : '$';
}

export function getReportDeliveryTimeStatistics(data, controlCenterStatistics) {
    const isExistCOntrolCenters = controlCenterStatistics && controlCenterStatistics.length;
    const existedControlCenters = controlCenterStatistics.map(controlCenter => controlCenter.controlCenterId);
    if (!data)
        return [
            {
                time: `${new Date().getHours()}:00`,
                values: (existedControlCenters || ['Control center']).map(controlCenter => ({
                    controlCenterId: controlCenter,
                    controlCenter:
                        (controlCenterStatistics || []).find(f => f.controlCenterId === controlCenter)['name'] ||
                        'Control center',
                    countOrders: 0,
                    averageDeliveryTime: 0,
                })),
            },
        ];

    return data.items
        .reduce((acc, item) => {
            const dateTime = moment(item.date);
            if (dateTime.isValid()) {
                const time = dateTime.format('YYYY-MM-DD HH');
                let index = acc.map(a => a.time).indexOf(time);

                if (index === -1) {
                    acc.push({
                        time: time,
                        values: isExistCOntrolCenters
                            ? existedControlCenters.map(controlCenter => ({
                                  controlCenterId: controlCenter,
                                  controlCenter: controlCenterStatistics.find(f => f.controlCenterId === controlCenter)[
                                      'name'
                                  ],
                                  countOrders: 0,
                                  averageDeliveryTime: 0,
                                  ordersInAvrgCalcualtation: 0,
                              }))
                            : [
                                  {
                                      controlCenterId: i18n.t('reports.TOTAL'),
                                      controlCenter: i18n.t('reports.TOTAL'),
                                      countOrders: 0,
                                      averageDeliveryTime: 0,
                                      ordersInAvrgCalcualtation: 0,
                                  },
                              ],
                    });

                    index = acc.map(a => a.time).indexOf(time);
                }

                if (isExistCOntrolCenters) {
                    const indexValue = acc[index].values.map(a => a.controlCenterId).indexOf(item.controlCenterId);
                    if (indexValue > -1) {
                        acc[index].values[indexValue].countOrders = acc[index].values[indexValue].countOrders + 1;

                        if (item.state === ORDER_STATE.delivered && item.deliveryDurationInMsec) {
                            const diffDeliveryTime = item.deliveryDurationInMsec;
                            acc[index].values[indexValue].averageDeliveryTime = acc[index].values[indexValue].ordersInAvrgCalcualtation === 0
                                ? diffDeliveryTime
                                : (acc[index].values[indexValue].averageDeliveryTime * acc[index].values[indexValue].ordersInAvrgCalcualtation + diffDeliveryTime) /
                                    (acc[index].values[indexValue].ordersInAvrgCalcualtation + 1);
                            acc[index].values[indexValue].ordersInAvrgCalcualtation = acc[index].values[indexValue].ordersInAvrgCalcualtation + 1;
                        }
                    }
                } else {
                    acc[index].values[0].countOrders = acc[index].values[0].countOrders + 1;

                    if (item.state === ORDER_STATE.delivered && item.deliveryDurationInMsec) {
                        const diffDeliveryTime = item.deliveryDurationInMsec;
                        acc[index].values[0].averageDeliveryTime = acc[index].values[0].ordersInAvrgCalcualtation === 0
                            ? diffDeliveryTime
                            : (acc[index].values[0].averageDeliveryTime * acc[index].values[0].ordersInAvrgCalcualtation + diffDeliveryTime) /
                                (acc[index].values[0].ordersInAvrgCalcualtation + 1);
                        acc[index].values[0].ordersInAvrgCalcualtation = acc[index].values[0].ordersInAvrgCalcualtation + 1;
                    }
                }
            }

            return acc;
        }, [])
        .map(res => ({ ...res, time: `${res.time.split(' ')[1]}:00` }));
}

export function getReportAverageDeliveryTimeStatistics(data) {
    if (!data)
        return {
            averageDeliveryTime: 0,
            averageMinDeliveryTime: 0,
            averageMaxDeliveryTime: 0,
            values: [
                {
                    time: `${new Date().getHours()}:00`,
                    minDeliveryTime: 0,
                    maxDeliveryTime: 0,
                },
            ],
        };

    return {
        averageDeliveryTime: data.averageDeliveryTime / 60,
        averageMinDeliveryTime: data.minDeliveryTime / 60,
        averageMaxDeliveryTime: data.maxDeliveryTime / 60,
        items: data.items
            .reduce((acc, item) => {
                const dateTime = moment(item.date);
                if (dateTime.isValid()) {
                    const time = dateTime.format('YYYY-MM-DD HH');
                    let index = acc.map(a => a.time).indexOf(time);

                    if (index === -1) {
                        acc.push({
                            time: time,
                            minDeliveryTime: 0,
                            maxDeliveryTime: 0,
                            averageDeliveryTime: 0,
                            ordersInAvrgCalcualtation : 0,
                        });
                        index = acc.map(a => a.time).indexOf(time);                    }
                    if (item.state === ORDER_STATE.delivered && item.deliveryDurationInMsec) {
                        const diffDeliveryTime = item.deliveryDurationInMsec / 1000 / 60;
                        acc[index].minDeliveryTime = acc[index].minDeliveryTime === 0 || diffDeliveryTime < acc[index].minDeliveryTime
                            ? diffDeliveryTime
                            : acc[index].minDeliveryTime;
                        acc[index].maxDeliveryTime = acc[index].maxDeliveryTime === 0 || diffDeliveryTime > acc[index].maxDeliveryTime
                            ? diffDeliveryTime
                            : acc[index].maxDeliveryTime;
                        acc[index].averageDeliveryTime = acc[index].ordersInAvrgCalcualtation === 0
                            ? diffDeliveryTime
                            : (acc[index].averageDeliveryTime * acc[index].ordersInAvrgCalcualtation + diffDeliveryTime) / (acc[index].ordersInAvrgCalcualtation + 1);
                        acc[index].ordersInAvrgCalcualtation = acc[index].ordersInAvrgCalcualtation + 1;
                    }
                }

                return acc;
            }, [])
            .map(res => ({ ...res, time: `${res.time.split(' ')[1]}:00` })),
    };
}

export function getTotalReportDeliveryTimeStatistics(data) {
    return data.map(val => ({
        time: val.time,
        values: [
            {
                controlCenterId: i18n.t('reports.TOTAL'),
                controlCenter: i18n.t('reports.TOTAL'),
                countOrders: val.values.reduce((a, b) => a + b.countOrders, 0),
            },
        ],
    }));
}

export function getOrderMenuProducts(order) {
    if (!order) return null;

    return {
        ...order,
        deliveryDate: order.delivery_datetime,
        creation_datetime: order.creation_datetime,
        sequenceId: order.sequence_id || '',
        type: order.delivery_type || order.type + 1,
        customerName: order.customer_name || '',
        deliveryPrice: order.delivery_price || 0,
        offerName: order.offer_name || '',
        items: getOrderMenuProductItems(order.products || order.content, order.businessInfo),
    };
}

export function getOrderMenuProductItems(products, businessInfo) {
    const state = Store.store.getState();
    const consumerOrderMenu = businessInfo ||
        state.restaurant.data.consumerOrderMenu || { categories: [], products: [] };

    const menuProducts = consumerOrderMenu.categories.reduce((acc, consumerOrderMenu) => {
        return acc.concat(consumerOrderMenu.products);
    }, []);
    const menuOptions = consumerOrderMenu.categories.reduce((acc, consumerOrderMenu) => {
        let newOptions = [];

        consumerOrderMenu.products.forEach(product => {
            product.extras.forEach(extra => {
                extra.options.forEach(extraOption => {
                    newOptions.push({
                        categoryId: product.category_id,
                        extraId: extra.id,
                        name: extraOption.name,
                        suboptions: extraOption.suboptions.map(suboption => ({
                            id: suboption.id,
                            name: suboption.name,
                        })),
                    });
                });
            });
        });

        return acc.concat(newOptions);
    }, []);

    if (!products) {
        return [];
    }

    return products.reduce((acc, product) => {
        const menuProduct = menuProducts.find(f => f.id === (product.id || product.item)) || {};
        acc.push({
            ...product,
            description: menuProduct.description,
            image: menuProduct.images,
            name: menuProduct.name || product.name || product.item,
            options: (product.options || product.addons || []).map(option => {
                const subOptions = (option.suboptions || []).map(sub => {
                    const menuOption =
                        menuOptions.find(f => f.suboptions.find(suboption => suboption.id === sub.id)) || {};
                    return {
                        ...sub,
                        optionName: menuOption.name,
                        halfOption: sub.half_option,
                        ...(menuOption.suboptions || []).find(f => f.id === sub.id),
                    };
                });

                return {
                    ...option,
                    suboptions: subOptions,
                };
            }),
            ingredientsNames: (product.ingredients || []).map(ingredient => {
                const menuProductIngredient = (menuProduct.ingredients || []).find(f => f.id === ingredient) || {};
                return menuProductIngredient['name'];
            }),
            total: product.full_price || product.price,
        });

        return acc;
    }, []);
}

export function getReshipmentOrderData(data) {
    return {
        products: (data.products || []).map(product => getInitModel(MODEL_TYPE.adminOrderProductServerModel, product)),
        reshipment_type: data.reshipment_type,
        notes: data.notes,
    };
}

export function getMenuCredentials() {
    const state = Store.store.getState();
    return (
        (state &&
            state.restaurant.data.paymentsConfiguration && {
                login: state.restaurant.data.paymentsConfiguration.orderingUser,
                password: state.restaurant.data.paymentsConfiguration.orderingPassword,
            }) ||
        {}
    );
}

export function getAdminOrdersAcceptDialogItems() {
    return [
        { key: 0, value: i18n.t('admin-orders.REJECT_DIALOG_WITH_REFUND_LABEL') },
        { key: 1, value: i18n.t('admin-orders.REJECT_DIALOG_NO_REFUND_LABEL') },
    ];
}

export function getDeliveryZoneData(data) {
    const newData = {
        enabled: data.enabled,
        id: data.id,
        minimum: data.minimum,
        name: data.name,
        operation_profile: data.operation_profile,
        price: data.price,
        type: data.type,
        price_calculation_mode: data.price_calculation_mode_bool ? 0 : null,
    };
    const resData = {
        region: null,
        circle: null,
        reference: null,
        delivery_zone_reference: null,
    };

    switch (data.type) {
        case DELIVERY_ZONE_TYPE.polygon:
            if (Array.isArray(data.region)) {
                resData.region = data.region.map(region => ({
                    lat: region.latitude || region.lat,
                    lng: region.longitude || region.lng,
                }));
            }
            break;
        case DELIVERY_ZONE_TYPE.circle:
            if (data.circle) {
                resData.circle = {
                    center: {
                        lat: data.circle.center.latitude || data.circle.center.lat,
                        lng: data.circle.center.longitude || data.circle.center.lng,
                    },
                    radio: data.circle.radius / 1000,
                };
            }
            break;
        case DELIVERY_ZONE_TYPE.reference:
            resData.reference = data.reference || data.reference === 0 ? { region_id: parseInt(data.reference) } : null;
            break;
        case DELIVERY_ZONE_TYPE.deliveryZoneReference:
            resData.delivery_zone_reference = { zone_id: data.zone_id };
            break;
        default:
            break;
    }

    newData.data = resData;
    return newData;
}

export function getNewCategoryOrder(depotId) {
    const state = Store.store.getState();
    const depot = state && state.restaurant.data && state.restaurant.data.depots.find(depot => depot.id === depotId);
    const sortedCategories = ((depot && depot.businessMenu && depot.businessMenu.categories) || [])?.sort(
        (a, b) => b.order - a.order
    );
    return sortedCategories.length ? sortedCategories[0].order + 1 : 0;
}

export function getDepotCategories(depotId) {
    const state = Store.store.getState();
    const depot =
        state &&
        state.restaurant.data &&
        state.restaurant.data.depots &&
        state.restaurant.data.depots.find(depot => depot.id === depotId);
    return (depot && depot.businessMenu && depot.businessMenu.categories) || [];
}

export function getCurrentBusinessMenu() {
    const state = Store.store.getState();
    return state.restaurant.data.businessMenu || {};
}

export function getBusinessMenuCategories() {
    const state = Store.store.getState();
    return (
        (state.restaurant.data &&
            state.restaurant.data.businessMenu &&
            state.restaurant.data.businessMenu.categories) ||
        []
    );
}

export function getDepotCategoryExtras(depotId, categoryId, store) {
    const state = store || Store.store.getState();
    const menu = state.restaurant.data && state.restaurant.data.businessMenu;
    const category = ((menu && menu.categories) || []).find(category => category.id === categoryId) || {};

    const extras = (menu && menu.extras) || [];

    return (category.extras || []).concat(extras);
}

export function getBusinessProduct(categoryId, productId, store) {
    const state = store || Store.store.getState();
    const menu = state.restaurant.data && state.restaurant.data.businessMenu;
    const category = ((menu && menu.categories) || []).find(category => category.id === categoryId) || {};
    const product = (category.products || []).find(p => p.id === productId);
    return product;
}

export function getBusinessExtra(categoryId, productId, extraId, store) {
    const state = store || Store.store.getState();
    const menu = state.restaurant.data && state.restaurant.data.businessMenu;
    const category = ((menu && menu.categories) || []).find(category => category.id === categoryId) || {};
    const product = (category.products || []).find(p => p.id === productId) || {};
    const extra = (product.extras || []).find(e => e.id === extraId);
    return extra;
}

export function getAllBusinessSuboption(categoryId, productId, extraId, suboptionId, store) {
    const state = store || Store.store.getState();
    const menu = state.restaurant.data && state.restaurant.data.businessMenu;
    const category = ((menu && menu.categories) || []).find(category => category.id === categoryId) || {};
    const product = (category.products || []).find(p => p.id === productId) || {};
    const extra = (product.extras || []).find(e => e.id === extraId);
    const suboptions = (extra.options || []).flatMap(e => e.suboptions);
    return suboptions;
}

export function prepareOverrideObject(object, initItem, overrides) {
    const newOverride = [];
    const result = {...object};
    delete result.overridesProperty;
    Object.keys(result).forEach(key => {
        if(result[key] !== initItem[key]) {
            newOverride.push({
                id: initItem.id,
                language:null,
                name: key,
                value: result[key],
            });
        }
    });
    return (overrides || []).filter(o => o.id !== object.id).concat(newOverride);
}

export function getAllEnabledOverrideDataFromMenu(menu) {
    if(!menu) return [];
    const categories = (menu.categories) || [];
    const products = categories.flatMap(c => c.products);
    const categoryExtras = categories.flatMap(cat => cat.extras || []);
    const categoryOptions = categoryExtras.flatMap(extra => extra.options || []);
    const categorySuboptions = categoryOptions.flatMap(option => option.suboptions || []);
    const globalExtras = menu.extras || [];
    const globalOptions = globalExtras.flatMap(extra => extra.options);
    const globalSuboptions = globalOptions.flatMap(option => option.suboptions || []);

    return []
        .concat(categories)
        .concat(products)
        .concat(categoryExtras).concat(categoryOptions).concat(categorySuboptions)
        .concat(globalExtras).concat(globalOptions).concat(globalSuboptions);
}

export function getAllExtra(menu) {
    const globalExtra = menu.extras || [];
    const extras = menu.categories.flatMap(cat => cat.extras) || [];
    return [].concat(extras).concat(globalExtra);
}

export function getAllOption(menu) {
    const globalExtra = menu.extras || [];
    const extras = menu.categories.flatMap(cat => cat.extras) || [];

    const globalOptions = globalExtra.flatMap(extra => extra.options);
    const options = extras.flatMap(extra => extra.options);

    return [].concat(options).concat(globalOptions);
}

export function getAllSuboption(menu) {
    const globalExtra = menu.extras || [];
    const globalOption = globalExtra.flatMap(extra => extra.options);
    const globalSuboptions = globalOption.flatMap(opt => opt.suboptions);

    const extras = menu.categories.flatMap(cat => cat.extras) || [];
    const options = extras.flatMap(extra => extra.options);
    const suboptions = options.flatMap(opt => opt.suboptions);

    return [].concat(suboptions).concat(globalSuboptions);
}

export function getOveridesArray(currentObject, oldObject) {
    const override = [];
    for(let propName in currentObject) {
        if(currentObject[propName] !== oldObject[propName]) {
            override.push({
                name: propName,
                value: currentObject[propName]
            });
        } else {
            override.push({
                name: propName,
                value: null
            });
        }
    }
    return override;
}

export function getCurrentOrderingMenuDepotId() {
    const state = Store.store.getState();
    const { id } = getHistoryLocationParams(window.location.search);
    return (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.id) || id;
}

export function getOrderingMenuExtraOptions(extraId, store) {
    const state = store || Store.store.getState();
    const businessMenu = (state.restaurant.data && state.restaurant.data.businessMenu) || {};
    const extras = (businessMenu && businessMenu.extras) || [];
    const extra = extras.find(f => f.id === extraId);
    return (extra && extra.options) || [];
}

export function getOrderingMenuOptions(categoryId, extraId, store) {
    const state = store || Store.store.getState();
    const categories = (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
    const category = categories.find(f => f.id === categoryId);
    const extras = (category && category.extras) || [];
    const extra = extras.find(f => f.id === extraId);
    return (extra && extra.options) || [];
}

export function getFleetProducerType(){
    const result = [
        {
            key: 0, value: i18n.t('fleet.NONE'), icon: ''
        },
        {
            key: 1, value: i18n.t('fleet.NIU'), icon: 'niu-icon'
        }];
    return result
}

export function getFleetVehicleType(){
    const result = [{
        key: 0, value: i18n.t('fleet.SCOOTER'), icon: "kickScooterDark",
    },
        {
            key: 2, value: i18n.t('fleet.CAR'), icon: "carDark",
        },
        {
            key: 1, value: i18n.t('fleet.BICYCLE'), icon: "deliveryDark",
        }];
    return result
}


export function getNameForExtraById(depotId, extraId, store) {
    const state = store || Store.store.getState();
    const extras = (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.extras) || [];
    return (extras.find(e => e.id === extraId) || {}).name || '';
}

export function getNameForOptionExtraById(depotId, extraId, optionId, store) {
    const state = store || Store.store.getState();
    const extras = (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.extras) || [];
    const extra = extras.find(e => e.id === extraId) || {};
    return ((extra.options || []).find(o => o.id === optionId) || {}).name || '';
}

export function getCurrentContryCode() {
    const state = Store.store.getState();
    return (state && state.restaurant.data && state.restaurant.data.countryCode) || 'HE';
}

export function getCurrentCurrency() {
    const state = Store.store.getState();
    return (
        COUNTRY_CURRENCY_CODES[state && state.restaurant.data && state.restaurant.data.countryCode] ||
        COUNTRY_CURRENCY_CODES.US
    );
}

export function getCampaignsExecutionStatusLabel(type) {
    switch (type) {
        case CAMPAIGN_EXECUTION_STATE_TYPES.pending:
            return i18n.t('enums.campaign.CAMPAIGN_EXECUTION_STATE_PENDING');
        case CAMPAIGN_EXECUTION_STATE_TYPES.active:
            return i18n.t('enums.campaign.CAMPAIGN_EXECUTION_STATE_ACTIVE');
        case CAMPAIGN_EXECUTION_STATE_TYPES.finished:
            return i18n.t('enums.campaign.CAMPAIGN_EXECUTION_STATE_FINISHED');
        default:
            return '';
    }
}

export function getDiscountTypeLabel(type) {
    switch (type) {
        case DISCOUNT_TYPE.coupon:
            return i18n.t('settings.orderingmenu.MENU_DISCOUNT_TYPE_COUPON');
        case DISCOUNT_TYPE.promotion:
            return i18n.t('settings.orderingmenu.MENU_DISCOUNT_TYPE_PROMOTION');
        default:
            return '';
    }
}

export function getPeriodsByDate(startDate, endDate) {
    if(startDate && endDate){
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);
        const formattedStartDate = `${i18n.t(`covertTime.MONTH_SHORT_${startDateMoment.month()}`)} ${startDateMoment.date()}, ${startDateMoment.year()}`;
        const formattedEndDate = `${i18n.t(`covertTime.MONTH_SHORT_${endDateMoment.month()}`)} ${endDateMoment.date()}, ${endDateMoment.year()}`;

        return `${formattedStartDate} - ${formattedEndDate}`;
    } else {
        return '';
    }
}

export function getStageByNumber(stage) {
    let stageResult = '';
    switch(stage) {
        case 0:
            stageResult = i18n.t('stagePeriods.PENDING'); break;
        case 1:
            stageResult = i18n.t('stagePeriods.APPROVED'); break;
        case 2:
            stageResult = i18n.t('stagePeriods.EXECUTION'); break;
        case 3:
            stageResult = i18n.t('stagePeriods.PAYMENT_READY'); break;
        case 4:
            stageResult = i18n.t('stagePeriods.DECLINED'); break;
        default:
            stageResult = '';
    }
    return stageResult;
}


export function getDiscountOrderTypeLabel(type) {
    switch (type) {
        case DISCOUNT_ORDER_TYPE.any:
            return i18n.t('settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_ANY');
        case DISCOUNT_ORDER_TYPE.delivery:
            return i18n.t('settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_DELIVERY');
        case DISCOUNT_ORDER_TYPE.takeAway:
            return i18n.t('settings.orderingmenu.MENU_DISCOUNT_ORDER_TYPE_TAKEAWAY');
        default:
            return '';
    }
}

export function getDiscountTargetType(type) {
    switch (type) {
        case OFFER_DISCOUNT_TARGET.products:
            return i18n.t('enums.offerDiscountTarget.PRODUCTS');
        case OFFER_DISCOUNT_TARGET.delivery:
            return i18n.t('enums.offerDiscountTarget.DELIVERY');
        case OFFER_DISCOUNT_TARGET.product:
            return i18n.t('enums.offerDiscountTarget.PRODUCT');
        default:
            return '';
    }
}

export function getDiscountCalculationBaseType(type) {
    switch (type) {
        case OFFER_DISCOUNT_TARGET.products:
            return i18n.t('enums.offerCalculationBase.PRODUCTS');
        case OFFER_DISCOUNT_TARGET.delivery:
            return i18n.t('enums.offerCalculationBase.DELIVERY');
        default:
            return '';
    }
}

export function getCampaignType(type) {
    switch (type) {
        case CAMPAIGN_TYPES.push:
            return i18n.t('enums.campaign.CAMPAIGN_TYPE_PUSH');
        default:
            return '';
    }
}

export function getDepotDefaultRegionControlCenterName(item) {
    const region = getRegions().find(f => f.id.toString() === item.deliveryRegionId.toString()) || {};
    const controlCenter = getControlCenterById(item.controlCenterId) || {};
    return `${region.name || ''}${controlCenter.name ? '-' + controlCenter.name : ''}`;
}

export function getAllOrders() {
    const state = Store.store.getState();
    if (!((state || {}).order || {}).data) return [];
    const activeOrders = state.order.data.activeOrders;
    const pendingPreOrders = state.order.data.pendingPreOrders;
    return activeOrders.concat(pendingPreOrders);
}

export function getPendingOrdersItems(type, isShowPreorders, orderData, users, externalCycles) {
  let activeOrders = [];
  let pendingPreOrders = [];
  let historyOrders = [];

  if(orderData) {
    activeOrders = orderData.activeOrders;
    pendingPreOrders = orderData.pendingPreOrders;
    historyOrders = orderData.historyOrders;
  } else {
    const state = Store.store.getState();
    if (!((state || {}).order || {}).data) return [];

    activeOrders = state.order.data.activeOrders;
    pendingPreOrders = state.order.data.pendingPreOrders; historyOrders = state.order.data.historyOrders;
  }

    let result = [];

    switch (type) {
        case PENDING_ORDERS_FILTER_TYPE.active:
            result = pendingOrdersFilter((activeOrders || []).filter(
                order =>
                    order.process_step === ORDER_PROCESS_STEP.waitingForApproval ||
                    order.process_step === ORDER_PROCESS_STEP.waitingForCourierAssignment ||
                    order.process_step === ORDER_PROCESS_STEP.waitingForPickup ||
                    order.isAssistOrder
            ));
            break;
        case PENDING_ORDERS_FILTER_TYPE.pending:
            result = pendingOrdersSort((activeOrders || [])
        .filter(order => order.process_step === ORDER_PROCESS_STEP.waitingForApproval || order.isAssistOrder));
            break;
        case PENDING_ORDERS_FILTER_TYPE.cooking:
            result = pendingOrdersCookingSort((activeOrders || []).filter(
                order => order.process_step === ORDER_PROCESS_STEP.waitingForCourierAssignment
            ));
            break;
        case PENDING_ORDERS_FILTER_TYPE.ready:
            result = pendingOrdersCouriersOlderSort(
        (activeOrders || []).filter(order => order.process_step === ORDER_PROCESS_STEP.waitingForPickup),
        users,
        externalCycles
      );
            break;
        case PENDING_ORDERS_FILTER_TYPE.delivering:
            result = pendingOrdersCouriersOlderSort(
        (activeOrders || []).filter(order => order.process_step === ORDER_PROCESS_STEP.delivering),
            users,
        externalCycles
      );break;
        case PENDING_ORDERS_FILTER_TYPE.preorders:
            result = pendingOrdersFilter(pendingPreOrders || []);
            break;
        case PENDING_ORDERS_FILTER_TYPE.history:
            result = pendingHistoryOrdersFilter(historyOrders || []);
            break;
        default:
            break;
    }

    const orders =
        isShowPreorders && type !== PENDING_ORDERS_FILTER_TYPE.preorders
            ? result.concat(pendingPreOrders || [])
            : result;

    return orders;
}

export function getPendingOrdersContainerConfig(filterType, orderData, users, externalCycles) {
    const isDesktop = getPendingOrdersIsDesktop();
    const isIpad = getPendingOrdersIsIpad();
    const filterTypesExcept = [
        PENDING_ORDERS_FILTER_TYPE.delivering,
        PENDING_ORDERS_FILTER_TYPE.history,
        PENDING_ORDERS_FILTER_TYPE.preorders,
    ];
    const isShowThreeColumn = (isDesktop || isIpad) && !filterTypesExcept.includes(filterType);
    const showPendingLabel =
        (isDesktop || isIpad) &&
        [
            PENDING_ORDERS_FILTER_TYPE.active,
            PENDING_ORDERS_FILTER_TYPE.cooking,
            PENDING_ORDERS_FILTER_TYPE.pending,

            PENDING_ORDERS_FILTER_TYPE.ready,
        ].includes(filterType);
    const firstContainerItems = getPendingOrdersItems(
        (isDesktop || isIpad) && filterType === PENDING_ORDERS_FILTER_TYPE.active
            ? PENDING_ORDERS_FILTER_TYPE.pending
            : filterType
    , false, orderData, users, externalCycles);

    return {
        isDesktop,
        isIpad,
        showPendingLabel,
        isShowThreeColumn,
        filterTypesExcept,
        firstContainerItems,
        secondContainerItems: isShowThreeColumn && getPendingOrdersItems(PENDING_ORDERS_FILTER_TYPE.cooking),
        thirdContainerItems: isShowThreeColumn && getPendingOrdersItems(PENDING_ORDERS_FILTER_TYPE.ready),
    };
}

export function getPendingOrdersTypes() {
    const desktopPending = [
        PENDING_ORDERS_FILTER_TYPE.active,
        PENDING_ORDERS_FILTER_TYPE.delivering,
        PENDING_ORDERS_FILTER_TYPE.preorders,
        PENDING_ORDERS_FILTER_TYPE.history,
    ];
    return getPendingOrdersIsDesktop() || getPendingOrdersIsIpad()
        ? desktopPending
        : [
              ...getPendingActiveTypes(),
              PENDING_ORDERS_FILTER_TYPE.delivering,
              PENDING_ORDERS_FILTER_TYPE.preorders,
              PENDING_ORDERS_FILTER_TYPE.history,
          ];
}

export function getPendingActiveTypes() {
    return [PENDING_ORDERS_FILTER_TYPE.pending, PENDING_ORDERS_FILTER_TYPE.cooking, PENDING_ORDERS_FILTER_TYPE.ready];
}

export function getFirstPendingActiveTypeOrder() {
    let type = null;
    let order = null;
    const indexActiveType = getPendingActiveTypes().findIndex(type => getPendingOrdersItems(type).length > 0);

    if (indexActiveType > -1) {
        type = PENDING_ORDERS_FILTER_TYPE.active;
        order = getPendingOrdersItems(getPendingActiveTypes()[indexActiveType])[0];
    } else {
        type = getPendingActiveTypes()
            .slice(1)
            .findIndex(type => getPendingOrdersItems(type).length > 0);
        order = getPendingOrdersItems(type)[0];
    }

    return { type, order };
}

export function getOrderAndTypeById(orderId) {
    const isFullPreview = getPendingOrdersIsDesktop() || getPendingOrdersIsIpad();
    const order = getAllOrders().find(order => order.id === orderId);

    const isActiveTabs = [ORDER_PROCESS_STEP.waitingForApproval, ORDER_PROCESS_STEP.waitingForCourierAssignment, ORDER_PROCESS_STEP.waitingForPickup].includes(order.process_step);
    if(isFullPreview && (isActiveTabs || order.isAssistOrder)) {
        return { order, type: PENDING_ORDERS_FILTER_TYPE.active };
    }
    if(!isFullPreview && (order.process_step === ORDER_PROCESS_STEP.waitingForApproval || order.isAssistOrder)) {
        return { order, type: PENDING_ORDERS_FILTER_TYPE.pending };
    }
    if(order.process_step === ORDER_PROCESS_STEP.delivering) {
        return { order, type: PENDING_ORDERS_FILTER_TYPE.delivering };
    }
    if(order.process_step === ORDER_PROCESS_STEP.waitingForPickup) {
        return { order, type: PENDING_ORDERS_FILTER_TYPE.ready };
    }
    if(order.process_step === ORDER_PROCESS_STEP.waitingForCourierAssignment) {
        return { order, type: PENDING_ORDERS_FILTER_TYPE.cooking };
    }

    return getFirstPendingActiveTypeOrder();
}

export function getCityList(city) {
    const state = Store.store.getState();
    const serviceCities =
        (state.restaurant &&
            state.restaurant.data &&
            state.restaurant.data.configuration &&
            state.restaurant.data.configuration.serviceCities) ||
        [];

    return city && !serviceCities.find(f => f === city)
        ? [{ city, skipForExistCheck: true}].concat(serviceCities.map(city => ({ city })))
        : serviceCities.map(city => ({ city }));
}

export function getFilteredCityList(cities, city) {
    let result = [...cities];
    if(cities && cities.length > 0 && city){
        result = cities.filter((e) => e.city.toLowerCase().includes(city.toLowerCase()));
    }

    return result;
}

export function getDefaultCity(depotId) {
    if (!depotId) return '';

    const state = Store.store.getState();
    const serviceCities =
        (state.restaurant &&
            state.restaurant.data.configuration &&
            state.restaurant.data.configuration.serviceCities) ||
        [];
    const depot = state.restaurant && state.restaurant.data.depots.find(f => f.id === depotId);
    const depotCity = depot && depot.address && depot.address.address && depot.address.address.city;

    const firstCity = serviceCities[0] && serviceCities[0].city;
    return (
        (depotCity && (serviceCities.find(f => getStringValue(f) === getStringValue(depotCity)) || depotCity)) ||
        firstCity
    );
}

export function getRestaurantConfigPropValue(prop) {
    const state = Store && Store.store.getState();

    return (
        state &&
        state.restaurant &&
        state.restaurant.data &&
        state.restaurant.data.configuration &&
        state.restaurant.data.configuration[prop]
    );
}

export function getLabelWithMetric(label) {
    const isUSMetric = getRestaurantConfigPropValue('uSBasedMetricSystem');
    return isUSMetric ? `${label}_US` : label;
}

export function getRestaurantLocation() {
    const state = Store.store.getState();
    return state && state.restaurant && state.restaurant.data.location;
}

export function getAddressSearchRadius(depotId) {
    const state = Store.store.getState();
    const depot = depotId && state.restaurant && state.restaurant.data.depots.find(f => f.id === depotId);

    return {
        radius: (depot && depot.radius) || SEARCH_RADIUS,
        location: getLatLngFromLocation(
            (depot && depot.address && depot.address.location) || (state.restaurant && state.restaurant.data.location),
            true
        ),
    };
}

export function getPusherChannelName() {
    const connData = getUserConnectionData() || {};
    return (connData.depotId || connData.rest || '').toLowerCase();
}

export function getDepotModeId() {
    const connData = getUserConnectionData() || {};
    return connData.depotId;
}

export function getCurrentSelectedControlCenter() {
    const state = Store.store.getState();
    return state && state.restaurant && state.restaurant.data && state.restaurant.data.selectedControlCenter;
}

export function getCouriersBarGroupItemData(type, { disableBackupCouriers, itemsLength }) {
    let data = {};
    switch (type) {
        case COURIER_GROUP_MODEL_TYPE.onRouteGroup:
            data = createCourierGroupModel(i18n.t('dashboard.couriersbar.ON_ROUTE_GROUP_LABEL'), true, true);
            data.showCountOrders = true;
            data.isActive = true;
            break;
        case COURIER_GROUP_MODEL_TYPE.approvedGroup:
            data = createCourierGroupModel(i18n.t('dashboard.couriersbar.APPROVED_GROUP_LABEL'), true, true);
            data.showCountOrders = true;
            data.isActive = true;
            break;
        case COURIER_GROUP_MODEL_TYPE.comingBackGroup:
            data = createCourierGroupModel(i18n.t('dashboard.couriersbar.COMING_BACK_GROUP_LABEL'), true, true);
            data.hide = !getRestaurantConfigPropValue('isSingleLocationAccount');
            data.isActive = true;
            break;
        case COURIER_GROUP_MODEL_TYPE.awayGroup:
            data = createCourierGroupModel(i18n.t('dashboard.couriersbar.AWAY_GROUP_LABEL'), true, true);
            data.hide = isDepotMode() && !itemsLength;
            break;
        case COURIER_GROUP_MODEL_TYPE.waitingGroup:
            data = createCourierGroupModel(i18n.t('dashboard.couriersbar.WAITING_GROUP_LABEL'), true, true);
            data.showCountOrders = true;
            data.isActive = true;
            break;
        case COURIER_GROUP_MODEL_TYPE.backupGroup:
            data = createCourierGroupModel(i18n.t('dashboard.couriersbar.BACKUP_GROUP_LABEL'));
            data.hide = disableBackupCouriers;
            break;
        case COURIER_GROUP_MODEL_TYPE.externalCycle:
            data = createCourierGroupModel(i18n.t('externalCycles.EXTERNAL_CYCLE_GROUP_LABEL'), false, true);
            data.isActive = true;
            break;
        default:
            break;
    }

    return data;
}

export function getCouriersBarGroupItems(type, { users, orders, externalCycles, selectedControlCenters, searchString }) {
    switch (type) {
        case COURIER_GROUP_MODEL_TYPE.onRouteGroup:
            return getCourierGroupDataSort(
                type,
                users.filter(
                    user => couriersBarFilter(user, orders, searchString, selectedControlCenters) && onRouteFilter(user)
                )
            );
        case COURIER_GROUP_MODEL_TYPE.approvedGroup:
            return getCourierGroupDataSort(
                type,
                users.filter(
                    user => couriersBarFilter(user, orders, searchString, selectedControlCenters) && approvedFilter(user)
                )
            );
        case COURIER_GROUP_MODEL_TYPE.comingBackGroup:
           return getCourierGroupDataSort(
              type,
              users.filter(
                  user =>
                      couriersBarFilter(user, orders, searchString, selectedControlCenters) &&
                      comingBackFilter(user)
              )
          );
        case COURIER_GROUP_MODEL_TYPE.awayGroup:
            const items = users.filter(
                user => couriersBarFilter(user, orders, searchString, selectedControlCenters) && awayFilter(user)
            );
            return isDepotMode() && !items.length ? [] : getCourierGroupDataSort(type, items);
        case COURIER_GROUP_MODEL_TYPE.waitingGroup:
        case COURIER_GROUP_MODEL_TYPE.backupGroup:
            const includeCycleStates = [CYCLE_STATE.started, CYCLE_STATE.closed, CYCLE_STATE.comingBack];
            const waitingItems = users.filter(
                user => waitingFilter(user) && couriersBarFilter(user, orders, searchString, selectedControlCenters)
            );
            const backupItems = users.filter(
                user =>
                    backUpCouriersFilter(user) &&
                    couriersBarFilter(user, orders, searchString, selectedControlCenters, true) &&
                    (!user.cycle || includeCycleStates.includes(user.cycle.state)) &&
                    !waitingItems.find(f => f.userId === user.userId)
            );

            return getCourierGroupDataSort(
                type,
                type === COURIER_GROUP_MODEL_TYPE.waitingGroup ? waitingItems : backupItems
            );
        case COURIER_GROUP_MODEL_TYPE.externalCycle:
            return externalCycleCouriersBarFilter(externalCycles, selectedControlCenters);
        default:
            return [];
    }
}

export function getShowDescription(group) {
    const travelData = group.travelData || getInitModel(MODEL_TYPE.travelDataModel);

    return (
        travelData.gpsDisabled ||
        (group.cycle &&
            group.cycle.state !== CYCLE_STATE.started &&
            travelData.state === COURIER_LOCATION_STATE.standing &&
            !travelData.stoppedAtAllowedLocation) ||
        travelData.state === COURIER_LOCATION_STATE.lost ||
        isLowBattery(travelData)
    );
}

export function getDescriptionLocationState(group) {
    const travelData = group.travelData || getInitModel(MODEL_TYPE.travelDataModel);

    if (travelData.gpsDisabled) {
        return i18n.t('dashboard.couriersbar.COURIER_DISABLED_GPS');
    }
    if (
        group.cycle &&
        group.cycle.state !== CYCLE_STATE.started &&
        travelData.state === COURIER_LOCATION_STATE.standing &&
        !travelData.stoppedAtAllowedLocation
    ) {
        return i18n.t('dashboard.couriersbar.COURIER_STOPPED');
    }
    if (travelData.state === COURIER_LOCATION_STATE.lost) {
        return i18n.t('dashboard.couriersbar.COURIER_LOST');
    }
    if (isLowBattery(travelData)) {
        return i18n.t('dashboard.couriersbar.LOW_BATTERY_LABEL');
    }
    return '';
}

export function getExternalCycleDriverInfo(externalCycle, propName) {
    let info = '';
    if ((externalCycle || {}).driverInfo) {
        if (propName) {
            return externalCycle.driverInfo[propName] || '';
        }

        if (externalCycle.driverInfo.name) {
            info += `${externalCycle.driverInfo.name}`;
        }

        if (externalCycle.driverInfo.carModel) {
            info += `, ${externalCycle.driverInfo.carModel}`;
        }

        if (externalCycle.driverInfo.carNumber) {
            info += `(${externalCycle.driverInfo.carNumber})`;
        }
    }
    return info;
}

export function getExternalCycleLogo(provider) {
  return (provider && (provider.avatarUrl || getExternalCycleLogoByProvider(provider.provider))) || "";
}

export function getExternalCycleLogoByProvider(provider) {
    switch (provider) {
        case EXTERNAL_COURIER_PROVIDER.yango:
            return defaultIcons.yango;
        case EXTERNAL_COURIER_PROVIDER.delivApp:
            return defaultIcons.default;
        case EXTERNAL_COURIER_PROVIDER.wolt:
            return defaultIcons.wolt;
        case EXTERNAL_COURIER_PROVIDER.doorDash:
            return defaultIcons.doorDash;
        case EXTERNAL_COURIER_PROVIDER.gett:
            return defaultIcons.gett;
        default:
            return '';
    }
}

export function getExternalCycleDistance(group) {
    const distance = getDistance(group);
    return distance ? ` ${distance} ${getLabelWithMetric('dashboard.orders.DISTANCE_LABEL')} ` : '';
}

export function getExternalCyclePriceInfo(externalCycle) {
    if (!externalCycle || !externalCycle.priceInfo || !externalCycle.priceInfo.price) return '';
    return `${externalCycle.priceInfo.price} ${getCurrencyIcons(externalCycle.priceInfo.currency)}`;
}

function getCourierGroupDataSort(type, items) {
    switch (type) {
        case COURIER_GROUP_MODEL_TYPE.onRouteGroup:
            return onRouteCourierGroupSort(items);
        default:
            return items?.sort((a, b) => courierQueueSort(a, b));
    }
}

function createCourierGroupModel(label, highlightGroup, openGroup) {
    return {
        label,
        items: [],
        open: openGroup,
        highlightGroup: highlightGroup,
        hide: false,
        isActive: false,
        showCountOrders: false,
    };
}

export function getDepot(depots, depotId, isFirstDepot = false) {
    if (!isFirstDepot && !depotId) return {};

    if (depots) {
        return (isFirstDepot ? (depots || [])[0] : (depots || []).find(depot => depot.id === depotId)) || {};
    } else {
        const state = Store.store.getState();
        return (
            (isFirstDepot
                ? ((state && state.restaurant && state.restaurant.data.depots) || [])[0]
                : ((state && state.restaurant && state.restaurant.data.depots) || []).find(
                      depot => depot.id === depotId
                  )) || {}
        );
    }
}

export function getDefaultLocation(location) {
    if(!locationIsEmpty(location)) return location;
    const store = Store.store.getState() || {};
    const restaurant = store.restaurant.data || {};
    const depots = restaurant.depots || [];
    const firstDepotLocation = ((depots[0] || {}).address || {}).location;
    return firstDepotLocation || restaurant.location;
}

export function getDefaultRegion(center, locationIsFromCenter = false, isReturnDefaultIfEmpty = true) {
    const store = Store.store.getState() || {};
    const restaurant = store.restaurant.data || {};
    const depots = restaurant.depots || [];
    const firstDepotLocation = ((depots[0] || {}).address || {}).location;
    let location = locationIsFromCenter
        ? center || firstDepotLocation || restaurant.location
        : firstDepotLocation || (locationIsEmpty(restaurant.location) ? center : restaurant.location);

    if (!location || locationIsEmpty(location)) {
        if(!isReturnDefaultIfEmpty) return [];

        location = firstDepotLocation || restaurant.location;
        if (!location || locationIsEmpty(location)) return [];
    }
    const locationLat = location.lat || location.latitude;
    const locationLng = location.lng || location.longitude;

    return [
        {
            latitude: locationLat - MAP_POLYGON_DIFF,
            longitude: locationLng - MAP_POLYGON_DIFF,
        },
        {
            latitude: locationLat - MAP_POLYGON_DIFF,
            longitude: locationLng + MAP_POLYGON_DIFF,
        },
        {
            latitude: locationLat + MAP_POLYGON_DIFF,
            longitude: locationLng + MAP_POLYGON_DIFF,
        },
        {
            latitude: locationLat + MAP_POLYGON_DIFF,
            longitude: locationLng - MAP_POLYGON_DIFF,
        },
    ];
}

function isGoogleMapEmpty() {
    return !window || !window.google || !window.google.maps;
}

export function generateRandomString(length) {
    const symbols = '1234567890qwertyuiopasdfghjklzxcvbnm';
    return Array(length || 12)
        .fill(0)
        .map(() => symbols[Math.floor(Math.random() * symbols.length)])
        .join('');
}

export const isMobileWidth = () => window.innerWidth <= 700;

export function getItemsWithColor(data, propName = "id", propColor = "color") {
  const couriersWithColors = {};
  return (data || []).map(item=> {
    if(item[propName]) {
      couriersWithColors[propName] = item[propColor] || couriersWithColors[propName] || ColorService.instance.getColor();
      item[propColor] = couriersWithColors[propName];
    }

    return item;
  });
}

export function getItemsColors(data, propName = "id", propColor = "color") {
  return (data || []).reduce((a, item) => ({ ...a, [item[propName]]: item[propColor] }), {});
}

export const getRestaurantCardConfig = () => {
    const state = Store.store.getState();
    const config = state.restaurant.data.paymentsConfiguration;
    return {
        initPaymentIntent: GET_ORDER_SETUP_INTENT,
        stripeVerifyCard: STRIPE_VERIFY_CARD_ORDER,
        provider: config.provider,
        sellerId: config.paymeSellerId,
        productionMode: config.productionMode,
        stripeKey: config.publicStripeKey,
        tranzilaKey: config.tranzilaTokenTerminal,
    }
}