import SectionModel from "../../models/sectionModel";
import i18n from "../../i18n";
import {
    CONTROL_TYPE,
    MODEL_CONTROL_TYPE,
    TYPE_SECTION_MODEL
} from "../../utils/enums";
import {isMobileWidth} from "../restaurant";
import {
    COURIER_SETTINGS,
    COURIER_SETTINGS_GET,
} from "../../store/reducers/restaurant/restaurant-actions";
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import {createControlModel} from "../controlModelFactory";

export function createCourierAppSettingsModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: "settings.courierSettings.COURIER_CONTROL",
                isEditable: true,
                type: CONTROL_TYPE.sectionTab,
                items: [
                    {
                        isEditable: true,
                        isInline: true,
                        type: CONTROL_TYPE.switchGroups,
                        items: [[
                            {
                                title: "settings.courierSettings.DO_NOT_ALLOW_CYCLE_REJECT_BY_COURIER",
                                accessor: "doNotAllowCycleRejectByCourier",
                                isEditable: true,
                                type: CONTROL_TYPE.switch,
                                isInline: true,
                            },
                            {
                                title: "settings.courierSettings.DO_NOT_ALLOW_PULL_WITHOUT_LOCATION",
                                accessor: "doNotAllowPullWithoutLocation",
                                isEditable: true,
                                type: CONTROL_TYPE.switch,
                                isInline: true,
                            },
                            {
                                title: "settings.courierSettings.ENABLE_AWAY_CONTROL_BY_COURIER",
                                accessor: "enableAwayControlByCourier",
                                isEditable: true,
                                type: CONTROL_TYPE.switch,
                                isInline: true,
                            },
                            {
                                title: "settings.courierSettings.MANAGER_ONLY_SHIFT_CONTROL",
                                accessor: "managerOnlyShiftControl",
                                isEditable: true,
                                type: CONTROL_TYPE.switch,
                                isInline: true,
                            },
                        ]],
                    },
                    {
                        isEditable: true,
                        isInline: true,
                        type: CONTROL_TYPE.switchGroups,
                        items: [[
                            {
                                title: "settings.courierSettings.REJECT_CYCLE_ON_COURIER_APPROVE_TIMEOUT",
                                accessor: "rejectCycleOnCourierApproveTimeout",
                                isEditable: true,
                                type: CONTROL_TYPE.switch,
                                isInline: true,
                            },
                            {
                                title: "settings.courierSettings.RUN_COURIER_APP_IN_POINT_BY_POINT_MODE",
                                accessor: "runCourierAppInPointByPointMode",
                                isEditable: true,
                                type: CONTROL_TYPE.switch,
                                isInline: true,
                            },
                            {
                                title: "settings.courierSettings.LOCK_TASK_ORDER_IN_COURIER_APP",
                                accessor: "lockTasksOrderInCourierApp",
                                isEditable: true,
                                type: CONTROL_TYPE.switch,
                                isInline: true,
                            },
                        ]],
                    },
                    {
                        title: "settings.courierSettings.COURIER_WAITING_APPROVE_TIMOUT",
                        accessor: "courierWaitingApproveTimeout",
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                        measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
                    },
                ]
            },
            {
                title: "settings.courierSettings.DISPLAY_SETTINGS",
                isEditable: true,
                type: CONTROL_TYPE.sectionTab,
                items: [
                    {
                        isEditable: true,
                        isInline: true,
                        type: CONTROL_TYPE.switchGroups,
                        items: [[
                           
                            {
                                title: "settings.courierSettings.SHOW_BUSINESS_DELIVERY_PRICE_IN_COURIER_APP",
                                accessor: "showBusinessDeliveryPriceInCourierApp",
                                isEditable: true,
                                isInline: true,
                                type: CONTROL_TYPE.switch,
                            },
                            {
                                title: "settings.courierSettings.SHOW_COURIER_PAYMENT_IN_APP",
                                accessor: "showCourierPaymentInApp",
                                isEditable: true,
                                type: CONTROL_TYPE.switch,
                                isInline: true,
                            },
                            {
                                title: "settings.courierSettings.SHOW_COURIER_TIP_IN_APP",
                                accessor: "showCourierTipInApp",
                                isEditable: true,
                                inlineType: 'inlineOdd',
                                isInline: true,
                                type: CONTROL_TYPE.switch
                            },
                        ]],
                    },
                    {
                        isEditable: true,
                        isInline: true,
                        type: CONTROL_TYPE.switchGroups,
                        items: [[
                            {
                                title: "settings.courierSettings.SHOW_ORDER_CONTENT_AT_PICKUP",
                                accessor: "showOrderContentAtPickup",
                                isEditable: true,
                                isInline: true,
                                type: CONTROL_TYPE.switch,
                            },
                            {
                                title: "settings.courierSettings.SHOW_LATEST_ORDER_READINESS_TIME_AT_PICKUP",
                                accessor: "showLatestOrderReadinessTimeAtPickup",
                                isEditable: true,
                                isInline: true,
                                type: CONTROL_TYPE.switch,
                            }
                        ]],
                    },
                ]
            },
            {
                title: "settings.courierSettings.FLOW_CONTROL_SETTINGS",
                isEditable: true,
                type: CONTROL_TYPE.sectionTab,
                items: [
                    {
                        isEditable: true,
                        isInline: true,
                        type: CONTROL_TYPE.switchGroups,
                        items: [[
                            {
                                title: "settings.courierSettings.HIDE_FINISH_BUTTON_IN_COURIER_APP",
                                accessor: "hideFinishButtonInCourierApp",
                                isEditable: true,
                                isInline: true,
                                type: CONTROL_TYPE.switch
                            },
                            {
                                title: "settings.courierSettings.MULTI_SELECTION_IN_COURIER_APP",
                                accessor: "multiSelectionInCourierApp",
                                isEditable: true,
                                inlineType: 'inlineOdd',
                                isInline: true,
                                type: CONTROL_TYPE.switch
                            },
                            {
                                title: "settings.courierSettings.REMOVE_CLOSED_ORDERS_FROM_CYCLE",
                                accessor: "removeClosedOrdersFromCycle",
                                isEditable: true,
                                isInline: true,
                                type: CONTROL_TYPE.switch,
                            },
                        ]],
                    },
                    {
                        isEditable: true,
                        isInline: true,
                        type: CONTROL_TYPE.switchGroups,
                        items: [[
                            {
                                title: "settings.courierSettings.SHOW_POD_SCREEN_IN_COURIER_APP",
                                accessor: "showPODScreenInCourierApp",
                                isEditable: true,
                                inlineType: 'inlineOdd',
                                isInline: true,
                                type: CONTROL_TYPE.switch
                            },
                            {
                                title: "settings.courierSettings.USE_ADDRESS_FOR_NAVIGATION",
                                accessor: "useAddressForNavigation",
                                isEditable: true,
                                inlineType: 'inlineOdd',
                                isInline: true,
                                type: CONTROL_TYPE.switch
                            }, 
                            {
                                title: "settings.courierSettings.ENABLE_COLLECT_ORDERS_STEP_AT_PICKUP",
                                accessor: "enableCollectOrdersStepAtPickup",
                                isEditable: true,
                                isInline: true,
                                type: CONTROL_TYPE.switch,
                            },
                        ]],
                    },
                ],
            }
        ],
        actionName: COURIER_SETTINGS,
        actionLoad: COURIER_SETTINGS_GET,
        initNewItemModel: item => item,
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: TYPE_SECTION_MODEL.courierAppSettings,
        saveControlsConfig: {
            cancel: { title: "basic.RESET_BUTTON", action: ()=> {}, isInit: true }
        },
        label: "settings.courierSettings.LABEL",
        breadcrumbs: [
            createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            })
        ],
        mappingLambda: state => {
            return ({
                id: "courierappsettigns",
                ...state.restaurant.data.courierControlSettings,
                ...state.restaurant.data.courierDisplaySettings,
                ...state.restaurant.data.courierInterfaceSettings,
            });
        }
    });
}
