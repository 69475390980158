import React from 'react';
import SectionModel from '../../models/sectionModel.js';
import { getPeriodsByDate, getReconciliationsConfigurationsNameById, isMobileWidth } from '../restaurant.js';
import {
  CONTROL_TYPE,
  MODEL_CONTROL_TYPE,
  MODEL_TYPE,
  RECONCILIATIONS_MODEL,
  TYPE_ACTION,
} from '../../utils/enums.js';
import { createBreadcrumbModel } from '../createBreadcrumbModel.js';
import { createControlModel } from '../controlModelFactory.js';
import FilterOptionsModel from '../../models/filterOptionsModel.js';
import i18n from '../../i18n.js';
import { getHistoryLocationParams } from '../../utils/routesHelper.js';
import {
  getReconciliationsDepotsSelect,
  getTerritoriesSelect,
} from '../../app/components/SectionItemAudienceFilter/audienceFilterHelpers.js';
import { getInitModel } from '../initModels.js';
import {
  RECONCILIATIONS_CONFIGURATIONS,
  RECONCILIATIONS_CONFIGURATIONS_DEPOTS_GET,
  RECONCILIATIONS_CONFIGURATIONS_GET,
  RECONCILIATIONS_CONFIGURATIONS_PARENT_MERGED_GET,
  RECONCILIATIONS_PERIOD_GET,
  RECONCILIATIONS_PERIODS,
  RECONCILIATIONS_PERIODS_ADD,
  RECONCILIATIONS_PERIODS_APPROVE,
  RECONCILIATIONS_PERIODS_COLLAPSE_TABLE,
  RECONCILIATIONS_PERIODS_EXPORT,
  RECONCILIATIONS_PERIODS_GET,
  RECONCILIATIONS_PERIODS_INVOICE,
  RECONCILIATIONS_PERIODS_RECALCULATE,
  RECONCILIATIONS_PERIODS_RELOAD,
  RECONCILIATIONS_PERIODS_TABLE_INVOICE_EXPORT,
  RECONCILIATIONS_PERIODS_TABLE_XLS_EXPORT,
  RECONCILIATIONS_PERIODS_UNMOUNT_TABLE,
  RECONCILIATIONS_PERIODS_XLS,
} from '../../store/reducers/reconciliationModel/reconciliationModel-actions.js';
import { StagePeriods } from '../../app/components/Stage/StagePeriods.js';
import LinkDownload from '../../app/components/LinkDownload/LinkDownload.jsx';
import { PeriodDatesAndStage } from '../../app/components/PeriodDatesAndStage/PeriodDatesAndStage.js';
import { getDepotPeriodFilter } from '../filter.js';
import TextWithTooltip from '../../app/components/TextWithTooltip/index.js';
import { isRTL } from '../userModel.js';

export function createSettingsReconciliationsProviderSectionModel() {
  return new SectionModel({
    filterOptions: new FilterOptionsModel(),
    isForceUpdate: true,
    columns: [
      {
        accessor: 'templateName',
        title: 'reconciliations.TEMPLATE_NAME',
        width: '250px',
        fixedWidth: '250px',
        type: CONTROL_TYPE.text,
      },
      {
        accessor: 'territoryName',
        title: 'reconciliations.TERRITORY',
        width: 'auto',
        fixedWidth: 'auto',
        type: CONTROL_TYPE.text,
      },
      {
        accessor: 'parentId',
        title: 'reconciliations.PARENT',
        width: 'auto',
        component: (data,func1, func2, item) => {

          if(data && data.parentId && item && item.length > 0) {
            const currentParentElement = item.find((e) => e.id === data.parentId);
            return currentParentElement ? currentParentElement.templateName : "";

          }
          return null;
        },
        fixedWidth: 'auto',
        type: CONTROL_TYPE.text,
      },
      {
        accessor: 'depotName',
        title: 'reconciliations.DEPOT',
        width: '400px',
        whiteSpace: 'nowrap',
        component: data => {
          if(data.depotData && data.depotData.length > 0){
            const depotNames = data.depotData.map(depot => depot.depotName);
            return <TextWithTooltip text={depotNames.join(', ')} tooltip={depotNames.join(', ')} />;
          }

          return ''
        },
        fixedWidth: '400px',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'basic.EDIT_BUTTON',
        width: '40px',
        type: CONTROL_TYPE.link,
        link: RECONCILIATIONS_MODEL.settingsTemplateEdit,
        iconName: 'editItem',
        tooltipText: i18n.t('basic.EDIT_BUTTON'),
      },
    ],
    hideCellForSort: true,
    typeModel: RECONCILIATIONS_MODEL.settings,
    actionName: RECONCILIATIONS_CONFIGURATIONS,
    actionLoad: RECONCILIATIONS_CONFIGURATIONS_GET,
    tableMinWidth: 1200,
    label: 'reconciliations.SETTINGS',
    breadcrumbs: [createBreadcrumbModel({ label: 'header.menu.RECONCILIATIONS_LABEL' })],
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        url: RECONCILIATIONS_MODEL.settingsTemplateNew,
      }),
    ],
    mappingLambda: state => state.reconciliations?.data?.configurations || [],
  });
}

export function createSettingsReconciliationsTemplateEditSectionModel({ ...args }) {
  const { id } = getHistoryLocationParams(window.location.search);
  return new SectionModel({
    columns: [
      {
        title: 'reconciliations.SETTINGS_TEMPLATE_NAME_LABEL',
        accessor: 'templateName',
        placeholder: 'reconciliations.SETTINGS_TEMPLATE_NAME_PLACEHOLDER',
        isEditable: true,
        isRequired: true,
        type: CONTROL_TYPE.text,
      },
      {
        id: 'groupCampaignsEditDate',
        columns: [
          {
            title: 'reconciliations.SETTINGS_TEMPLATE_PARENT_LABEL',
            accessor: 'parentId',
            isEditable: true,
            connect: state => {
              const reconciliations = state.reconciliations?.data?.configurations || [];
              const reconciliationConfigurationsTypes = reconciliations.map(item => ({ key: item.id, value: item.templateName }));
              return {
                values: [{ key: null, value: '-' }].concat(reconciliationConfigurationsTypes)
              };
            },
            action: RECONCILIATIONS_CONFIGURATIONS_PARENT_MERGED_GET,
            type: CONTROL_TYPE.select,
          },
          {
            title: 'reconciliations.SETTINGS_TEMPLATE_TERRITORY_LABEL',
            accessor: 'territoryId',
            isRequired: true,
            isEditable: true,
            connect: () => ({
              values: getTerritoriesSelect(),
            }),
            type: CONTROL_TYPE.select,
            action: RECONCILIATIONS_CONFIGURATIONS_DEPOTS_GET,
          },
          {
            title: 'reconciliations.SETTINGS_TEMPLATE_DEPOT_LABEL',
            accessor: 'depotIds',
            width: '369px',
            refreshedId: 'territoryId',
            placeholderMulti: (selectedData, data) => {
              const filteredDepots = data.filter(depot => selectedData.includes(depot.value));

              return filteredDepots.map(depot => depot.key).join(', ');
            },
            getDefaultData: (state) => {
              if(state && state.length > 0 && id){
                const findCurrentSetting = state.find((e) => e.id === id);
                if(findCurrentSetting && findCurrentSetting.depotData){
                  const depotNames = findCurrentSetting.depotData.map(depot => ({
                    key: depot.depotName,
                        value: depot.depotId,
                  }));
                  return depotNames
                }
                return null;
              }
              return null;
            },
            connect: () => ({ values: getReconciliationsDepotsSelect() }),
            type: CONTROL_TYPE.reconciliationMultiSelect,
          },
        ],
        isEditable: true,
        isInline: true,
        isFillContent: true,
        alignItems: 'flex-end',
        gap: '16px',
        type: CONTROL_TYPE.group,
      },
      {
        id: 'groupCampaignsEditDate',
        columns: [
          {
            columns: [
              {
                title: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TYPE_LABEL',
                accessor: 'title',
                width: '240px',
                fixedWidth: '240px',
                type: CONTROL_TYPE.text,
              },
              {
                title: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_DEPOT_LABEL',
                accessor: 'Depot',
                width: '140px',
                fixedWidth: '140px',
                min: '-100',
                type: CONTROL_TYPE.inputPercent,
              },
              {
                title: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_TERRITORY_LABEL',
                accessor: 'Territory',
                width: '140px',
                fixedWidth: '140px',
                min: '-100',
                type: CONTROL_TYPE.inputPercent,
              },
              {
                title: 'reconciliations.SETTINGS_TEMPLATE_FEES_TABLE_GLOBAL_LABEL',
                accessor: 'Global',
                width: '140px',
                fixedWidth: '140px',
                min: '-100',
                type: CONTROL_TYPE.inputPercent,
              },
            ],
            accessor: 'fees',
            type: CONTROL_TYPE.reconciliationFeesTable,
          },
        ],
        isEditable: true,
        isInline: true,
        isFillContent: true,
        alignItems: 'flex-end',
        type: CONTROL_TYPE.group,
      },
    ],
    actionName: RECONCILIATIONS_CONFIGURATIONS,
    actionLoad: RECONCILIATIONS_CONFIGURATIONS_GET,
    initNewItemModel: item => getInitModel(MODEL_TYPE.reconciliationsSettingsTemplateModel, item),
    typeAction: args.typeAction,
    typeModel:
      args.typeAction === TYPE_ACTION.edit
        ? RECONCILIATIONS_MODEL.settingsTemplateEdit
        : RECONCILIATIONS_MODEL.settingsTemplateNew,
    parentUrl: RECONCILIATIONS_MODEL.settings,
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.RECONCILIATIONS_LABEL',
      }),
      createBreadcrumbModel({
        label: 'reconciliations.SETTINGS',
      }),
    ],
    getLabel: () => {
      const { id } = getHistoryLocationParams(window.location.search);
      return args.typeAction === TYPE_ACTION.edit
        ? getReconciliationsConfigurationsNameById(id)
        : i18n.t('reconciliations.SETTINGS_TEMPLATE_NEW_LABEL');
    },
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
      }),
    ],
    saveControlsConfig: {
      cancel: {
        title: 'basic.RESET_BUTTON',
        action: () => {},
        isInit: true,
      },
    },
    mappingLambda: state => state.reconciliations?.data?.configurations || [],
  });
}

export function createPeriodsReconciliationsSectionModel() {
  return new SectionModel({
    filterOptions: new FilterOptionsModel(),
    isForceUpdate: true,
    columns: [
      {
        accessor: 'startDate',
        title: 'reconciliations.PERIODS',
        width: '250px',
        component: data => getPeriodsByDate(data.startDate, data.endDate),
        type: CONTROL_TYPE.text,
      },
      {
        accessor: 'stage',
        title: 'reconciliations.STATUS',
        width: '150px',
        component: data => <StagePeriods stage={data.stage} />,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.INVOICE',
        width: '150px',
        type: CONTROL_TYPE.text,
        component: function(period) {
          return (
            <LinkDownload
              title={i18n.t('reconciliations.DOWNLOAD_INVOICE')}
              actionName={RECONCILIATIONS_PERIODS_INVOICE}
              data={period.id}
              icon={'invoice'}
            />
          );
        },
      },
      {
        title: 'reconciliations.XLS',
        width: 'auto',
        type: CONTROL_TYPE.text,
        component: function(period) {
          return (
            <LinkDownload
              title={i18n.t('reconciliations.DOWNLOAD_XLS')}
              actionName={RECONCILIATIONS_PERIODS_XLS}
              data={period.id}
              icon={'xls'}
            />
          );
        },
      },
      {
        title: 'reconciliations.ACTIONS',
        textRight: true,
        width: '68px',
        fixedWidth: '90px',
        type: CONTROL_TYPE.link,
        link: RECONCILIATIONS_MODEL.periodsEdit,
        iconName: 'editItem',
        isShow: item => !item.stage,
        tooltipText: i18n.t('basic.EDIT_BUTTON'),
      },
      {
        title: '',
        width: '68px',
        fixedWidth: '68px',
        hideInHeader: true,
        type: CONTROL_TYPE.link,
        link: RECONCILIATIONS_MODEL.periodsView,
        iconName: 'eyeIcon',
        isShow: item => !!item.stage,
        tooltipText: i18n.t('reconciliations.PERIOD'),
      },
    ],
    initNewItemModel: item => getInitModel(MODEL_TYPE.reconciliationsPeriodsTemplateModel, item),
    actionName: RECONCILIATIONS_PERIODS,
    typeModel: RECONCILIATIONS_MODEL.periods,
    actionLoad: RECONCILIATIONS_PERIODS_GET,
    rangePickerAction: RECONCILIATIONS_PERIODS_ADD,
    hideCellForSort: true,
    tableMinWidth: 1200,
    label: 'reconciliations.PERIODS',
    breadcrumbs: [createBreadcrumbModel({ label: 'header.menu.RECONCILIATIONS_LABEL' })],
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        text: 'reconciliations.ADD_PERIOD',
        openDateRange: true,
      }),
    ],
    mappingLambda: state => {
      const periodsData = state.reconciliations?.data?.summary?.periods;
      if (periodsData) {
        periodsData.forEach(e => {
          e.isShowDeleteButton = e.stage === 1;
        });
      }

      return periodsData || [];
    },
  });
}

export function createPeriodsReconciliationsPeriodEditSectionModel({ ...args }) {
  const { id } = getHistoryLocationParams(window.location.search);
  return new SectionModel({
    filterOptions: createReconciliationsSectionFilter(),
    columns: [
      {
        id: 'groupPeriodsReconciliations',
        columns: getReconciliationPeriodEditsExecutionsColumns(),
        isEditable: true,
        isInline: true,
        isFillContent: true,
        alignItems: 'flex-end',
        type: CONTROL_TYPE.group,
      },
    ],
    actionLoad: RECONCILIATIONS_PERIOD_GET,
    typeAction: args.typeAction,
    tableMinWidth: 5700,
    stayAfterSave: true,
    searchDataId: 'stats',
    actionOnUnmount: RECONCILIATIONS_PERIODS_UNMOUNT_TABLE,
    typeModel:
      args.typeAction === TYPE_ACTION.edit ? RECONCILIATIONS_MODEL.periodsEdit : RECONCILIATIONS_MODEL.periodsView,
    parentUrl: RECONCILIATIONS_MODEL.periods,
    actionName: RECONCILIATIONS_PERIODS,
    initNewItemModel: item => getInitModel(MODEL_TYPE.reconciliationsPeriodTemplateModel, item),
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.RECONCILIATIONS_LABEL',
      }),
      createBreadcrumbModel({
        label: 'reconciliations.PERIODS',
      }),
    ],
    getLabel: () => {
      const { id } = getHistoryLocationParams(window.location.search);
      return <PeriodDatesAndStage periodId={id} />;
    },
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.listItems,
        items: [{
            icon: 'button-minus',
            text: i18n.t('reconciliations.COLLAPSE_ALL_ITEMS'),
            isShow: (show, item) => {
              if (item) {
                return show;
              }
              return true;
            },
            action: RECONCILIATIONS_PERIODS_COLLAPSE_TABLE,
          },
          {
            icon: 'button-plus',
            text: i18n.t('reconciliations.EXPAND_ALL_ITEMS'),
            isShow: (show, item) => {
              if (item) {
                return show;
              }
              return false;
            },
            action: RECONCILIATIONS_PERIODS_COLLAPSE_TABLE,
          },],
        action: RECONCILIATIONS_PERIODS_EXPORT,
        customClassName: () => {
          if(isRTL()) {
            return 'periodItemsControlRtl'
          }
          return 'periodItemsControl'
        }
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.actionButton,
        icon: 'xls',
        action: RECONCILIATIONS_PERIODS_EXPORT,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.actionButton,
        width: '120px',
        text: i18n.t('reconciliations.PERIODS_RECALCULATE'),
        action: RECONCILIATIONS_PERIODS_RECALCULATE,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.dropdown,
        icon: 'more-dots',
        items: [
          {
            type: MODEL_CONTROL_TYPE.actionButton,
            width: '120px',
            text: i18n.t('reconciliations.PERIODS_RELOAD'),
            action: RECONCILIATIONS_PERIODS_RELOAD,
          }
        ],
      }),
    ],
    controlSettings: {
      position: 'subHeader'
    },
    saveControlsConfig: {
      cancel: {
        title: 'basic.RESET_BUTTON',
        action: () => {},
        isInit: true,
      },
      approve: {
        title: 'basic.APPROVE_BUTTON',
        action: RECONCILIATIONS_PERIODS_APPROVE,
        isShowClose: false,
        redirectIfSuccess: RECONCILIATIONS_MODEL.periodsView + `?id=${id}`,
        className: 'approve-button-reconciliation',
        isInit: true,
      },
    },
    mappingLambda: state => {
      const { id } = getHistoryLocationParams(window.location.search);
      const period = state?.reconciliations?.data?.summary?.periods.find(e => e.id === id);

      return period && period.stats ? { ...period, stats: period.stats } : { stats: [] };
    },
  });
}

export function viewPeriodsReconciliationsPeriodEditSectionModel({ ...args }) {
  return new SectionModel({
    filterOptions: createReconciliationsSectionFilter(),
    columns: [
      {
        id: 'groupPeriodsReconciliations',
        columns: getReconciliationPeriodViewExecutionsColumns(),
        isEditable: true,
        isInline: true,
        isFillContent: true,
        alignItems: 'flex-end',
        type: CONTROL_TYPE.group,
      },
    ],
    isHideControls: true,
    actionLoad: RECONCILIATIONS_PERIOD_GET,
    typeAction: args.typeAction,
    tableMinWidth: 5850,
    typeModel: RECONCILIATIONS_MODEL.periodsView,
    parentUrl: RECONCILIATIONS_MODEL.periods,
    searchDataId: 'stats',
    actionName: RECONCILIATIONS_PERIODS,
    initNewItemModel: item => item,
    breadcrumbs: [
      createBreadcrumbModel({
        label: 'header.menu.RECONCILIATIONS_LABEL',
      }),
      createBreadcrumbModel({
        label: 'reconciliations.PERIODS',
      }),
    ],
    getLabel: () => {
      const { id } = getHistoryLocationParams(window.location.search);
      return <PeriodDatesAndStage periodId={id} />;
    },
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.actionButton,
        icon: 'xls',
        action: RECONCILIATIONS_PERIODS_EXPORT,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.actionButton,
        icon: 'invoice',
        action: RECONCILIATIONS_PERIODS_INVOICE,
      }),
    ],
    mappingLambda: state => {
      const { id } = getHistoryLocationParams(window.location.search);
      const period = state?.reconciliations?.data?.summary?.periods.find(e => e.id === id);

      return period && period.stats ? { ...period, stats: period.stats } : { stats: [] };
    },
  });
}

export const getReconciliationPeriodEditsExecutionsColumns = (isEdit = true) => [
  {
    columns: [
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_COUNTERPARTIES',
        accessor: 'tableName',
        width: '250px',
        fixedWidth: '250px',
        widthHeader: '256px',
        sticky: true,
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_INVOICE',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        component: function(period) {
          return (
            <LinkDownload
              title={i18n.t('reconciliations.DOWNLOAD_INVOICE')}
              actionName={RECONCILIATIONS_PERIODS_TABLE_INVOICE_EXPORT}
              data={{ depotId: period.territoryId ? period.territoryId : period.id, isDepot: !!period.id }}
              icon={'invoice'}
            />
          );
        },
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_XLS',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        component: function(period) {
          return (
            <LinkDownload
              title={i18n.t('reconciliations.DOWNLOAD_XLS')}
              actionName={RECONCILIATIONS_PERIODS_TABLE_XLS_EXPORT}
              data={{ depotId: period.territoryId ? period.territoryId : period.id, isDepot: !!period.id }}
              icon={'xls'}
            />
          );
        },
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_TOTAL_REVENUES',
        accessor: 'depotName',
        width: '350px',
        fixedWidth: '350px',
        colSpan: '3',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_DEPOT',
        accessor: 'depotRevenue',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_TERRITORY',
        accessor: 'territoryRevenue',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_GLOBAL',
        accessor: 'globalRevenue',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_OFFSET',
        accessor: 'offset',
        width: '150px',
        isReadonly: false,
        fixedWidth: '150px',
        rowSpan: '2',
        type: isEdit ? CONTROL_TYPE.inputNumber : CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_DEPOT',
        accessor: 'depotReconciliation',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_TERRITORY',
        accessor: 'territoryReconciliation',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_GLOBAL',
        accessor: 'globalReconciliation',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_TOTAL_RECONCILIATION',
        accessor: 'depotName',
        width: '350px',
        fixedWidth: '350px',
        colSpan: '3',
        type: CONTROL_TYPE.text,
      },

      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DELIVERY_ORDERS',
        accessor: 'deliveryCount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_TAKEAWAY_ORDERS',
        accessor: 'takeawayCount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_ACCEPTED_ORDERS',
        accessor: 'totalAcceptedOrdersCount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_CANCELED_ORDERS',
        accessor: 'totalCanceledOrdersCount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_PRODUCTS_PRICE_SUM',
        accessor: 'totalProductPrices',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_PRODUCTS_PRICE_SUM_IN_CASH',
        accessor: 'totalCashProductPrices',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_PRODUCTS_PRICE_SUM_IN_CARD',
        accessor: 'totalCreditCardProductPrices',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_CARD_PAYMENTS',
        accessor: 'totalCreditCardPayments',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_CASH_PAYMENTS',
        accessor: 'totalCashPayments',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_BONUS_PAYMENTS',
        accessor: 'totalBonusPayments',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DISCOUNT_PAYMENTS',
        accessor: 'totalDiscountPayments',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DELIVERY_PRICE',
        accessor: 'totalDeliveryPrice',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_TOPUP_TO_MINIMUM',
        accessor: 'totalTopUpToMinimum',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_TAX',
        accessor: 'totalTax',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DRIVER_TIPS',
        accessor: 'totalDriverTip',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_SERVICE_FEE',
        accessor: 'totalServiceFee',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_REFUND_BY_LOYALTY_MARKETPLACE_RESP',
        accessor: 'totalMarketPlaceRefundLoyaltyAmount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_REFUND_BY_CARD_MARKETPLACE_RESP',
        accessor: 'totalMarketPlaceRefundCardAmount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DISCOUNT_MARKETPLACE_RESP',
        accessor: 'totalMarketplaceDiscount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_RESHIPMENT_MARKETPLACE_RESP',
        accessor: 'totalMarketplaceReshipment',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_REFUND_BY_LOYALTY_BUSINESS_RESP',
        accessor: 'totalBusinessRefundLoyaltyAmount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_REFUND_BY_CARD_BUSINESS_RESP',
        accessor: 'totalBusinessRefundCardAmount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DISCOUNT_BUSINESS_RESP',
        accessor: 'totalBusinessDiscount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_RESHIPMENT_BUSINESS_RESP',
        accessor: 'totalBusinessReshipment',
        width: '150px',
        fixedWidth: '150px',
        widthHeader: '171px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      // {
      //   title: 'reconciliations.PERIODS_TABLE_TOTAL_CANCELED_REFUNDS_MARKETPLACE',
      //   accessor: 'totalCanceledRefundsMarketplace',
      //   width: '150px',
      //   fixedWidth: '150px',
      //   widthHeader: '150px',
      //   rowSpan: '2',
      //   type: CONTROL_TYPE.text,
      // },
      // {
      //   title: 'reconciliations.PERIODS_TABLE_TOTAL_CANCELED_REFUNDS_BUSINESS',
      //   accessor: 'totalCanceledRefundsBusiness',
      //   width: '150px',
      //   fixedWidth: '150px',
      //   widthHeader: '195px',
      //   rowSpan: '2',
      //   type: CONTROL_TYPE.text,
      // },
    ],
    accessor: 'stats',
    isEditable: true,
    type: CONTROL_TYPE.reconciliationPeriodsTable,
  },
];
export const getReconciliationPeriodViewExecutionsColumns = () => [
  {
    columns: [
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_COUNTERPARTIES',
        accessor: 'tableName',
        width: '250px',
        fixedWidth: '250px',
        widthHeader: '256px',
        sticky: true,
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_INVOICE',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        component: function(period) {
          return (
            <LinkDownload
              title={i18n.t('reconciliations.DOWNLOAD_INVOICE')}
              actionName={RECONCILIATIONS_PERIODS_TABLE_INVOICE_EXPORT}
              data={{ depotId: period.territoryId ? period.territoryId : period.id, isDepot: !!period.id }}
              icon={'invoice'}
            />
          );
        },
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_XLS',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        component: function(period) {
          return (
            <LinkDownload
              title={i18n.t('reconciliations.DOWNLOAD_XLS')}
              actionName={RECONCILIATIONS_PERIODS_TABLE_XLS_EXPORT}
              data={{ depotId: period.territoryId ? period.territoryId : period.id, isDepot: !!period.id }}
              icon={'xls'}
            />
          );
        },
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_TOTAL_REVENUES',
        accessor: 'depotName',
        width: '450px',
        fixedWidth: '450px',
        colSpan: '3',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_DEPOT',
        accessor: 'depotRevenue',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_TERRITORY',
        accessor: 'territoryRevenue',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_GLOBAL',
        accessor: 'globalRevenue',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_DEPOT',
        accessor: 'depotReconciliation',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_TERRITORY',
        accessor: 'territoryReconciliation',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_GLOBAL',
        accessor: 'globalReconciliation',
        width: '150px',
        fixedWidth: '150px',
        isChild: true,
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_PERIOD_TABLE_TOTAL_RECONCILIATION',
        accessor: 'depotName',
        width: '450px',
        fixedWidth: '450px',
        colSpan: '3',
        type: CONTROL_TYPE.text,
      },

      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DELIVERY_ORDERS',
        accessor: 'deliveryCount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_TAKEAWAY_ORDERS',
        accessor: 'takeawayCount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_ACCEPTED_ORDERS',
        accessor: 'totalAcceptedOrdersCount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_CANCELED_ORDERS',
        accessor: 'totalCanceledOrdersCount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_PRODUCTS_PRICE_SUM',
        accessor: 'totalProductPrices',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_PRODUCTS_PRICE_SUM_IN_CASH',
        accessor: 'totalCashProductPrices',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_PRODUCTS_PRICE_SUM_IN_CARD',
        accessor: 'totalCreditCardProductPrices',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_CARD_PAYMENTS',
        accessor: 'totalCreditCardPayments',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_CASH_PAYMENTS',
        accessor: 'totalCashPayments',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_BONUS_PAYMENTS',
        accessor: 'totalBonusPayments',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DISCOUNT_PAYMENTS',
        accessor: 'totalDiscountPayments',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DELIVERY_PRICE',
        accessor: 'totalDeliveryPrice',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_TOPUP_TO_MINIMUM',
        accessor: 'totalTopUpToMinimum',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_TAX',
        accessor: 'totalTax',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DRIVER_TIPS',
        accessor: 'totalDriverTip',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_SERVICE_FEE',
        accessor: 'totalServiceFee',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_REFUND_BY_LOYALTY_MARKETPLACE_RESP',
        accessor: 'totalMarketPlaceRefundLoyaltyAmount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_REFUND_BY_CARD_MARKETPLACE_RESP',
        accessor: 'totalMarketPlaceRefundCardAmount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DISCOUNT_MARKETPLACE_RESP',
        accessor: 'totalMarketplaceDiscount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_RESHIPMENT_MARKETPLACE_RESP',
        accessor: 'totalMarketplaceReshipment',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_REFUND_BY_LOYALTY_BUSINESS_RESP',
        accessor: 'totalBusinessRefundLoyaltyAmount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_REFUND_BY_CARD_BUSINESS_RESP',
        accessor: 'totalBusinessRefundCardAmount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_DISCOUNT_BUSINESS_RESP',
        accessor: 'totalBusinessDiscount',
        width: '150px',
        fixedWidth: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_RESHIPMENT_BUSINESS_RESP',
        accessor: 'totalBusinessReshipment',
        width: '150px',
        fixedWidth: '150px',
        widthHeader: '171px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_CANCELED_REFUNDS_MARKETPLACE',
        accessor: 'totalCanceledRefundsMarketplace',
        width: '150px',
        fixedWidth: '150px',
        widthHeader: '150px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
      {
        title: 'reconciliations.PERIODS_TABLE_TOTAL_CANCELED_REFUNDS_BUSINESS',
        accessor: 'totalCanceledRefundsBusiness',
        width: '150px',
        fixedWidth: '150px',
        widthHeader: '195px',
        rowSpan: '2',
        type: CONTROL_TYPE.text,
      },
    ],
    accessor: 'stats',
    width: '5400px',
    type: CONTROL_TYPE.reconciliationPeriodsTable,
  },
];

function createReconciliationsSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions('search', CONTROL_TYPE.text, 'reconciliations.SEARCH_BY_DEPOT', function(period) {
    return getDepotPeriodFilter(this.selected, period);
  });

  return filterOptionsModel;
}
