import {orderSourceType, PAY_METHOD, SOURCE_TYPE} from "./enums.js";
import Store from "../store/store.js";

export const distributeAmount = (total, payments, maxBonus, minCardAmount = 5) => {
    if(maxBonus === 0) {
        payments = payments.filter(p => p.paymethod_id !== PAY_METHOD.bonus);
    }
    const bonusPayment = payments.find(p => !p.isTouched && p.paymethod_id === PAY_METHOD.bonus);
    const touchedPayments = payments.filter(p => p.isTouched);
    const untouchedPayments = payments.filter(p => !p.isTouched && p.paymethod_id !== PAY_METHOD.bonus);
    const totalInCent = total * 100;

    const touchedSum = touchedPayments.reduce((acc, cv) => acc += cv.saleAmount * 100, 0);
    let restSum = totalInCent - touchedSum;

    if(touchedSum < total * 100 && maxBonus && bonusPayment){
        const bonusInCent = maxBonus * 100;
        const sumForBonus = touchedSum + bonusInCent <= restSum
            ? bonusInCent
            : totalInCent - touchedSum >= bonusInCent
                ? bonusInCent
                : totalInCent - touchedSum;
        restSum -= sumForBonus;
        bonusPayment.saleAmount = sumForBonus;
        bonusPayment.changed = true;
    }

    if(touchedSum === totalInCent && maxBonus && bonusPayment) {
        bonusPayment.saleAmount = 0;
        restSum = totalInCent - touchedPayments.reduce((acc, cv) => acc += cv.saleAmount * 100, 0);
    }

    const basePayment = Math.floor(restSum / untouchedPayments.length);
    const remainingAmount = restSum % untouchedPayments.length;
    const minCardAmountInCent = minCardAmount * 100;

    for (let i = 0; i < untouchedPayments.length; i++) {
        const isCard = untouchedPayments[i].paymethod_id === PAY_METHOD.creditCard;
        untouchedPayments[i] = {
            ...untouchedPayments[i],
            saleAmount: isCard
                ? basePayment === 0 ? 0 : (basePayment < minCardAmountInCent ? minCardAmountInCent : basePayment)
                : (basePayment < 0 ? 0 : basePayment)
        };
    }

    if (remainingAmount && remainingAmount !== 0) {
        untouchedPayments[untouchedPayments.length -1].saleAmount += remainingAmount;
    }

    const totalUntouched = untouchedPayments.reduce((acc, cv) => acc += cv.saleAmount, 0);
    const totalTouched = touchedPayments.reduce((acc, cv) => acc += (cv.saleAmount || 0) * 100, 0);
    const lastTotal = totalTouched + totalUntouched + (bonusPayment && bonusPayment.changed ? bonusPayment.saleAmount : 0);
    if(totalInCent < lastTotal) {
        let valueToRemove = totalInCent - lastTotal;
        untouchedPayments.forEach(item => {
            if(valueToRemove) {
                const isCard = item.paymethod_id === PAY_METHOD.creditCard;
                const newAmount = item.saleAmount + valueToRemove;
                if(isCard) {
                    const value = newAmount >= minCardAmountInCent ? newAmount : minCardAmountInCent;
                    valueToRemove = valueToRemove + item.saleAmount - value;
                    item.saleAmount = value;
                } else {
                    const value = newAmount >= 0 ? newAmount : 0;
                    valueToRemove = valueToRemove + item.saleAmount - value;
                    item.saleAmount = value;
                }
            }
        });

        if(bonusPayment) {
            if(bonusPayment.changed) {
                const newAmount = bonusPayment.saleAmount + valueToRemove;
                const value = newAmount >= 0 ? newAmount : 0;
                valueToRemove = valueToRemove + bonusPayment.saleAmount - value;
                bonusPayment.saleAmount = newAmount;
            } else {
                const newAmount = bonusPayment.saleAmount * 100 + valueToRemove;
                const value = newAmount >= 0 ? newAmount : 0;
                valueToRemove = valueToRemove + bonusPayment.saleAmount * 100 - value;
                bonusPayment.saleAmount = newAmount;
            }
        } else {
            // change bonus value even if it was touched;
            const bonusChanged = payments.find(p => p.paymethod_id === PAY_METHOD.bonus);
            if(bonusChanged && ((bonusChanged.saleAmount * 100 + totalInCent - lastTotal) > 0)) {
                bonusChanged.saleAmount = (bonusChanged.saleAmount * 100 + totalInCent - lastTotal) / 100;
            }
        }
    }

    if(bonusPayment) {
        if(bonusPayment.changed) {
            bonusPayment.saleAmount = bonusPayment.saleAmount / 100;
            bonusPayment.changed = undefined;
            // delete bonusPayment.changed;
        }
        return [bonusPayment, ...touchedPayments, ...untouchedPayments.map(p => ({...p, saleAmount: p.saleAmount / 100}))];
    } else {
        return [...touchedPayments, ...untouchedPayments.map(p => ({...p, saleAmount: p.saleAmount / 100}))];
    }
}

export const filtratePayments = (payments, newPayment) => {
    const executeType = {
        [PAY_METHOD.creditCard]: [],
        [PAY_METHOD.cash]: [],
        [PAY_METHOD.bonus]: [PAY_METHOD.voucher],
        [PAY_METHOD.discount]: [],
        [PAY_METHOD.terminal]: [],
        [PAY_METHOD.voucher]: [PAY_METHOD.bonus, PAY_METHOD.voucher],
    }[newPayment.paymethod_id];
    return payments.filter(p => !executeType.includes(p.paymethod_id)).concat(newPayment);
}

export const getOrderSource = (source, sourceId) => {
    if(!sourceId) {
        return orderSourceType()[source];
    }
    if(source === SOURCE_TYPE.delivapp) {
        const providers = Store?.store?.getState()?.restaurant?.data?.ordersProvider || [];
        const provider = providers.find(p => p.id === sourceId);
        return provider ? provider.name : orderSourceType()[source];
    }

    return orderSourceType()[source];
}