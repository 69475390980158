import { getInitModel } from "../services/initModels";
import { MODEL_TYPE } from "../utils/enums";

export function businessInfoModel(data) {
    let model = data || {};
    return {
        ...model,
        address: {
            address: getInitModel(MODEL_TYPE.address, { city: model.address_city, street: model.address }),
            location: getInitModel(MODEL_TYPE.location, model.location)
        },
        modelType: MODEL_TYPE.businessInfoModel
    }
}

export function businessInfoServerModel(data) {
    let model = data || {};
    const address = (model.address && model.address.address) || {};
    const location = (model.address && model.address.location) || {};

    return {
        name: model.name,
        email: model.email,
        phone: model.phone,
        city: address.city,
        address: address.street,
        address_notes: model.address_notes,
        zipcode: model.zipcode,
        location: {
            lat: location.latitude,
            lng: location.longitude
        },
        kosher: model.kosher,
        minimum: model.minimum,
        always_deliver: model.always_deliver,
        about: model.about,
        description: model.description,
        enabled: model.enabled,
        disable_delivery: model.disable_delivery,
        disable_takeaway: model.disable_takeaway,
        allow_gift: model.allow_gift,
        message: model.message,
        message_enabled: model.message_enabled,
        header_settings: model.header_settings,
        header_mini_settings: model.header_mini_settings,
        disable_scheduled_orders: model.disable_scheduled_orders,
        minimum_takeaway: model.minimum_takeaway,
        disable_cutlery: model.disable_cutlery,
        cutlery_sku: model.cutlery_sku
    }
}
