import {
  ORDERS_LOAD_SUCCESS,
  ORDERS_LOAD_FAIL,
  ORDER_NEW,
  ORDER_UPDATE,
  ORDER_UPDATE_STATE,
  CYCLE_REORDER,
  PUSHER_GROUP_LOCK_UPDATE,
  GROUP_ORDER_UPDATE,
  REMOVE_GROUP_ORDERS,
  GROUP_UPDATE_STATE,
  CYCLE_DISPATCHE,
  UPDATE_ORDER_DATA,
  ORDER_REMOVE,
  CHANGE_ITEMS_GROUP,
  PUSHER_ASSISTANCE_UPDATE,
  GROUP_UPDATE,
  SAVE_HISTORY_ORDERS,
  ADD_NEW_ORDERS,
  PENDING_ORDERS_UPDATE,
  ORDER_MENU_UPDATE,
  ORDER_INDEX_UPDATE,
  UPDATE_ORDERS,
  ADMIN_ORDERS_UPDATE,
  ADMIN_ORDER_UPDATE,
  ORDER_UPDATE_SERVICE_NOTES,
  PENDING_PREORDERS_UPDATE,
  CONSUMER_ORDER_SEARCH,
  PENDING_ORDER_UPDATE,
  PENDING_PREORDER_DELETE,
  ORDERS_MODEL_UPDATE,
  PENDING_ORDER_UPDATE_COURIER,
  ADMIN_ORDER_SET_FILERS,
  ADMIN_ORDER_SELECTED_ORDER_ID,
  ADMIN_ORDERS_LOADING, ADMIN_ORDER_PENDING_COUNT_UPDATE
} from "./order-actions";
import {
  PUSHER_CYCLE_ROUTE_UPDATE,
  PUSHER_SET_AWAY,
  REMOVE_ALL_DATA
} from "../common-actions";

import {
  STATUSES,
  DEFAULT_ORDERS_GROUPS,
  CYCLE_STATE,
  ORDER_TYPE
} from "../../../utils/enums";
import orderModule, {
  updateOrderData,
  isDefaultGroup,
  isGroupHide, getDefaultAdminOrdersSearchFilter
} from "../../../services/order";
import { objectKeysToUpperLowerCase } from "../../../utils/objects-util";
import { mergeArrays } from "../../../utils/objects-util";
import User from "../../../services/user";
import { COURIER_UPDATE, CYCLE_STATE_UPDATE } from "../userModel/userModel-actions";
import { NOTIFICATIONS_UPDATE } from "../notification/notification-actions";

const initState = {
  data: {
    filter: getDefaultAdminOrdersSearchFilter(),
  },
  status: STATUSES.notInitialized
};

export default function orders(state = initState, action) {
  switch (action.type) {
    case REMOVE_ALL_DATA:
      return initState;
    case ORDERS_LOAD_SUCCESS:
       return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
          orders: action.data.orders.reduce((accumulator, currentValue) => {
            const index = accumulator.map(m => m.id).indexOf(currentValue.id);
            if (index > -1) {
              if (!isDefaultGroup(currentValue.groupId)) {
                accumulator.push(currentValue);
                accumulator.splice(index, 1);
              }
            } else {
              accumulator.push(currentValue);
            }

            return accumulator;
          }, []),
          adminOrders: action.data.adminOrders || state.data.adminOrders || [],
          historyOrders: action.data.historyOrders || state.data.historyOrders || [],
          activeOrders: action.data.activeOrders || state.data.activeOrders || [],
          pendingPreOrders: action.data.pendingPreOrders || state.data.pendingPreOrders || []
        },
        status: STATUSES.success
      };
    case NOTIFICATIONS_UPDATE:
      if(!Array.isArray(action.data) || !action.data.length) return state;
      return {
        ...state,
        data: {
          ...state.data,
          orders: state.data.orders.map(order => {
            const notification = action.data.find(f=> (f.args || []).includes(order.id));
            return notification
              ? { ...order, notificationType: notification.type }
              : order;
          })
        }
      };
    case ORDERS_LOAD_FAIL:
      return {
        ...state,
        status: STATUSES.failed
      };
    case SAVE_HISTORY_ORDERS:
      return {
        ...state,
        data: {
          ...state.data,
          historyOrders: action.data || state.data.historyOrders || []
        }
      };
    case ORDER_UPDATE_SERVICE_NOTES:
      return {
        ...state,
        data: {
          ...state.data,
          orders: state.data.orders.map(order=> order.id === action.data.id 
            ? { ...order, serviceNotes: action.data.value }
            : order
          ),
          historyOrders: state.data.historyOrders.map(order=> order.id === action.data.id 
            ? { ...order, serviceNotes: action.data.value }
            : order
          )
        }
      };
    case ORDER_UPDATE_STATE:
      return orderModule.updateOrderState(state, action.data);
    case ORDER_NEW:
      return orderModule.newOrder(state, action.data);
    case ADD_NEW_ORDERS:
      return {
        ...state,
        data: {
          ...state.data,
          orders: mergeArrays(state.data.orders, action.data.filter(f => f.type !== ORDER_TYPE.pickUp && !User.instance.skipObjectWithControlCenter(f)))
        }
      };
    case ORDER_UPDATE:
      return orderModule.updateOrder(state, action.data);
    case CYCLE_REORDER:
      return orderModule.cycleReorder(state, action.data);
    case CYCLE_DISPATCHE:
      return orderModule.cycleDispatched(state, action.data);
    case PUSHER_CYCLE_ROUTE_UPDATE:
      const routeEstimationData = objectKeysToUpperLowerCase(action.data);
      const stateGroups = state.data.groups || [];

      return {
        ...state,
        data: {
          ...state.data,
          groups: stateGroups.map(
            group =>
              group.id === routeEstimationData.courierId
                ? {
                    ...group,
                    routeEstimation: routeEstimationData.routeEstimation
                  }
                : group
          )
        }
      };
    case GROUP_ORDER_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          orders: state.data.orders.map(order => order.id === action.data.orderId
              ? { ...order, groupId: action.data.group.id, isCourierGroup: action.data.group.isCourierGroup }
              : order
          ),
          groups: mergeArrays(state.data.groups, [action.data.group])
        }
      };
    case GROUP_UPDATE:
      const groupId = action.data.id || action.data.courierId || action.data.userId;
      return {
        ...state,
        data: {
          ...state.data,
          groups: state.data.groups.find(f=> f.id === groupId)
            ? state.data.groups.map(group =>
                group.id === groupId
                  ? { ...group, ...action.data }
                  : group
              )
            : state.data.groups.concat([{ ...action.data, id: groupId }])
        }
      };
    case PUSHER_GROUP_LOCK_UPDATE:
      const groupLockUpdateData = objectKeysToUpperLowerCase(action.data);
      return {
        ...state,
        data: {
          ...state.data,
          groups: state.data.groups.map(
            group =>
              group.id === groupLockUpdateData.groupId
                ? { ...group, ...groupLockUpdateData }
                : group
          )
        }
      };
    case REMOVE_GROUP_ORDERS:
      return {
        ...state,
        data: {
          ...state.data,
          orders: state.data.orders.filter(
            f => f.groupId !== action.data.groupId
          ),
          pickUps: state.data.pickUps.filter(
            f => f.groupId !== action.data.groupId
          )
        }
      };
    case PUSHER_SET_AWAY:
      return {
        ...state,
        data: {
          ...state.data,
          orders: state.data.orders.map(
            order =>
              order.groupId === action.data.CourierId
                ? {
                    ...order,
                    groupId: DEFAULT_ORDERS_GROUPS.Unsorted
                  }
                : order
          )
        }
      };
    case GROUP_UPDATE_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          groups: state.data.groups.map(group => {
            let updGroup = action.data.find(f => f.id === group.id);
            return updGroup ? { ...group, hide: updGroup.hide } : group;
          })
        }
      };
    case COURIER_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          groups: state.data.groups.map(group => group.id === (action.data.id || action.data.userId || action.data.courierId)
            ? { ...group, ...action.data.cycle, hide: isGroupHide(action.data.cycle ? action.data.cycle.state : action.data.state) }
            : group
          )
        }
      };
    case UPDATE_ORDERS:      
      return {
        ...state,
        data: {
          ...state.data,
          orders: mergeArrays(state.data.orders, (action.data || []).filter(order=> order.type !== ORDER_TYPE.pickUp)),
          pickUps: mergeArrays(state.data.pickUps, (action.data || []).filter(order=> order.type === ORDER_TYPE.pickUp))
        }
      };
    case UPDATE_ORDER_DATA:
      if(User.instance.skipObjectWithControlCenter(action.data)) 
        return state;
      return {
        ...state,
        data: {
          ...state.data,
          orders: state.data.orders.map(order =>
              order.id === action.data.id ? { ...order, ...action.data } : order
          ),
          historyOrders: state.data.historyOrders.map(historyOrder =>
              historyOrder.id === action.data.id ? { ...historyOrder, ...action.data } : historyOrder
          )
        }
      };
    case ORDER_REMOVE:
      return {
        ...state,
        data: {
          ...state.data,
          orders: state.data.orders.filter(order => !(action.data || []).includes(order.id)),
          pickUps: state.data.pickUps.filter(order => !(action.data || []).includes(order.id)),
          activeOrders: state.data.activeOrders.filter(order => !(action.data || []).includes(order.id))
        }
      };
    case CHANGE_ITEMS_GROUP:
      return {
        ...state,
        data: {
          ...state.data,
          orders: state.data.orders.reduce((accumulator, order) => {
            accumulator.push(
              action.data.itemsIds.includes(order.id)
                ? {
                    ...order,
                    groupId: action.data.destinationGroup.id,
                    isCourierGroup: action.data.destinationGroup.isCourierGroup
                  }
                : order
            );
            return accumulator;
          }, []),
          pickUps: state.data.pickUps.reduce((accumulator, order) => {
            accumulator.push(
              action.data.itemsIds.includes(order.id)
                ? {
                    ...order,
                    groupId: action.data.destinationGroup.id,
                    isCourierGroup: action.data.destinationGroup.isCourierGroup
                  }
                : order
            );
            return accumulator;
          }, [])
        }
      };
    case PUSHER_ASSISTANCE_UPDATE:
      const data = objectKeysToUpperLowerCase(action.data);
      let updOrders = state.data.orders || [];

      if (data.deletedOrderId)
        if (updOrders.find(order => order && order.id === data.deletedOrderId && order.groupId === DEFAULT_ORDERS_GROUPS.assistance))
          updOrders = updOrders.filter(order => order && order.id !== data.deletedOrderId);
      if (data.order) {
        let newOrder = updateOrderData(
          data.order,
          false,
          DEFAULT_ORDERS_GROUPS.assistance,
          state
        );
        if (data.order.parsedOrder) {
          newOrder = updateOrderData(
            {
              ...newOrder,
              ...data.order.parsedOrder
            },
            false,
            DEFAULT_ORDERS_GROUPS.assistance,
            state
          );
        }
        updOrders = mergeArrays(updOrders, [newOrder]);
      }

      return {
        ...state,
        data: {
          ...state.data,
          orders: updOrders
        }
      };
    case CYCLE_STATE_UPDATE:
      if (
        User.instance.skipObjectWithControlCenter(action.data) ||
        !action.data.courierId
      )
        return state;
      const isCleanOrders =
        action.data.cycleState === CYCLE_STATE.started &&
        action.data.cleanOrders;
      return {
        ...state,
        data: {
          ...state.data,
          groups: state.data.groups.map(
            group =>
              group.id === action.data.courierId
                ? {
                    ...group,
                    queueNumber: action.data.queueNumber,
                    state: action.data.cycleState,
                    routeEstimation: isCleanOrders
                      ? { ...group.routeEstimation, segments: [] }
                      : group.routeEstimation,
                    optimalData: isCleanOrders
                      ? { ...group.optimalData, totalDistance: 0, totalTime: 0 }
                      : group.optimalData
                  }
                : group
          ),
          orders: isCleanOrders
            ? state.data.orders.filter(
                order => order.groupId !== action.data.courierId
              )
            : state.data.orders
        }
      };
    case PENDING_ORDERS_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
          activeOrders: mergeArrays(state.data.activeOrders || [], action.data.activeOrders || [])
        }
      };
    case PENDING_ORDER_UPDATE_COURIER:
      return {
        ...state,
        data: {
          ...state.data,
          activeOrders: (state.data.activeOrders || []).map(order =>
              order.id === action.data.orderId
                  ? {...order, courier_id: action.data.courierId}
                  : order)
        }
      }
    case PENDING_ORDER_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          activeOrders: mergeArrays(state.data.activeOrders || [], [action.data])
        }       
      };    
    case PENDING_PREORDER_DELETE:
      return {
        ...state,
        data: {
          ...state.data,
          pendingPreOrders: state.data.pendingPreOrders.filter(preord => preord.id !== action.data.id),
        }
      }
    case PENDING_PREORDERS_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          pendingPreOrders: action.data || []
        }
      };      
    case ADMIN_ORDERS_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          adminOrders: {
            ...state.data.adminOrders,
            ...action.data
          }
        }
      };
    case ADMIN_ORDERS_LOADING:
      return {
        ...state,
        data: {
          ...state.data,
          adminOrdersLoading: action.data
        }
      };
    case ADMIN_ORDER_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          adminOrders: {
            ...state.data.adminOrders,
            items: mergeArrays((state.data.adminOrders || {}).items || [], [action.data])
          }
        }
      };
    case ADMIN_ORDER_PENDING_COUNT_UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          adminOrders: {
            ...state.data.adminOrders,
            pendingCount: action.data,
          }
        }
      }
    }
    case ADMIN_ORDER_SET_FILERS:
      return {
        ...state,
        data: {
          ...state.data,
          filter: action.data,
        }
      }
    case ORDER_MENU_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          orders: state.data.orders.map(order=> order.integrationId === action.data.id
            ? { ...order, menu: action.data }
            : order
          )
        }
      };
    case ORDER_INDEX_UPDATE:
      let pickUps = action.data.orders.filter(f=> f.type === ORDER_TYPE.pickUp);

      return {
        ...state,
        data: {
          ...state.data,
          orders: state.data.orders.map(order=> {
            const updOrder = action.data.orders.find(f=> f.id === order.id);
            return updOrder ? { ...order, ...updOrder } : order;
          }),
          pickUps: state.data.pickUps.map(pickUp=> {
            const index = pickUps.findIndex(f => f.id === pickUp.id || f.depotId === pickUp.depotId);
            return index > -1
              ? { ...pickUp, ...pickUps.splice(index, 1)[0] }
              : pickUp;
          }).concat(pickUps)
        }
      };
    case CONSUMER_ORDER_SEARCH:
      return {
        ...state,
        data: {
          ...state.data,
          historyOrders: action.data
        }
      }
    case ORDERS_MODEL_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          orders: mergeArrays(state.data.orders || [], (action.data || []).filter(f=> f && f.type !== ORDER_TYPE.pickUp)),
          pickUps: mergeArrays(state.data.pickUps || [], (action.data || []).filter(f=> f && f.type === ORDER_TYPE.pickUp))
        }
      };
    case ADMIN_ORDER_SELECTED_ORDER_ID:
      return {
        ...state,
        data: {
          ...state.data,
          selectedOrderId: action.data
        }
      }
    default:
      return state;
  }
}
