import { objectKeysToUpperLowerCase } from '../utils/objects-util';

export function categoryProductServerModel(data) {
    const formatedExtra = extras => {
        if (extras) {
            return extras
                .filter(e => e.enabled)
                .map(e => ({
                    extra_id: e.id,
                    overrides: e.overrides && e.overrides.map(el => ({ ...el, value: String(el.value) })),
                }));
        }
    };
    return {
        Id: data.id,
        Name: data.name,
        Price: data.price,
        SKU: data.sku,
        Description: data.description,
        Order: data.order,
        Featured: data.featured,
        Upselling: data.upselling,
        Enabled: data.enabled,
        Vegan: data.vegan,
        Vegetarian: data.vegetarian,
        Gluten_free: data.gluten_free,
        Spicy: data.spicy,
        Allow_comments: data.allow_comments,
        Ingredients: data.categoryIngredients
            ? objectKeysToUpperLowerCase(
                  data.categoryIngredients.map(ingredient => {
                      return (
                          data.ingredients.find(f => f.name === ingredient) || {
                              name: ingredient,
                              image: null,
                              image_settings: null,
                          }
                      );
                  })
              )
            : objectKeysToUpperLowerCase(data.ingredients, true),
        Image_settings: data.image_settings,
        Enable_schedule: data.enable_schedule,
        Schedule: data.schedule,
        Max: data.max,
        Price_before_discount: data.price_before_discount,
        Extras: data.extras ? formatedExtra(data.extras) : formatedExtra(data.categoryExtras),
        Tag: data.tag,
    };
}
