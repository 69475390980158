import SectionModel from "../../models/sectionModel";
import {
    CONTROL_TYPE,
    MODEL_CONTROL_TYPE,
    TYPE_SECTION_MODEL
} from "../../utils/enums";
import {isMobileWidth} from "../restaurant";
import {
    BUSINESS_APP_CONFIGURATION, BUSINESS_APP_CONFIGURATION_GET,
} from "../../store/reducers/restaurant/restaurant-actions";
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import {createControlModel} from "../controlModelFactory";
import i18n from "../../i18n.js";

export function createBusinessAppSettingsModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: "settings.businessAppConfiguration.ACKNOWLEDGE_PREORDER",
                isEditable: true,
                accessor: 'acknowledgePreorder',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: "settings.businessAppConfiguration.ALWAYS_SHOW_CONSUMER_PHONE",
                isEditable: true,
                accessor: 'alwaysShowConsumerPhone',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            // {
            //     title: "settings.businessAppConfiguration.HIDE_COURIER_PHONE_NUMBER",
            //     isEditable: true,
            //     accessor: 'hideCourierPhoneNumber',
            //     isInline: true,
            //     type: CONTROL_TYPE.switch,
            // },
            {
                title: "settings.businessAppConfiguration.HIDE_CONSUMER_NAME",
                isEditable: true,
                accessor: 'hideConsumerName',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            // {
            //     title: "settings.businessAppConfiguration.SHOW_CASH_PAYMENT_INFO",
            //     isEditable: true,
            //     accessor: 'showCashPaymentInfo',
            //     isInline: true,
            //     type: CONTROL_TYPE.switch,
            // },
            {
                title: "settings.businessAppConfiguration.ALWAYS_SHOW_CONSUMER_ADDRESS",
                isEditable: true,
                accessor: 'alwaysShowConsumerAddress',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: "settings.businessAppConfiguration.INCLUDE_DELIVERY_PRICE",
                isEditable: true,
                accessor: 'includeDeliveryPrice',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            // {
            //     title: "settings.businessAppConfiguration.SHOW_CREDIT_CARD_PAYMENT_INFO",
            //     isEditable: true,
            //     accessor: 'showCreditCardPaymentInfo',
            //     isInline: true,
            //     type: CONTROL_TYPE.switch,
            // },
            {
                title: "settings.businessAppConfiguration.SHOW_DRIVER_TIP",
                isEditable: true,
                accessor: 'showDriverTip',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: "settings.businessAppConfiguration.SHOW_ETA_FOR_NEXT_PICKUP_ONLY",
                isEditable: true,
                accessor: 'showETAForNextPickupOnly',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: "settings.businessAppConfiguration.SHOW_GLOBAL_DISCOUNT_INFO",
                isEditable: true,
                accessor: 'showGlobalDiscountInfo',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: "settings.businessAppConfiguration.SHOW_TOP_UP_TO_MINIMUM",
                isEditable: true,
                accessor: 'showTopUpToMinimum',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: "settings.businessAppConfiguration.DO_NOT_ALLOW_ASSISTANCE_ORDER_CREATION",
                isEditable: true,
                accessor: 'doNotAllowAssistOrderCreation',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: "settings.businessAppConfiguration.SHOW_SERVICE_FEE",
                isEditable: true,
                accessor: 'showServiceFee',
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: "settings.businessAppConfiguration.SHOW_PREORDER_SPAN",
                isEditable: true,
                accessor: 'showPreorderSpan',
                isInline: true,
                type: CONTROL_TYPE.number,
                measure: i18n.t("settings.settings.numericalSettings.TIME_MEASURE"),
            },
        ],
        actionName: BUSINESS_APP_CONFIGURATION,
        actionLoad: BUSINESS_APP_CONFIGURATION_GET,
        initNewItemModel: item => item,
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: TYPE_SECTION_MODEL.businessAppSettings,
        label: "settings.businessAppConfiguration.LABEL",
        breadcrumbs: [
            createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            })
        ],
        mappingLambda: state => {
            return state.restaurant?.data?.businessAppSettings || {};
        }
    });
}
