import {callServer} from '../utils/request-util.js';
import environment from 'environment';

export function fetchReconciliationsConfigurations() {
  // const entityId = 'ontime.global.il';
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/configurations/list`,
      method: "GET",
    }).then(response => resolve(response), error => reject(error.response));
  });
}
export function postReconciliationsConfiguration(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "reconciliation/configurations/create",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateReconciliationsConfiguration({ data, configurationId }) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/configurations/${configurationId}`,
      method: "PUT",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}
export function getMergedConfiguration(parentId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/configurations/${parentId}/merged`,
      method: "GET",
    }).then(response => resolve(response), error => reject(error.response));
  });
}
export function fetchReconciliationsConfigurationDepots(territoryId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/configurations/${territoryId}/depots`,
      method: "GET",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateReconciliationsPeriod({ data, id }) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/${id}/offset`,
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteReconciliationsConfiguration(configurationId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/configurations/${configurationId}`,
      method: "DELETE",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchReconciliationsPeriods() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "reconciliation/summary",
      method: "GET",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchReconciliationsPeriodById(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "reconciliation/" + id,
      method: "GET",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function postReconciliationsPeriods(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "reconciliation",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteReconciliationsPeriods(configurationId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/${configurationId}`,
      method: "DELETE",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function xlsReconciliationsPeriod(periodId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/${periodId}/table`,
      method: "GET",
      responseType: "blob",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function invoiceReconciliationsPeriod(periodId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/${periodId}/invoice`,
      method: "GET",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function recalculateReconciliationsPeriod({periodRewriteSourceDataModel, periodId}) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/${periodId}/recalculate`,
      method: "POST",
      data: {
        RewriteSourceData: periodRewriteSourceDataModel
      },
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function approveReconciliationsPeriod(periodId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/${periodId}/approve`,
      method: "PUT",
    }).then(response => resolve(response), error => reject(error.response));
  });
}
export function exportReconciliationsPeriod(periodId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/${periodId}/table`,
      method: "GET",
      responseType: "blob",
    }).then(response => resolve(response), error => reject(error.response));
  });
}
export function exportReconciliationsPeriodTableXls(periodId, type, depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/${periodId}/table/${type}/${depotId}`,
      method: "GET",
      responseType: "blob",
    }).then(response => resolve(response), error => reject(error.response));
  });
}
export function exportReconciliationsPeriodTableInvoice(periodId, type, depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `reconciliation/${periodId}/invoice/${depotId}`,
      method: "GET",
      responseType: "blob",
    }).then(response => resolve(response), error => reject(error.response));
  });
}


