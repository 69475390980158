import SectionModel from "../../models/sectionModel";

import {ORDERING_MENU_BUSINESS_INFO, ORDERING_MENU_GET} from "../../store/reducers/restaurant/restaurant-actions";

import {
  CONTROL_TYPE,
  MODEL_CONTROL_TYPE,
  MODEL_TYPE,
  ORDERING_MENU_TYPE_SECTION_MODEL,
  TYPE_SECTION_DEPOT_MODEL,
  TYPE_SWITCH_CONTROL_COLOR,
} from '../../utils/enums';
import {getInitModel} from "../initModels";
import {getDepotNameById} from '../restaurant';
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import {getHistoryLocationParams} from "../../utils/routesHelper";
import {createControlModel, filtrateControlByDepotRole} from "../controlModelFactory";

export function createBusinesInfoEditSection({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "",
        accessor: "header",
        settingsAaccessor: "header_settings",
        isEditable: true,
        type: CONTROL_TYPE.imageEdit,
        tabs: [{
          title: "settings.orderingmenu.MENU_BUSINESS_INFO_MAIN_HEADER",
          accessor: "header",
          settingsAaccessor: "header_settings",
        }, {
          title: "settings.orderingmenu.MENU_BUSINESS_INFO_SECOND_HEADER",
          accessor: "header_mini",
          settingsAaccessor: "header_mini_settings",
        }]
      },
      {
        id: "group1",
        columns: [
          {
            title: "settings.orderingmenu.MENU_BUSINESS_INFO_ENABLED_LABEL",
            accessor: "enabled",
            isEditable: true,
            isInline: true,
            type: CONTROL_TYPE.switch
          },
          {
            title: "settings.orderingmenu.MENU_BUSINESS_INFO_DISABLE_DELIVERY_LABEL",
            accessor: "disable_delivery",
            typeColor: TYPE_SWITCH_CONTROL_COLOR.red,
            isInline: true,
            isEditable: true,
            type: CONTROL_TYPE.switch
          },
          {
            title: "settings.orderingmenu.MENU_BUSINESS_INFO_DISABLE_TAKEAWAY_LABEL",
            accessor: "disable_takeaway",
            typeColor: TYPE_SWITCH_CONTROL_COLOR.red,
            isInline: true,
            isEditable: true,
            type: CONTROL_TYPE.switch
          },
          {
            title: "settings.orderingmenu.MENU_BUSINESS_INFO_MESSAGE_LABEL",
            accessor: "message",
            isEditable: true,
            type: CONTROL_TYPE.textArea
          },
        ],
        isEditable: true,
        type: CONTROL_TYPE.group
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_DISABLE_SCHEDULER_ORDERS",
        accessor: "disable_scheduled_orders",
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_MESSAGE_ENABLED_LABEL",
        accessor: "message_enabled",
        isInline: true,
        isEditable: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_DISABLE_GIFT",
        accessor: "allow_gift",
        isInline: true,
        isEditable: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_DISABLE_ CUTLERY",
        accessor: "disable_cutlery",
        isInline: true,
        isEditable: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_CUTLERY_SKU",
        accessor: "cutlery_sku",
        isEditable: true,
        type: CONTROL_TYPE.text,
        isShow : module => !module.disable_cutlery
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_NAME_LABEL",
        accessor: "name",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_ABOUT_LABEL",
        accessor: "about",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_KOSHER_LABEL",
        accessor: "kosher",
        isInline: true,
        isEditable: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_ALWAYS_DELIVER_LABEL",
        accessor: "always_deliver",
        maxHeight: '50px',
        isEditable: true,
        isInline: true,
        type: CONTROL_TYPE.switch
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_DESCRIPTION_LABEL",
        accessor: "description",
        isEditable: true,
        type: CONTROL_TYPE.richText
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_PHONE_LABEL",
        accessor: "phone",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_MINIMUM_LABEL",
        accessor: "minimum",
        isEditable: true,
        inlineType: 'inlineOdd',
        type: CONTROL_TYPE.number
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_ZIPCODE_LABEL",
        accessor: "zipcode",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_MINIMUM_TAKEAWAY_LABEL",
        accessor: "minimum_takeaway",
        isEditable: true,
        inlineType: 'inlineOdd',
        type: CONTROL_TYPE.number
      },
      {
        title: "settings.orderingmenu.MENU_BUSINESS_INFO_ADDRESS_NOTES_LABEL",
        accessor: "address_notes",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "new-order-directive.ADDRESS_LABEL",
        accessor: "address",
        isEditable: true,
        isShowIcon: true,
        withEmpty: true,
        type: CONTROL_TYPE.address
      },
      {
        title: "",
        accessor: "address",
        isEditable: true,
        isFillContent: true,
        isHideSearch: true,
        isHidePolygon: true,
        type: CONTROL_TYPE.mapAddress
      }
    ],
    actionLoad: ORDERING_MENU_GET,
    actionName: ORDERING_MENU_BUSINESS_INFO,
    initNewItemModel: item => getInitModel(MODEL_TYPE.businessInfoModel, item),
    typeAction: args.typeAction,
    typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo,
    parentUrl: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
    stayAfterSave: true,
    saveControlsConfig: {
      cancel: { title: "basic.RESET_BUTTON", action: ()=> {}, isInit: true }
    },
    label: "settings.orderingmenu.MENU_BUSINESS_INFO",
    breadcrumbs: [
      createBreadcrumbModel({
        label: "settings.map.DEPOT_SETTINGS_LABEL"
      }),
      createBreadcrumbModel({
        getLabel: () => {
          const { id } = getHistoryLocationParams(window.location.search);
          return getDepotNameById(id);
        }
      })
    ],
    controls: filtrateControlByDepotRole([
      createControlModel({
        type: MODEL_CONTROL_TYPE.back
      }),
    ]),
    mappingLambda: state => state.restaurant.data.businessMenu || {}
  });
}
