import SectionModel from "../../models/sectionModel.js";
import {CONTROL_TYPE, MARKETPLACE_SETTINGS_MODEL, MODEL_CONTROL_TYPE, MODEL_TYPE} from "../../utils/enums.js";
import {
    LOYALTY_SETTINGS_CASHBACK, LOYALTY_SETTINGS_GET,
    LOYALTY_SETTINGS_REGISTER_VOUCHER,
} from "../../store/reducers/restaurant/restaurant-actions.js";
import {createControlModel} from "../controlModelFactory.js";
import {isMobileWidth} from "../restaurant.js";
import {createBreadcrumbModel} from "../createBreadcrumbModel.js";
import {getInitModel} from "../initModels.js";

export function createLoyaltySettingsCashbackSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.loyaltyCashback.CASHBACK',
                accessor: 'spendBonusPercent',
                isEditable: true,
                type: CONTROL_TYPE.number,
                max: () => 100,
                isItemValid: item => item.spendBonusPercent >= 0 && item.spendBonusPercent <= 100,
                useColorRed: item => !(item.spendBonusPercent >= 0 && item.spendBonusPercent <= 100),
                measure: '%'
            },
            {
                title: 'settings.loyaltyCashback.TARGET',
                accessor: 'accumulatedBonusTarget',
                isEditable: true,
                type: CONTROL_TYPE.number,
                isInline: true,
            },
        ],
        actionName: LOYALTY_SETTINGS_CASHBACK,
        actionLoad: LOYALTY_SETTINGS_GET,
        initNewItemModel: item => item,
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: MARKETPLACE_SETTINGS_MODEL.loyaltySettingsCashback,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        saveControlsConfig: {
            cancel: { title: "basic.RESET_BUTTON", action: ()=> {}, isInit: true }
        },
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.marketplaceMessage.LOYALTY",
            }),
        ],
        label: 'settings.loyaltyCashback.MENU_LABEL',
        mappingLambda: state => {
            const settings = (state.restaurant.data && state.restaurant.data.loyaltySettings) || {};
            return {...settings, spendBonusPercent: (settings.spendBonusPercent || 0) * 100}
        },
    });
}

export function createLoyaltySettingsRegisterBonusSectionModel({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: '',
                accessor: 'bonus_info',
                isEditable: true,
                isFillContent: true,
                isItemValid: item => {
                    if(!item.bonus_info) return true;

                    const percent = item?.bonus_info?.checkout_limit_percent;
                    const bonus = item?.bonus_info?.bonus;
                    const isNameExist = item?.bonus_info?.bonus_name;
                    const isValidPercent = percent >= 0.01 && percent <= 1.0;
                    const isValidValidityPeriod = item?.bonus_info?.bonus_expiration !== '00:00:00';
                    return isValidPercent && isValidValidityPeriod && isNameExist && bonus;
                },
                zeroValueIsOff: true,
                type: CONTROL_TYPE.bonusVoucher,
            },
        ],
        actionName: LOYALTY_SETTINGS_REGISTER_VOUCHER,
        actionLoad: LOYALTY_SETTINGS_GET,
        initNewItemModel: item => ({ bonus_info: getInitModel(MODEL_TYPE.loyaltyRegisterVoucher, item) }),
        typeAction: args.typeAction,
        stayAfterSave: true,
        typeModel: MARKETPLACE_SETTINGS_MODEL.loyaltySettingsCashback,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: isMobileWidth,
            }),
        ],
        saveControlsConfig: {
            cancel: { title: "basic.RESET_BUTTON", action: ()=> {}, isInit: true }
        },
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.marketplaceMessage.LOYALTY",
            }),
        ],
        label: 'settings.loyaltyRegisterBonus.LABEL',
        mappingLambda: state => {
            const settings =  state.restaurant.data && state.restaurant.data.loyaltySettings;
            return settings || {};
        },
    });
}
