import environment from 'environment';
import moment from 'moment';
import i18n from '../../i18n';

import SectionModel from '../../models/sectionModel';
import FilterOptionsModel from '../../models/filterOptionsModel';
import {
  CONTROL_TYPE,
  FILTER_TYPE,
  getOrderIntegrationTypeFilterItems,
  INTEGRATION_TYPE,
  MODEL_CONTROL_TYPE,
  ORDER_INTEGRATION_TYPE_FILTER,
  ORDER_STATE,
  ORDER_TYPE,
} from '../../utils/enums';
import { setStartTime } from '../../utils/convertTime';
import { getDeliveryDuration } from '../order';
import { getControlCenterFilter, groupBarOrderFilter, sortItemsDate } from '../filter';
import { createControlModel } from '../controlModelFactory';
import {getTerritoriesEntity} from "../restaurant.js";

export function createOrdersSection() {
  return new SectionModel({
    filterOptions: createOrdersSectionFilter(),
    tag: "activeOrder",
    sortByFunc: function(orders) {
      return sortItemsDate(getControlCenterFilter(orders, "controlCenterId"), "date", true);
    },
    controls: [
      createControlModel({ type: MODEL_CONTROL_TYPE.filter }),
    ],
    typeModel: "/orders",
    isForceUpdate: true,
  });
}

function createOrdersSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();
  const orderIntegrationTypeFilterItems = getOrderIntegrationTypeFilterItems();
  const territoriesItems = getTerritoriesEntity();

  //search
  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(order) {
    return groupBarOrderFilter(this.selected, order);
  });

  //date
  let filterDate = filterOptionsModel.addOptions(
    "date",
    CONTROL_TYPE.date,
    i18n.t("dashboard.orders.ORDER_DATE_FILTER_LABEL"),
    function(order) {
      const orderDate = moment.utc(order.date);
      const selectedDate = moment.utc(setStartTime(this.selected));
	  const selectedDateEnd = moment.utc(setStartTime(this.selected)).add(1, 'days');
      return orderDate >= selectedDate && orderDate <= selectedDateEnd;
    },
    null,
    null,
    true
  );
  filterDate.filterType = FILTER_TYPE.and;

  //integrationTypes
  let integrationTypes = filterOptionsModel.addOptions("integrationTypes", 
    CONTROL_TYPE.select, 
    i18n.t("dashboard.orders.ORDER_INTEGRATION_TYPE_FILTER_LABEL"), 
    function(order) {
      const type = orderIntegrationTypeFilterItems.find(f=> f.key === this.selected);
      if(!type) return true;
      switch (type.key) {
        case ORDER_INTEGRATION_TYPE_FILTER.application:
          return order.integrationType === INTEGRATION_TYPE.ordering;
        case ORDER_INTEGRATION_TYPE_FILTER.external:
          return order.integrationType !== INTEGRATION_TYPE.ordering;
        default:
          return true;
      }
    }
  );
  integrationTypes.filterType = FILTER_TYPE.and;
  orderIntegrationTypeFilterItems.forEach(item=> {
    integrationTypes.addItem(item.value, item.key);
  });
  integrationTypes.selected = orderIntegrationTypeFilterItems[2].key;


  //territories
  if(territoriesItems && territoriesItems.length) {
    const territoriesFilter = filterOptionsModel.addOptions(
        "territories",
        CONTROL_TYPE.select,
        i18n.t("dashboard.orders.ORDER_DELIVERY_TERRITORY_LABEL"),
        null,
        null,
        null,
        false,
        null,
        null,
        false
    );
    territoriesFilter.addItem("-", null);
    territoriesItems.forEach(t => {
      territoriesFilter.addItem(t.name, t.id);
    })
  }

  //deliveryGroup
  filterOptionsModel.addOptions(
    "deliveryGroup",
    CONTROL_TYPE.groupCheckbox,
    "dashboard.orders.ORDER_DELIVERY_TYPE_GROUP_LABEL",
    null,
    null,
    [
      {
        title: "dashboard.orders.ORDER_DELIVERY_TYPE_GROUP_LABEL",
        isCheckAllGroup: true,
        filterType: FILTER_TYPE.and,
        items: [
          {
            name: "takeaway",
            label: "orders.TAKEAWAY_FILTER_LABEL",
            selected: true,
            filterType: FILTER_TYPE.or,
            filterFunction: order=> order.type === ORDER_TYPE.takeAway
          },
          {
            name: "delivery",
            label: "orders.DELIVERY_FILTER_LABEL",
            selected: true,
            filterType: FILTER_TYPE.or,
            filterFunction: order=> order.type === ORDER_TYPE.delivery
          }
        ]
      },
      {
        title: "dashboard.orders.ORDER_ORDER_STATE_GROUP_LABEL",
        isCheckAllGroup: true,
        filterType: FILTER_TYPE.and,
        items: [
          {
            name: "pending",
            label: "orders.PENDING_FILTER_LABEL",
            selected: true,
            filterType: FILTER_TYPE.or,
            filterFunction: order=> order.state === ORDER_STATE.new
          },
          {
            name: "inkitchen",
            label: "orders.INKITCHEN_FILTER_LABEL",
            selected: true,
            filterType: FILTER_TYPE.or,
            filterFunction: order=> order.state === ORDER_STATE.kitchen
          },
          {
            name: "ready",
            label: "orders.READY_FILTER_LABEL",
            selected: true,
            filterType: FILTER_TYPE.or,
            filterFunction: order=> order.state === ORDER_STATE.ready
          },
          {
            name: "onroute",
            label: "orders.ONROUTE_FILTER_LABEL",
            selected: true,
            filterType: FILTER_TYPE.or,
            filterFunction: order=> order.state === ORDER_STATE.onroute
          },
          {
            name: "delivered",
            label: "orders.DELIVERED_FILTER_LABEL",
            selected: true,
            filterType: FILTER_TYPE.or,
            filterFunction: order=> order.state === ORDER_STATE.delivered
          },
          {
            name: "deleted",
            label: "orders.DELETED_FILTER_LABEL",
            selected: true,
            filterType: FILTER_TYPE.or,
            filterFunction: order=> order.state === ORDER_STATE.canceled
          }
        ]
      }
    ],
    false,
    null,
    null,
    true
  );

  //lateorders
  let selectOption = filterOptionsModel.addOptions(
    "lateorders",
    CONTROL_TYPE.select,
    i18n.t("dashboard.orders.ORDER_LATE_FOR_LABEL"),
    function(order) {
      if(!this.selected) return true;
      if (order.state !== ORDER_STATE.delivered)
        return false;
      let late = getDeliveryDuration(order);
      if (!late)
        return false;
      late = late / (1000 * 60);
      return late > this.selected;
    }
  );
  selectOption.filterType = FILTER_TYPE.and;
  selectOption.addItem("-", 0);
  selectOption.addItem(i18n.t("orders.LATE_FOR_HALF_LABEL"), 30);
  selectOption.addItem(i18n.t("orders.LATE_FOR_HOUR_LABEL"), 60);
  selectOption.addItem(i18n.t("orders.LATE_FOR_HOUR_AND_HALF_LABEL"), 90);
  selectOption.selected = 0;

   //historyTime
  let slider = filterOptionsModel.addOptions(
    "historyTime",
    CONTROL_TYPE.slider,
    i18n.t("dashboard.orders.ORDER_HISTORY_TIME_LABEL"), 
    null,
    false,
    environment.orders.defaultHistoryTime,
    true
  );
  slider.filterType = FILTER_TYPE.and;
  slider.setMinMax(
    environment.orders.minHistoryTime,
    environment.orders.maxHistoryTime
  );

  return filterOptionsModel;
}
