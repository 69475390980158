import Store from '../store/store';
import {
    ACCOUNT_ORDER_STATUS, CONTROL_TYPE,
    CYCLE_STATE,
    DEFAULT_ORDERS_GROUPS,
    DELIVERY_STRATEGY, DELIVERY_TYPE, EXTERNAL_COURIER_PROVIDER,
    EXTERNAL_CYCLE_STATE,
    FILTER_TYPE,
    ORDER_STATE,
    ORDER_TYPE, PAY_METHOD,
    PENDING_ORDER_TYPE,
    SHIFT_TYPE, SOURCE_TYPE,
    TYPE_MARKER, TYPE_STORAGE,
} from '../utils/enums';
import {
    getAddress,
    getMiniAddress,
    getNotes,
    getUIName,
    isDefaultGroup,
    isNotDefaultOrderGroup,
    showGroup,
} from './order';
import {getDepotDefaultRegionControlCenterName, getDepotName, isDepotMode} from './restaurant';
import {getCourierNameById, getFullName, getTotalCourierEstimation, getUserById} from './userModel';
import User from './user';
import {oneHour} from '../utils/convertTime';
import FilterOptionsModel from "../models/filterOptionsModel.js";
import {getStoreData, saveStoreData} from "./dataStorage.js";

///
///Group, order filters
///

export function activeGroupsFilter(group, orders) {
    return group.alwaysShow || (orders && orders.length > 0 && !group.hide);
}

export function courierFilter(group) {
    return group.isCourierGroup || isNotDefaultOrderGroup(group.id);
}

export function readyGroupFilter(group) {
    return !group.isCourierGroup && isOrdersReady(group) && !isDefaultGroup(group.id);
}

export function notReadyGroupFilter(group) {
    return (
        !group.isCourierGroup && !isOrdersReady(group) && !isDefaultGroup(group.id) && !isNotDefaultOrderGroup(group.id)
    );
}

export function defaultGroupFilter(group) {
    return isDefaultGroup(group.id);
}

export function externalCycleGroupsFilter(externalCycles) {
    return (externalCycles || []).filter(externalCycle => externalCycle.state === EXTERNAL_CYCLE_STATE.started);
}

export function externalCycleCouriersBarFilter(externalCycles, selectedControlCenters) {
    const excludedStates = [EXTERNAL_CYCLE_STATE.started, EXTERNAL_CYCLE_STATE.closed, EXTERNAL_CYCLE_STATE.canceled];
    return (externalCycles || []).filter(
        externalCycle =>
            !excludedStates.includes(externalCycle.state) && checkForControlCenter(externalCycle, selectedControlCenters)
    );
}

export function groupCourierQueueSort(g1, g2) {
    var userModel1 = getCourier(g1);
    if (!userModel1) return 0;
    var userModel2 = getCourier(g2);
    if (!userModel2) return 0;
    return courierQueueSort(userModel1, userModel2);
}

export function sortByOlderOrder(g1, g2) {
    const order1 = getOlderOrder(g1);
    const order2 = getOlderOrder(g2);

    if (!order1 || !order2 || order1.date === order2.date) return 0;
    const date1 = new Date(order1.date);
    const date2 = new Date(order2.date);
    return date1 > date2 ? 1 : -1;
}

export function sortByDate(g1, g2, fieldName) {
    const date1 = new Date(g1[fieldName || 'date']);
    const date2 = new Date(g2[fieldName || 'date']);
    return date1.getTime() - date2.getTime();
}

export function pendingOrdersFilter(orders) {
    return (orders || [])
        ?.sort((a, b) => a.sequence_id - b.sequence_id);
}

export function pendingOrdersCouriersOlderSort(items, users, externalCycles) {
  const propFormSort = "creation_datetime";
  const data = (items || []).reduce((acc, order)=> {
    const courierId = order.courier_id || order.external_provider_id;
    if(courierId) {
      const courier = (order.external_provider_id
        ? (externalCycles || []).find(f=> f.providerId === order.external_provider_id)
        : (users || []).find(f=> f.userId === order.courier_id)) || {};
      const courierOrders = (order.external_provider_id
        ? courier.orders
        : (courier.cycle || {}).orders) || [];

      return {
        ...acc,
        couriersData: {
          ...(acc.couriersData || {}),
          [courierId]: ((acc.couriersData || {})[courierId] || [])
            .concat({
              ...order,
              index: courierOrders.findIndex(f=> f.id === order.id)
            })
        }
      }
    } else {
      return {
        ...acc,
        orders: (acc.orders || []).concat(order)
      }
    }
  }, {});

  return Object.keys(data.couriersData || {})
    ?.sort((a, b)=> {
      const minOrderA = new Date(Math.min(...(data.couriersData[a]?.map(m=> new Date(m[propFormSort]).getTime()))));
      const minOrderB = new Date(Math.min(...(data.couriersData[b]?.map(m=> new Date(m[propFormSort]).getTime()))));
      return minOrderA < minOrderB ? -1 : 1;
    })
    .reduce((a, k)=> { return a.concat((data.couriersData[k] || [])?.sort((a, b) => a.index - b.index))}, [])
    .concat((data.orders || [])?.sort((a, b) => {
      return new Date(a[propFormSort]).getTime() < new Date(b[propFormSort]).getTime() ? -1 : 1;
    }));
}

export function pendingOrdersCookingSort(items) {
  return (items || [])
    ?.sort((a, b) => {
      if(!b.status || a.status > b.status){
          return -1;
      }
      if(!a.status || a.status < b.status){
          return 1;
      }

      return 0;
    });
}

export function pendingOrdersSort(items) {
  return (items || [])
    ?.sort((a, b) => a.sequence_id - b.sequence_id);
}

export function pendingHistoryOrdersFilter(orders) {
  return (orders || [])
    ?.sort((a, b)=> sortByDate(a, b, "creation_datetime"));
}

export function groupBarOrderFilter(text, order) {
    if (!text || !text.length) return true;
    const loverText = text?.toLowerCase();
    const getUINameVal = getUIName(order);
    const getMiniAddressVal = getMiniAddress(order);
    const getNotesVal = getNotes(order);
    let userName = getCourierNameById(order.groupId);
    const courierName = order.deliveryBy && getCourierNameById(order.deliveryBy);

    return (
        (order.orderId && order.orderId?.toLowerCase()?.indexOf(loverText) > -1) ||
        (getUINameVal && getUINameVal?.toLowerCase()?.indexOf(loverText) > -1) ||
        (order.consumerPhone &&
            order.consumerPhone
                ?.toLowerCase()
                ?.replace(/[- ]/gi, '')
                ?.indexOf(loverText) > -1) ||
        (getMiniAddressVal && getMiniAddressVal?.toLowerCase()?.indexOf(loverText) > -1) ||
        (getNotesVal && getNotesVal?.toLocaleLowerCase()?.indexOf(loverText) > -1) ||
        (isDepotMode() &&
            getDepotName(order)
                ?.toLocaleLowerCase()
                ?.indexOf(loverText) > -1) ||
        (userName && userName?.toLowerCase().indexOf(loverText) > -1) ||
        (courierName && courierName?.toLowerCase().indexOf(loverText) > -1) ||
        (order.deliveryBy && order.deliveryBy?.toLowerCase().indexOf(loverText) > -1) ||
        (Array.isArray(order.tags) && order.tags.length && order.tags.includes(loverText))
    );
}

export function groupBarGroupOrderFilter(text, group, orders) {
    return (orders.filter(order => order.groupId === group.id) || []).some(order => groupBarOrderFilter(text, order));
}

export function courierAssociateFilter(text, courier) {
    if (!text || !text.length) return true;
    const loverText = text.toLowerCase();
    const userName = getFullName(courier);

    return userName.toLowerCase().includes(loverText);
}

export function courierSettingsFilter(text, courier) {
    if (!text || !text.length) return true;
    const loverText = text.toLowerCase();

    return (
        getFullName(courier)
            .toLowerCase()
            .includes(loverText) ||
        (courier.phoneNumber &&
            courier.phoneNumber
                .replace(/-/g, '')
                .toLowerCase()
                .includes(loverText))
    );
}

export function translationsFilter(text, translationValue) {
    if (!text || !text.length) return true;
    const loverText = text.toLowerCase();
    const value = translationValue?.original_value || ''

    return value.toLowerCase().includes(loverText)
}

export function depotBusinessTranslationsFilter(text, translateItem) {
    // if (!text || !text.length) return true;
    // const loverText = text.toLowerCase();
    //
    // return (
    //     getFullName(courier)
    //         .toLowerCase()
    //         .includes(loverText) ||
    //     (courier.phoneNumber &&
    //         courier.phoneNumber
    //             .replace(/-/g, '')
    //             .toLowerCase()
    //             .includes(loverText))
    // );
}

export function couriersBarFilter(user, orders, text, selectedControlCenters, noCheck) {
    return (
        cycleFilter(
            user,
            orders.filter(order => order && order.groupId === user.userId),
            selectedControlCenters,
            noCheck
        ) && courierSettingsFilter(text, user)
    );
}

export function sortItemsByReadinessAndTargetDate(items) {
    const sortTime = User.instance.getCurrentUserConfigurationProp('sortOrdersBasedOnReadinessTime')
        ? 'readinessTime'
        : 'targetTime';

    return items?.sort((a, b) => {
        if (a.isHighPriority && !b.isHighPriority) {
            return -1;
        }

        if (b.isHighPriority && !a.isHighPriority) {
            return 1;
        }

        if (a.groupId === DEFAULT_ORDERS_GROUPS.assistance && b.groupId !== DEFAULT_ORDERS_GROUPS.assistance) {
            return -1;
        }

        if (b.groupId === DEFAULT_ORDERS_GROUPS.assistance && a.groupId !== DEFAULT_ORDERS_GROUPS.assistance) {
            return 1;
        }

        var aDeliveryStrategy = a.deliveryStrategy;
        var bDeliveryStrategy = b.deliveryStrategy;

        if (
            a.deliveryStrategy === DELIVERY_STRATEGY.concreteTime &&
            a.targetTime &&
            new Date().getTime() - new Date(a.targetTime).getTime() < oneHour
        ) {
            aDeliveryStrategy = DELIVERY_STRATEGY.asap;
        }

        if (
            b.deliveryStrategy === DELIVERY_STRATEGY.concreteTime &&
            b.targetTime &&
            new Date().getTime() - new Date(b.targetTime).getTime() < oneHour
        ) {
            bDeliveryStrategy = DELIVERY_STRATEGY.asap;
        }

        if (aDeliveryStrategy !== bDeliveryStrategy) {
            return aDeliveryStrategy < bDeliveryStrategy ? -1 : 1;
        }

        if (a.type !== b.type) {
            return a.type < b.type ? -1 : 1;
        }

        if (a.state !== b.state) {
            return a.state < b.state ? 1 : -1;
        }

        return a[sortTime] < b[sortTime] ? -1 : 1;
    });
}

export function sortItemsDate(items, propertyName, isAsc) {
    return items?.sort((a, b) => {
        const aDate = new Date(a[propertyName]);
        const aVrp = a['isHighPriority'];
        const bDate = new Date(b[propertyName]);
        const bVrp = b['isHighPriority'];
        if (!aDate || !bDate) return 0;
        if (aVrp && !bVrp) {
            return -1;
        }
        if (bVrp && !aVrp) {
            return 1;
        }
        if (aDate < bDate) return isAsc ? 1 : -1;
        if (aDate > bDate) return isAsc ? -1 : 1;
        return 0;
    });
}

export function sortUsers(users) {
    return users?.sort((a, b) => {
        const fullNameA = getFullName(a).toLowerCase();
        const fullNameB = getFullName(b).toLowerCase();

        if (fullNameA < fullNameB) {
            return -1;
        }
        if (fullNameA > fullNameB) {
            return 1;
        }

        return 0;
    });
}

export function sortByProp(items, propName = 'order') {
    return (items || [])?.sort((a, b) => a[propName] - b[propName]);
}

export function sortItemsByProp(items, propName = 'name', isDesc = false) {
    if (!Array.isArray(items)) return items;
    return items?.sort((a, b) => {
        if ((a[propName] || '').toUpperCase() < (b[propName] || '').toUpperCase()) {
            return isDesc ? 1 : -1;
        }

        if ((a[propName] || '').toUpperCase() > (b[propName] || '').toUpperCase()) {
            return isDesc ? -1 : 1;
        }

        return 0;
    });
}

export function getLabelByLanguage(language, variants) {
    if(!variants) {
        return '';
    }

    if(variants[language]) {
        return variants[language];
    }

    const availableLanguage = Object.keys(variants);
    for (let i = 0; i < availableLanguage.length; i++) {
        const lang = availableLanguage[i];
        if(variants[lang]) {
            return variants[lang];
        }
    }

    return '';
}

export function sortByPropNumber(items, propName = 'order', isDesc = true) {
    if (!Array.isArray(items)) return items;
    return items?.sort((a, b) => {
        if (a[propName] < b[propName]) {
            return isDesc ? 1 : -1;
        }

        if (a[propName] > b[propName]) {
            return isDesc ? -1 : 1;
        }

        return 0;
    });
}

export function isPickupGroup(group) {
    return group.type === ORDER_TYPE.pickUp;
}

export function courierQueueSort(userModel1, userModel2) {
    return (userModel1.queueNumber || 0) - (userModel2.queueNumber || 0);
}

export function isOrdersReady(group) {
    const state = Store.store.getState();
    const groupOrders = state.order.data.orders.filter(order => order.groupId === group.id);
    return groupOrders.length > 0 && groupOrders.every(order => order.state === ORDER_STATE.ready);
}

export function getFilteredItems(items, filterOptions) {
    if (!filterOptions || !filterOptions.getSelectedFilters || !items) return [];

    const andFilters = filterOptions.getSelectedFilters(FILTER_TYPE.and);
    const orFilters = filterOptions.getSelectedFilters(FILTER_TYPE.or);

    if (!andFilters.length && !orFilters.length) return items;

    return items.filter(item => {
        let res = true;

        if (andFilters.length) {
            res = andFilters.every(filter => {
                const filteredItems = filter?.items?.filter(f => f.selected || f.selected === 0);
                return filter.isCheckAllGroup
                    ? !filteredItems.length && filter.passEmptyFilters
                        ? true
                        : filteredItems.some(filterItem => filterItem.filterFunction(item, filterOptions.options))
                    : filter.filterFunction(item, filterOptions.options);
            });
        }

        if (!res) return false;

        return orFilters.length ? orFilters.some(filter => filter.filterFunction(item, filterOptions.options)) : true;
    });
}

function getCourier(group) {
    if (group && group.isCourierGroup) {
        const state = Store.store.getState();
        return state.userModel.data.find(user => user.userId === group.id);
    } else {
        return null;
    }
}

function getOlderOrder(group) {
    if (!group) return;
    const state = Store.store.getState();
    const orders = state.order.data.orders.filter(order => order.groupId === group.id);

    if (!orders.length) return;

    let olderOrder = orders[0];
    for (var i = 1; i < orders.length; i++) {
        const order = orders[i];
        if (new Date(olderOrder.date) > new Date(order.date)) olderOrder = order;
    }
    return olderOrder;
}

///
///Courier filters
///
export function getBackupCouriers(users) {
    return users.filter(user => backUpCouriersFilter(user));
}

export function onRouteFilter(courier) {
    return courier.cycle && courier.cycle.state === CYCLE_STATE.deliveryStart;
}

export function onRouteCourierGroupSort(items) {
    return items?.sort((a, b) => {
        const countOrdersA = (a.orders || []).filter(order => order.state < ORDER_STATE.delivered).length;
        const countOrdersB = (b.orders || []).filter(order => order.state < ORDER_STATE.delivered).length;

        return countOrdersA - countOrdersB || getTotalCourierEstimation(b) - getTotalCourierEstimation(a);
    });
}

export function approvedFilter(courier) {
    return courier.cycle && courier.cycle.state === CYCLE_STATE.approved;
}

export function comingBackFilter(courier) {
    return courier.cycle && courier.cycle.state === CYCLE_STATE.comingBack;
}

export function waitingFilter(courier) {
    return courier.cycle && courier.cycle.state === CYCLE_STATE.started;
}

export function awayFilter(courier) {
    return courier.cycle && courier.cycle.state === CYCLE_STATE.away;
}

export function backUpCouriersFilter(user) {
    return user.roles && user.roles.active && user.cycle && user.cycle.shiftType === SHIFT_TYPE.Backup;
}

export function cycleFilter(user, orders, selectedControlCenters, noCheck) {
    return (
        user.roles.courier &&
        user.roles.active &&
        (noCheck ||
            (checkForControlCenter(user.cycle, selectedControlCenters) &&
                ((user.cycle && user.cycle.shiftType === SHIFT_TYPE.Normal) || orders.length)))
    );
}

export function getInactiveCouriers(users, shiftActiveUsers) {
    return users.filter(
        user =>
            Array.isArray(shiftActiveUsers) &&
            shiftActiveUsers.length &&
            shiftActiveUsers.includes(user.userId) &&
            user.roles &&
            user.roles.courier &&
            !user.roles.active &&
            user.authorized.courier &&
            !user.isDeleted
    );
}

export function getShiftedCouriers(users) {
    return users.filter(
        user =>
            user.roles &&
            user.cycle &&
            user.roles.active &&
            user.cycle.shiftType === SHIFT_TYPE.Normal &&
            !user.isDeleted
    );
}

export function getUnverifiedCouriers(users) {
    return users.filter(user => user.roles && user.roles.courier && !user.authorized.courier && !user.isDeleted);
}

///
///Depots filters
///

export function getDepotFilter(text, depot) {
    const loverText = text.toLowerCase();
    return !loverText
        ? depot
        : depot.phone
              .toLowerCase()
              .replace(/[- ]/gi, '')
              .indexOf(loverText) > -1 || depot.name.toLowerCase().indexOf(loverText) > -1;
}

export function getDepotPeriodFilter(text, depot) {
    const loverText = text.toLowerCase();
    const nameDepot = depot?.depotName;

    return !loverText ? depot : nameDepot ? depot?.depotName.toLowerCase().indexOf(loverText) > -1 : depot;
}

export function getDepotDefaultRegionControlCenterNameFilter(text, depotControlCenter) {
    const loverText = text.toLowerCase();
    return !loverText
        ? depotControlCenter
        : getDepotDefaultRegionControlCenterName(depotControlCenter)
              .toLowerCase()
              .indexOf(loverText) > -1;
}

export function getRegionFilter(text, region) {
    const loverText = text.toLowerCase();
    return !loverText ? region : region.name.toLowerCase().indexOf(loverText) > -1;
}

export function getPromotionFilter(text, promo) {
    const loverText = text.toLowerCase();
    const promoName = promo?.name || '';
    const promoLabel = promo?.label || '';
    const containPromoName = !loverText
        ? promo
        : promoName
        .toLowerCase()
        .replace(/[- ]/gi, '')
        .indexOf(loverText) > -1 || promoName.toLowerCase().indexOf(loverText) > -1;
    const containPromoLabel = !loverText
        ? promo
        : promoLabel
        .toLowerCase()
        .replace(/[- ]/gi, '')
        .indexOf(loverText) > -1 || promoLabel.toLowerCase().indexOf(loverText) > -1;

    return containPromoName || containPromoLabel;
}

export function getDeliveryZonesFilter(text, deliveryZone) {
    const lowerText = text.toLowerCase();
    return !lowerText ? deliveryZone : deliveryZone.name.toLowerCase().indexOf(lowerText) > -1;
}

export function getFleetFilter(vehicles, text) {
    const lowerText = text.toLowerCase();

    return !lowerText ? vehicles : (vehicles.description.toLowerCase().indexOf(lowerText) > -1) ||
        (vehicles.capacity && vehicles.capacity > 0 && vehicles.capacity.toLocaleString().toLowerCase().indexOf(`${lowerText}`) > -1) ||
        (vehicles.plateNumber && vehicles.plateNumber.toLowerCase().indexOf(lowerText) > -1);
}

export function getBusinesDiscountFilter(text, discount) {
    const lowerText = text.toLowerCase();
    return !lowerText ? discount : discount.name.toLowerCase().indexOf(lowerText) > -1;
}

export function getBusinesMenuCategoriesFilter(text, category) {
    const loverText = text.toLowerCase();
    const categoryName = category.name
    return !loverText
        ? category
        : categoryName
        .toLowerCase()
        .replace(/[- ]/gi, '')
        .indexOf(loverText) > -1 || categoryName.toLowerCase().indexOf(loverText) > -1;
}

export function getProvidersSectionFilter(text, provider) {
    const loverText = text.toLowerCase();
    const providerName = provider.providerName || ''
    return !loverText
      ? provider
      : providerName
      .toLowerCase()
      .replace(/[- ]/gi, '')
      .indexOf(loverText) > -1 || providerName.toLowerCase().indexOf(loverText) > -1;
}

export function getDepotUsersSectionFilter(text, user) {
    const loverText = text?.toLowerCase();
    const userName = user.firstName || user.name
    return !loverText
        ? user
        : userName
        .toLowerCase()
        .replace(/[- ]/gi, '')
        .indexOf(loverText) > -1 || userName.toLowerCase().indexOf(loverText) > -1;
}

export function getDepotIntegrationsSectionFilter(text, integration) {
    const loverText = text.toLowerCase();
    const integrationType = integration.type
    return !loverText
        ? integration
        : integrationType
        .toLowerCase()
        .replace(/[- ]/gi, '')
        .indexOf(loverText) > -1 || integrationType.toLowerCase().indexOf(loverText) > -1;
}

export function getOperationModeSectionFilter(text, item) {
    const loverText = text.toLowerCase();
    const categoryName = item.name
    return !loverText
      ? item
      : categoryName
      .toLowerCase()
      .replace(/[- ]/gi, '')
      .indexOf(loverText) > -1 || categoryName.toLowerCase().indexOf(loverText) > -1;
}

export function getGalleryFilter(text, gallery) {
    const lowerText = text.toLowerCase();
    return !lowerText ? gallery : gallery.title.toLowerCase().indexOf(lowerText) > -1;
}

export function getCategoryProductFilter(text, product) {
    const lowerText = text.toLowerCase();
    return !lowerText ? product : product.name.toLowerCase().indexOf(lowerText) > -1;
}

export function getCityFilter(text, city) {
    const loverText = text.toLowerCase();
    return !loverText ? city : city.name.toLowerCase().indexOf(loverText) > -1;
}

export function getSearchShortcutFilter(text, shortcut) {
    const loverText = text.toLowerCase();
    if (!loverText || !shortcut) return shortcut;
    return shortcut.name.toLowerCase().indexOf(loverText) > -1;
}

export function getNamedPlaceFilter(text, place) {
    const loverText = text.toLowerCase();
    if (!loverText) return place;

    const { address } = place.address;
    return (
        place.name.toLowerCase().indexOf(loverText) > -1 ||
        getAddress(address)
            .toLowerCase()
            .indexOf(loverText) > -1
    );
}

export function getShiftsFilter(text, shift) {
    const loverText = text.toLowerCase();
    if (!loverText) return shift;
    const courierName = shift?.name || shift?.courierName || ''
    return courierName.toLowerCase().indexOf(loverText) > -1;
}

export const getCampaignFilter = (text, campaign) => {
    const loverText = text.toLowerCase();
    if (!loverText) return campaign;

    return campaign?.name.toLowerCase().indexOf(loverText) > -1;
}

export function getShiftsHistoryFilter(text, order) {
    const loverText = text.toLowerCase();
    if (!loverText) return order;

    return (
        getAddress(order.depotName)
            .toLowerCase()
            .indexOf(loverText) > -1
    );
}

export function getControlCentersFilter(text, center) {
    const loverText = text.toLowerCase();
    if (!loverText) return center;
    const user = center.userModel ? getUserById(center.userModel) : null;
    const userFullName = user ? getFullName(user) || '' : '';
    return (
        center.name.toLowerCase().indexOf(loverText) > -1 ||
        (userFullName && userFullName.toLowerCase().indexOf(loverText) > -1)
    );
}

export function columnIsRequired(column, item) {
    return column.isRequired || (column.isFuncRequired && column.isFuncRequired(item));
}

export function getMarketplaceFilter(text, marketplace) {
    const loverText = text.toLowerCase();
    const title = marketplace?.title || '';
    const label = marketplace?.label || '';
    const containTitle = !loverText
        ? marketplace
        : title
        .toLowerCase()
        .replace(/[- ]/gi, '')
        .indexOf(loverText) > -1 || title.toLowerCase().indexOf(loverText) > -1;
    const containLabel = !loverText
        ? marketplace
        : label
        .toLowerCase()
        .replace(/[- ]/gi, '')
        .indexOf(loverText) > -1 || label.toLowerCase().indexOf(loverText) > -1;

    return containLabel || containTitle;
}

export function getStartedControlCenter(controlCenter) {
    return controlCenter && controlCenter.info && controlCenter.info.startTime && !controlCenter.info.endTime;
}

export function getMenuTemplatesFilter(text, template) {
    const loverText = text.toLowerCase();
    return !loverText
        ? template
        : template.name
              .toLowerCase()
              .replace(/[- ]/gi, '')
              .indexOf(loverText) > -1 || template.name.toLowerCase().indexOf(loverText) > -1;
}

export function getMarketplaceMessageGalleryFilter(text, gallery) {
    const loverText = text.toLowerCase();
    return !loverText
        ? gallery
        : gallery.title
        .toLowerCase()
        .replace(/[- ]/gi, '')
        .indexOf(loverText) > -1 || gallery.title.toLowerCase().indexOf(loverText) > -1;
}

export function checkForControlCenter(item, selectedControlCenter) {
    let ccids = [];
    if(selectedControlCenter && typeof selectedControlCenter === 'string') {
        ccids = [selectedControlCenter];
    }
    if (selectedControlCenter && Array.isArray(selectedControlCenter)) {
        ccids = selectedControlCenter;
    }
    const controlCenterIdsOrder = item?.orders?.map(c => c.controlCenterId).filter(Boolean);

    return (
        (item.shiftType === SHIFT_TYPE.Backup && controlCenterIdsOrder?.some(id => ccids.includes(id))) ||
        ccids.includes(item.controlCenterId) ||
        !item.controlCenterId ||
        (!ccids.length && !item.controlCenterId)
    );
}

export function groupsBarCheckForControlCenter(item, selectedControlCenters = []) {
    if (!item) return true;
    const checkExclude = item.groupId === DEFAULT_ORDERS_GROUPS.assistance || item.isExternalGroup;
    return (
        (checkExclude && (!item.controlCenterId || selectedControlCenters.includes(item.controlCenterId))) ||
        checkForControlCenter(item, selectedControlCenters)
    );
}

export function groupsBarGroupItemFilter(group) {
    return (
        group &&
        (group.isCourierGroup || isNotDefaultOrderGroup(group.id)) &&
        ((group.isExternalGroup && !group.groupId) || showGroup(group))
    );
}

export function groupsBarOrderItemFilter(order, isSearchMode) {
    return (
        order &&
        (isSearchMode || isDefaultGroup(order.groupId)) &&
        (order.groupId === DEFAULT_ORDERS_GROUPS.assistance || !User.instance.skipObjectWithControlCenter(order))
    );
}

export function getControlCenterFilter(items, propName = 'defaultControlCenterId') {
    const currentUser = User.instance.getCurrentUserInfo();
    if (!currentUser) return false;
    return (items || []).filter(
        item => !currentUser.controlCenterId || !item[propName] || item[propName] === currentUser.controlCenterId
    );
}

export function getUsersFilteredByDepotId(users, isNoDepotUser = true) {
    if (isNoDepotUser) {
        return (users || []).filter(user => !user.depotId && !user.isDeleted);
    }

    return (users || []).filter(user => !!user.depotId && !user.isDeleted);
}

export function getActiveUsers(users) {
    return (users || []).filter(user => user && ((user.roles || {}).active || user.cycle));
}

export function getMapDepotsMarkersFiltered(markers, orders, selectedControlCenters, showAllDepotsOnMap) {
    const filterMapObjectsByControlCenter =
        isDepotMode() && User.instance.getCurrentUserConfigurationProp('filterMapObjectsByControlCenter');

    if (showAllDepotsOnMap)
        return (markers || []).filter(
            marker =>
                marker.markerType === TYPE_MARKER.depot &&
                (filterMapItemByApplicableControlCenter(
                    marker,
                    filterMapObjectsByControlCenter,
                    selectedControlCenters
                ) ||
                    (orders || []).some(
                        order =>
                            order.depotId === marker.id &&
                            checkForControlCenter(order, selectedControlCenters) &&
                            order.state < ORDER_STATE.delivered
                    ))
        );
    return (markers || []).filter(
        marker =>
            marker.markerType === TYPE_MARKER.depot &&
            filterMapItemByApplicableControlCenter(marker, filterMapObjectsByControlCenter, selectedControlCenters) &&
            (orders || []).some(
                order =>
                    order.depotId === marker.id &&
                    checkForControlCenter(order, selectedControlCenters) &&
                    order.state < ORDER_STATE.delivered
            )
    );
}

export function filterMapItemByApplicableControlCenter(item, filterMapObjectsByControlCenter, selectedControlCenters = []) {
    const applicableControlCenters = ((item || {}).applicableControlCenters || []).filter(f => f);
    return (
        !filterMapObjectsByControlCenter ||
        !selectedControlCenters.length ||
        !applicableControlCenters.length ||
        applicableControlCenters.some(cc => selectedControlCenters.includes(cc))
    );
}

export function getMapRegionsFiltered(regions, selectedControlCenter) {
    const filterMapObjectsByControlCenter =
        isDepotMode() && User.instance.getCurrentUserConfigurationProp('filterMapObjectsByControlCenter');
    return getControlCenterFilter(
        (regions || []).filter(region =>
            filterMapItemByApplicableControlCenter(region, filterMapObjectsByControlCenter, selectedControlCenter)
        )
    );
}

export function filterWithWrappedMobileColumns(columns) {
    return (columns || []).filter(column =>
        !column.wrapOnMobileWidth ||
        window.innerWidth > column.wrapOnMobileWidth
    );
}

export function sectionWrapperFilteredHeaderColumns(columns, items) {
    return (columns || [])
        .filter(column=> (!column.isShow || (items || []).some(item=> column.isShow(item))) &&
            (!column.wrapOnMobileWidth || window.innerWidth > column.wrapOnMobileWidth)
        );
}

export const pendingOrdersForSummaryFilter = items => {
  return (items || []).filter(order=> order.status !== ACCOUNT_ORDER_STATUS.canceled);
}

export const pendingFooterDeliveryOrdersFilter = items => {
  return (items || []).filter(order=> order.delivery_type === PENDING_ORDER_TYPE.delivery &&
    order.status !== ACCOUNT_ORDER_STATUS.canceled
  );
}

export const pendingFooterTakeAwayOrdersFilter = items => {
  return (items || []).filter(order=> order.delivery_type === PENDING_ORDER_TYPE.takeAway &&
    order.status !== ACCOUNT_ORDER_STATUS.canceled
  );
}

export const ordersHistoryFilterModel = () => {
    const providers = Store?.store?.getState()?.restaurant?.data?.ordersProvider || [];
    const providerFilters = providers.filter(p => p.orderProvider).map(provider => ({
        name: provider.id,
        label: provider.name,
        selected: false,
        filterType: FILTER_TYPE.or,
        filterFunction: order=> order.order_source === SOURCE_TYPE.delivapp && order.order_source_id === provider.id,
    }))

    const filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions(
        "deliveryGroup",
        CONTROL_TYPE.groupCheckbox,
        "dashboard.orders.ORDER_DELIVERY_TYPE_GROUP_LABEL",
        null,
        null,
        [
            {
                title: "dashboard.orders.ORDER_ORDER_SOURCE_GROUP_LABEL",
                isCheckAllGroup: true,
                passEmptyFilters: true,
                filterType: FILTER_TYPE.and,
                items: [
                    {
                        name: "logistic",
                        label: "enums.orderSourceTypes.LOGISTIC",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.order_source === SOURCE_TYPE.logistic,
                    },
                    {
                        name: "app",
                        label: "enums.orderSourceTypes.APP",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.order_source === SOURCE_TYPE.app,
                    },
                    {
                        name: "admin",
                        label: "enums.orderSourceTypes.ADMIN",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.order_source === SOURCE_TYPE.admin,
                    },
                    {
                        name: "mishloha",
                        label: "enums.orderSourceTypes.MISHLOHA",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.order_source === SOURCE_TYPE.mishloha,
                    },
                    {
                        name: "tenbis",
                        label: "enums.orderSourceTypes.TEN_BIS",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.order_source === SOURCE_TYPE.tenBis,
                    },
                    {
                        name: "wolt",
                        label: "enums.orderSourceTypes.WOLT",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.order_source === SOURCE_TYPE.wolt,
                    },
                    {
                        name: "cibus",
                        label: "enums.orderSourceTypes.CIBUS",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.order_source === SOURCE_TYPE.cibus,
                    },
                    ...providerFilters,
                ]
            },
            {
                title: "dashboard.orders.PAYMENT_METHOD_GROUP_LABEL",
                isCheckAllGroup: true,
                passEmptyFilters: true,
                filterType: FILTER_TYPE.and,
                items: [
                    {
                        name: "unacceptedCash",
                        label: "pending-orders.FILTER_TYPE_BY_UNACCEPTED_CASH",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> !!order?.payments?.filter(p => p.paymethod_id === PAY_METHOD.cash && p.captured_price === 0).length,
                    },
                    {
                        name: "acceptedCash",
                        label: "pending-orders.FILTER_TYPE_BY_ACCEPTED_CASH",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> !!order?.payments?.filter(p => p.paymethod_id === PAY_METHOD.cash && p.captured_price > 0).length,
                    },
                    {
                        name: "card",
                        label: "pending-orders.FILTER_TYPE_BY_ACCEPTED_CREDIT_CARD",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> !!order?.payments?.filter(p => p.paymethod_id === PAY_METHOD.creditCard).length,
                    },
                    {
                        name: "external",
                        label: "pending-orders.FILTER_TYPE_BY_EXTERNAL",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> !!order?.payments?.filter(p => p.paymethod_id === PAY_METHOD.creditCard && p.provider === EXTERNAL_COURIER_PROVIDER.external).length,
                    },
                    {
                        name: "voucher",
                        label: "pending-orders.FILTER_TYPE_BY_VOUCHER",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> !!order?.payments?.filter(p => p.paymethod_id === PAY_METHOD.bonus && p.reference_Id).length,
                    },
                    {
                        name: "bonus",
                        label: "pending-orders.FILTER_TYPE_BY_BONUS",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> !!order?.payments?.filter(p => p.paymethod_id === PAY_METHOD.bonus && !p.reference_Id).length,
                    },
                    {
                        name: "coupon",
                        label: "pending-orders.FILTER_TYPE_BY_COUPON",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> !!(order.coupon || '').length,
                    },
                    {
                        name: "discount",
                        label: "pending-orders.FILTER_TYPE_BY_DISCOUNT",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> !!order?.payments?.filter(p => p.paymethod_id === PAY_METHOD.discount).length,
                    },
                ]
            }
        ],
        false,
        null,
        null,
        true
    );

    filterOptionsModel.addOptions(
        "deliveryGroup2",
        CONTROL_TYPE.groupCheckbox,
        "dashboard.orders.ORDER_DELIVERY_TYPE_GROUP_LABEL",
        null,
        null,
        [
            {
                title: "pending-orders.PENDING_ORDER_INFO_CLIENT_FILTER",
                isCheckAllGroup: true,
                passEmptyFilters: true,
                filterType: FILTER_TYPE.and,
                items: [
                    {
                        name: "newUser",
                        label: "pending-orders.PENDING_ORDER_INFO_NEW_CUSTOMER",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.new_customer,
                    },
                    {
                        name: "loyaltyLevel",
                        label: "pending-orders.PENDING_ORDER_INFO_LOYALTY",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.loyalty_level > 0,
                    },
                ]
            },
            {
                title: "pending-orders.PENDING_ORDER_INFO_DELIVERY_METHOD",
                isCheckAllGroup: true,
                passEmptyFilters: true,
                filterType: FILTER_TYPE.and,
                items: [
                    {
                        name: "takeaway",
                        label: "orders.TAKEAWAY_FILTER_LABEL",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.delivery_type === DELIVERY_TYPE.takeAway
                    },
                    {
                        name: "delivery",
                        label: "orders.DELIVERY_FILTER_LABEL",
                        selected: false,
                        filterType: FILTER_TYPE.or,
                        filterFunction: order=> order.delivery_type === DELIVERY_TYPE.delivery
                    }
                ]
            },
        ],
        false,
        null,
        null,
        true
    );

    return filterOptionsModel;
}

export const getSavedOrderHistoryFilterModel = () => {
    try {
        const savedModelString = getStoreData(TYPE_STORAGE.local, 'ordersHistoryFilterModel', true);
        const savedModel = JSON.parse(savedModelString)
        const filterOptions = ordersHistoryFilterModel();
        const updateFilterValue = (filter) => {
            const find = savedModel.find(f => f.id === filter.name);
            if(find) {
                filter.selected = find.selected;
            }
        }
        Object.values(filterOptions.options).forEach(filter => {
            if(filter.type === CONTROL_TYPE.groupCheckbox) {
                const filters = filter.selected.flatMap(f => f.items);
                filters.forEach(f => updateFilterValue(f));
            } else {
                updateFilterValue(filter);
            }
        })
        return filterOptions;
    } catch (e) {
        return ordersHistoryFilterModel();
    }
}

export const saveOrderHistoryFilterModel = filter => {
    const filterData = Object
        .values(filter?.options || {})
        .flatMap(f => f.type === CONTROL_TYPE.groupCheckbox ? f.selected.flatMap(o => o.items) : f)
        .map(f => ({id: f.name, selected: f.selected}));
    saveStoreData(TYPE_STORAGE.local, "ordersHistoryFilterModel", true, JSON.stringify(filterData));
}

export const savePendingOrderHistoryPeriod = (period) => {
    saveStoreData(TYPE_STORAGE.local, "pendingOrdersHistoryPeriods", true, JSON.stringify(period));
}

export const getPendingOrderHistoryPeriod = () => {
    try {
        const savedModelString = getStoreData(TYPE_STORAGE.local, 'pendingOrdersHistoryPeriods', true);
        return JSON.parse(savedModelString)
    } catch (e) {
        return { key: null };
    }
}

export const getAllFilters = (filterObj) => {
    const filterFunctions = Object
        .values(filterObj || {})
        .flatMap(f => f.type === CONTROL_TYPE.groupCheckbox ? f.selected.flatMap(o => o.items) : f)
        .filter(f => f.selected)
        .map(f => f.filterFunction);
    return filterFunctions;
}

export const getQuantityFilters = filter => {
    return Object
        .values(filter || {})
        .flatMap(f => f.type === CONTROL_TYPE.groupCheckbox ? f.selected.flatMap(o => o.items) : f)
        .filter(f => f.selected)
        .length;
}