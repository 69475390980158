import moment from "moment";
import i18n from "../i18n";
import { TITLE_RELATIVE_TIME, FORMAT_RELATIVE_TIME_ONLY_TYPE, PERIOD_TYPE } from "./enums";
import { isEmpty } from "./objects-util";

export const oneSec = 1000;
export const oneMin = 60000;
export const oneHour = 3600000;
export const oneDay = 24 * oneHour;
const userTime = "H:mm";
const formatTime24 = "H:mm";
const formatDayTime24 = "D/M/YY H:mm";
export const defaultDate = "0001-01-01T00:00:00.000Z";
export const DATE_FORMAT_DMY = "DD.MM.YYYY";
export const DATE_FORMAT_DMY2 = "DD/MM/YYYY";
export const DATE_FORMAT_DMY2_HM = "DD/MM/YYYY HH:mm";
export const DATE_FORMAT_DMY_SHORT = "D/M/YY";
export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH-mm";
export const TIME_FORMAT_USER = "HH:mm";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const DATE_TIME_FORMAT_DAYTIME = "D/M/YY H:mm";
export const DATE_TIME_FORMAT_DAYTIME_SHORT = "MM/DD H:mm";
export const DATE_TIME_AP_PM = "hh:mm a";

export function getFormattedDate(date, typeFormat) {
  if (!date) return date;
  const dateFormatted = new Date(date);
  switch (typeFormat) {
    case DATE_FORMAT:
      return moment(dateFormatted).format(DATE_FORMAT);
    case TIME_FORMAT:
      return moment(dateFormatted).format(TIME_FORMAT);
    case TIME_FORMAT_USER:
      return moment(dateFormatted).format(TIME_FORMAT_USER);
    case DATE_TIME_FORMAT:
      return moment(dateFormatted).format(DATE_TIME_FORMAT);
    case DATE_TIME_FORMAT_DAYTIME:
      return moment(dateFormatted).format(DATE_TIME_FORMAT_DAYTIME);
    case DATE_TIME_AP_PM:
      return moment(dateFormatted, DATE_TIME_AP_PM).format(DATE_TIME_AP_PM)
    default:
      return typeFormat
        ? moment(dateFormatted).format(typeFormat)
        : date;
  }
}

export function getTimeFromText(value) {
  const val = value.replace(/[-:]/g, "");
  return val.length >= 4
    ? val.substring(0,2) + ":" + val.substring(2,4)
    : value.replace("-", ":");
}

export function getDateFromText(value) {
  const clearDateValue = value.replace(/[-_]/g, "").replace("/", "");

  return clearDateValue.length >= 8
    ? clearDateValue.substring(0,4) + "." + clearDateValue.substring(4,6) + "." + clearDateValue.substring(6,8)
    : null
}

export function isNotToday(value) {
  return !(value &&
    moment(value).isSame(new Date(), "day")
    && moment(value).isSame(new Date(), "year")
    && moment(value).isSame(new Date(), "month")
  );
}

export function getStaticTime(time, onlyTime, isRtl) {
  let result = null;
  if (time && time !== defaultDate) {
    var timeToDate = new Date(time);
    var dateNow = new Date();
    if (dateNow.toDateString() === timeToDate.toDateString() || onlyTime) {
      result = isRtl
        ? moment(timeToDate).format(formatTime24)
        : moment(timeToDate).format(userTime);
    } else {
      result = isRtl
        ? moment(timeToDate).format(formatDayTime24)
        : moment(timeToDate).format(DATE_FORMAT_DMY2_HM);
    }
  }
  return result;
}

export function getFormatRelativeTime(
  delta,
  particle,
  isJustNow,
  isRtl,
  onlyType,
  shortMode
) {
  let result = "";

  if (delta < oneMin) {
    if (isJustNow) {
      const sec = delta ? (delta / oneSec).toFixed(0) : "";
      result = sec ? sec + " " + i18n.t(shortMode ? "covertTime.TIME_SEC_SHORT" : "covertTime.TIME_SEC") : "";
    } else {
      result = i18n.t("covertTime.JUST_NOW_LABEL");
    }
  } else {
    if (onlyType)
      return (millisToHourMinutesByType(delta, onlyType) + TITLE_RELATIVE_TIME(onlyType));

    const deltaDate = millisToHourMinutes(delta);
    let items = [];
    if (particle && isRtl) {
      items.push(particle);
    }
    if (deltaDate.d > 0) {
      items.push(deltaDate.d + " " + i18n.t("covertTime.TIME_DAY"));
      if (deltaDate.h > 0) {
        items.push(deltaDate.h + " " + i18n.t("covertTime.TIME_HOUR"));
      }
    } else {
      if (deltaDate.h > 0) {
        items.push(deltaDate.h + " " + i18n.t("covertTime.TIME_HOUR"));
      }
      items.push(deltaDate.m + " " + i18n.t(shortMode ? "covertTime.TIME_MIN_SHORT" : "covertTime.TIME_MIN"));
    }
    if (particle && !isRtl) {
      items.push(particle);
    }
    result = items.join(" ");
  }
  return result;
}

export function isSameDateByCurrent(value) {
  if(!value) return false;

  let d1 = new Date();
  let d2 = new Date(value);
  const res = moment(new Date()).isSameOrAfter(value);
  const diff = Math.abs(d1 - d2);

  return res && diff < oneMin;
}

export function isAfterDateByCurrent(value) {
  if(!value) return false;
  const res1 = moment(new Date()).isSameOrAfter(value);
  const res2 = !isSameDateByCurrent(value);

  return res1 && res2;
}

export function getRelativeTime(targetTime, particle, isRtl, shortMode) {
  let result = "";
  if (targetTime) {
    let delta = Math.abs(new Date() - new Date(targetTime));
    result = getFormatRelativeTime(delta, particle, false, isRtl, undefined, shortMode);
  }
  return result;
}

export function minutesToTimeRelative(minutes) {
  if(isEmpty(minutes)) return null;

  let date = new Date();
  date.setMinutes(date.getMinutes() + minutes);
  return date && date.toISOString();
}

export function timeToMinutes(time) {
  if (time === null || typeof time === "undefined" || time === defaultDate) {
    return 0;
  }
  var arr = time.split(":");
  return Math.round(Date.UTC(70, 0, 1, arr[0], arr[1], arr[2]) / 60000);
}

export function minutesToTime(minutes) {
  if (minutes === null || typeof minutes === "undefined") {
    return "00:00:00";
  }

  const min = Math.round(minutes % 60);
  return (
    ("00" + Math.floor((minutes / 60) % 60)).substr(-2) +
    ":" +
    (min ? min : "00") +
    ":00"
  );
}

export function timeToMinutesRelative(time) {
  if (isEmpty(time) || time === defaultDate)
    return 0;
  const dateTime = new Date(time);
  const delta = dateTime - new Date();
  return delta <= 0 ? 0 : Math.round(delta / 60000);
}

export function relativeDeltaTimeNoJust(delta, isRtl) {
  let result = "";
  if (delta || delta === 0) {
    result = getFormatRelativeTime(delta * oneSec, undefined, true, isRtl);
  }
  return result;
}

export function getISOTime(time) {
  return time ? new Date(time).toISOString() : time;
}

export function getRelativeStartEndTime(timeStart, timeEnd, isNotJust) {
  let result = "";
  if (timeStart && timeEnd) {
    const delta = Math.abs(new Date(timeEnd) - new Date(timeStart));
    result = getFormatRelativeTime(delta, undefined, isNotJust);
  }
  return result;
}

export function setStartTime(time) {
  const newTime = time ? new Date(time) : new Date();
  newTime.setUTCHours(0);
  newTime.setUTCMinutes(0);
  newTime.setUTCSeconds(0);
  newTime.setUTCMilliseconds(0);
  return new Date(newTime.getTime() + (newTime.getTimezoneOffset() * 60000));
}

export function setEndTime(time) {
  const newTime = time ? new Date(time) : new Date();
  newTime.setUTCHours(23);
  newTime.setUTCMinutes(59);
  newTime.setUTCSeconds(59);
  newTime.setUTCMilliseconds(999);
  return new Date(newTime.getTime() + (newTime.getTimezoneOffset() * 60000));
}

export function isDatesValid(startDate, endDate) {
  if (!startDate) {
    return false;
  }

  const tempStartDate = setStartTime(startDate);
  const tempEndDate = setEndTime(endDate);
  return tempEndDate > tempStartDate;
}

export function prepareStartDate(startDate) {
  return setStartTime(startDate).toISOString();
}

export function prepareEndDate(endDate) {
  return setEndTime(endDate).toISOString();
}

export function  getFormattedTime(open, close) {
  return `${open.hour < 10 ? `0` + open.hour : open.hour}:` +
      `${open.minute < 10 ? `0` + open.minute : open.minute}-` +
      `${close.hour < 10 ? `0` + close.hour : close.hour}:` +
      `${close.minute < 10 ? `0` + close.minute : close.minute}`
}

export function addHoursToDate(date, hours) {
  if(!date) date = new Date();
  return date.setHours(date.getHours() + hours);
}

export function getWeekPeriod() {
  const today = new Date();

  const dayOfWeek = today.getDay();
  const diffToLastSunday = dayOfWeek === 0 ? 0 : dayOfWeek;
  const from = new Date(today);
  from.setDate(today.getDate() - diffToLastSunday);
  from.setHours(0);
  from.setMinutes(0);
  from.setSeconds(0);
  from.setMilliseconds(0);

  const to = new Date();
  to.setDate(to.getDate());
  to.setHours(23);
  to.setMinutes(59);
  to.setSeconds(59);
  to.setMilliseconds(999);

  return { from, to };
}

export function getHours() {
  const hours = [];
  for (let index = 0; index < 24; index++) {
    hours.push({
      key: index,
      value: index < 10 ? `0` + index : index
    })
  }
  return hours;
}

export function getMinutes() {
  const minutes = [];
  for (let index = 0; index < 60; index++) {
    minutes.push({
      key: index,
      value: index < 10 ? `0` + index : index
    })
  }
  return minutes;
}

function millisToHourMinutes(delta) {
  const day = Math.floor(delta / oneDay);
  const hour = Math.floor((delta % oneDay) / oneHour).toFixed(0);
  const minutes = Math.floor((delta % oneHour) / oneMin).toFixed(0);
  return { d: day, h: hour, m: minutes };
}

function millisToHourMinutesByType(delta, type) {
  switch (type) {
    case FORMAT_RELATIVE_TIME_ONLY_TYPE.d:
      return Math.floor(delta / oneDay);
    case FORMAT_RELATIVE_TIME_ONLY_TYPE.h:
      return Math.floor(delta / oneHour);
    case FORMAT_RELATIVE_TIME_ONLY_TYPE.m:
      return Math.floor(delta / oneMin);
    default:
      return delta;
  }
}

export const getYesterday = () => moment().add(-1, "d");

export const getPeriodByType = (type) => {
  const today = new Date();
  const year = today.getFullYear();
  let data = { start: null, end: null };

  switch (type) {
    case PERIOD_TYPE.today:
      data = {
        start: prepareToday().from,
        end: prepareToday().to,
      };
      break;
    case PERIOD_TYPE.yesterday:
        data = {
          start: prepareYesterday().from,
          end: prepareYesterday().to
        };
        break;
    case PERIOD_TYPE.week:
      const { from, to } = getWeekPeriod();
      data = {
        start: from,
        end: to
      };
      break;
    case PERIOD_TYPE.month:
      data = {
        start: prepareMonth().from,
        end: prepareMonth().to,
      };
      break;
    case PERIOD_TYPE.prevMonth:
      data = {
        start: preparePreviousMonth().from,
        end: preparePreviousMonth().to,
      };
      break;
    case PERIOD_TYPE.year:
      data = {
        start: new Date(year, 0, 1).toISOString(),
        end: prepareEndDate(new Date())
      };
      break;
    default:
      break;
  }

  return data;
}

export const prepareFullDay = (date) => ({
  start: prepareStartDate(date),
  end: prepareEndDate(date)
});

const isToday = date => {
  if(!date) return false;
  const today = new Date();
  const d = new Date(date);

  return d.getDate() === today.getDate() &&
    d.getMonth() === today.getMonth() &&
    d.getFullYear() === today.getFullYear();
}

export const getConvertedAmPmWithCheck = date => {
  if(!date) return null;
  return isToday(date)
    ? getFormattedDate(date, DATE_TIME_AP_PM)
    : `${getFormattedDate(date, DATE_FORMAT_DMY_SHORT)} ${getFormattedDate(date, DATE_TIME_AP_PM)}`
}

export const getDeliveryTimeForNewOrder = () => {
  const date = moment(new Date()).endOf('hour').add(1, 'hour').add(1, 'second');
  const dateEnd = moment(new Date()).clone().add(24, 'hour');
  const res = { today: [], tomorrow: [] };

  while (date.isSameOrBefore(dateEnd)) {
    if(date.get('weekday') === moment().get('weekday')) {
      res.today.push(date.toDate());
    } else {
      res.tomorrow.push(date.toDate());
    }
    date.add(30, 'minute');
  }

  return res;
}


export const formatedDateWithTimeZone = (datetime) => {
  const date = datetime.original_date || '';
  const time = datetime.original_time || '';
  if(!date) {
    return datetime.original_datetime || moment(new Date());
  }
  return moment( (date + " " + time).trim() );
}

export const preparePreviousMonth = () => {
  const today = new Date();
  const from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const to = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
  return { from, to };
}

export const prepareMonth = () => {
  const today = new Date();
  const from = new Date(today.getFullYear(), today.getMonth(), 1);
  const to = new Date(today.getFullYear(), today.getMonth()+1, 0, 23, 59, 59);
  return { from, to };
}

export const prepareYesterday = () => {
  const from = new Date();
  from.setDate(from.getDate() - 1);
  from.setHours(0);
  from.setMinutes(0);
  from.setSeconds(0);
  from.setMilliseconds(0);

  const to = new Date();
  to.setDate(to.getDate() - 1);
  to.setHours(23);
  to.setMinutes(59);
  to.setSeconds(59);
  to.setMilliseconds(999);

  return { from, to };
}

export const prepareToday = () => {
  const from = new Date();
  from.setHours(0);
  from.setMinutes(0);
  from.setSeconds(0);
  from.setMilliseconds(0);

  const to = new Date();
  to.setDate(to.getDate());
  to.setHours(23);
  to.setMinutes(59);
  to.setSeconds(59);
  to.setMilliseconds(999);

  return { from, to };
}
